import { useEffect, useState } from "react";
import PresentDefaultCharacter from './PresentDefaultCharacter'
import * as BBL from '../BabylonModule'
import * as NG from '../NAVIGATOR'
import { SceneLoader, Mesh } from '@babylonjs/core'
function PresentMap() {
    const [load, setLoad] = useState(false);
    useEffect(() => {
        const scene = BBL.getScene(); 

        SceneLoader.LoadAssetContainer('./assets/model/', 'MapPresent.glb', scene, (container) => {      
            let nav = container.meshes[9];
            let mergeFloors = Mesh.MergeMeshes([
                container.meshes[5],
                container.meshes[6],
                container.meshes[7],
                container.meshes[8]
            ], true, true, null, false, true);
            mergeFloors.name = "mergeFloors";
            mergeFloors.freezeWorldMatrix();         

            let mergeProps = Mesh.MergeMeshes([
                container.meshes[1],
                container.meshes[2],
                container.meshes[3]
            ], true, true, null, false, true);
            mergeProps.name = "mergeProps";
            mergeProps.freezeWorldMatrix();
            
            BBL.grassMatSet(container.materials[3]);
            BBL.grassMatSet(container.materials[4]);
            BBL.brickMatSet(container.materials[1]);
            BBL.brickMatSet(container.materials[5]);
            BBL.mapMatSetup(container.materials[0]);
            let drawShadowMeshes = [
                mergeProps
            ];
            let receiveShadowMeshes = [
                mergeFloors,
                mergeProps
            ];      
            BBL.waterAndShadowSetup(container.meshes[0], null, [], drawShadowMeshes, receiveShadowMeshes);
            BBL.setNavMesh(nav, false, -0.055);
            container.addAllToScene();

            NG.createAutoNavMesh();
            if (NG.getNavPlugin().navMesh) {
                setLoad(!load);
            }    
                   
        });

    }, [])

    return (
        <>
            {
                load && <PresentDefaultCharacter />
            }
        </>
    );
}

export default PresentMap;