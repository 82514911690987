import { SceneLoader } from '@babylonjs/core';
import FutureMap from './future/FutureMap';
import PresentMap from './present/PresentMap';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as BBL from './BabylonModule'

import { setAllReady } from "../redux/BabylonSettings";

function World({ canvas, value }) {
    const dispatch = useDispatch()
    const SetAllReady = (isReady) => dispatch(setAllReady(isReady));
    const [isJustOneRender, setIsJustOneRender] = useState(false);
    let nickname = useSelector((state) => (state.UserInfo.nickname));
    let userPos = useSelector((state) => (state.UserInfo.position));
    let userRot = useSelector((state) => (state.UserInfo.rotation));
    let eyebrowColor = useSelector((state) => (state.UserInfo.currentItems.eyebrow));

    useEffect(() => {
        if (!isJustOneRender) {
            let shadowOptimizeValue = true; // shadow on/off
            let skyLikCheckValue = true;
            switch (value.value) {
                case "present":
                    skyLikCheckValue = false;
                    break;
            }

            BBL.createEngine(canvas, { disableWebGL2Support: false, doNotHandleContextLost: true })
            BBL.createScene({ useGeometryUniqueIdsMap: true, useMaterialMeshMap: true, useClonedMeshMap: true }, true);
            BBL.normalBabylonSetup(value.value, shadowOptimizeValue, skyLikCheckValue);
            let scene = BBL.getScene();
            let camera = BBL.getCamera();
            camera.alpha = 3.0887;
            camera.beta = 1.399;
            camera.radius = 11;

            SceneLoader.LoadAssetContainer('./assets/model/', "pin.glb", scene, (container) => {
                BBL.pinSetup(container);
                container.addAllToScene();
                SceneLoader.LoadAssetContainer("./assets/model/", "Point.glb", scene, (container) => {
                    BBL.pointerSetup(container.meshes[0], container.animationGroups[0]);
                    container.addAllToScene();

                    SceneLoader.LoadAssetContainer('./assets/model/', "Avatar_Body.glb", scene, (container) => {

                        BBL.playerSetup(Number(userPos.x), Number(userPos.y), Number(userPos.z), (Number(userRot.y)), eyebrowColor, container);
                        BBL.nickNameSetup(nickname);
                        BBL.setPlayerAnimation(container.animationGroups);
                        container.addAllToScene();
                        setIsJustOneRender(!isJustOneRender);
                    });
                });
            });
        }
        return () => {
            SetAllReady(false)
            BBL.clearEngine();
            setIsJustOneRender(false);
        }
    }, [])
    
    return (
        <>
            {
                value.value === "future" && isJustOneRender && <FutureMap />
            }
            {
                value.value === "present" && isJustOneRender && <PresentMap />
            }
        </>
    )
}
export default World;
