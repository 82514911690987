import {OtherUserTypes} from './types'

interface Position {
    x : number,
    y : number,
    z : number
}

interface Users {
    id : string,
    nickname : string,
    position : Position
    rotation : {
        x : number,
        y : number,
        z : number
    }
    items : {
        hair: string,
        cloth : string,
        shoes : string,
    },
    gameMode : boolean,
    campus : string
}

export type {Users}

interface setUsersAction {
    type : OtherUserTypes.SET_USERS;
    users : Array<Users>
}

interface addUserAction {
    type : OtherUserTypes.ADD_USER;
    user : Users
}

interface deleteUserAction {
    type : OtherUserTypes.DELETE_USER;
    id : string
}

interface updatePositionAction {
    type : OtherUserTypes.UPDATE_POSITION;
    id : string,
    position : Position
}

interface clearUsersAction {
    type : OtherUserTypes.CLEAR_USERS;
}



export type Actions =
    | setUsersAction
    | addUserAction
    | deleteUserAction
    | updatePositionAction
    | clearUsersAction

const SET_USERS = "SET_USERS";
const ADD_USER = 'ADD_USER';
const DELETE_USER = 'DELETE_USER';
const UPDATE_POSITION = 'UPDATE_POSITION';
const CLEAR_USERS = "CLEAR_USERS";

export const setUsers = (users : Array<Users>) => ({
    type : SET_USERS,
    users
})

export const addUser = (user : Users) => ({
    type : ADD_USER,
    user
})

export const deleteUser = (id : string) => ({
    type : DELETE_USER,
    id
})

export const updatePosition = (position : Position, id : string) => ({
    type : UPDATE_POSITION,
    id,
    position
})

export const clearUsers = () => ({
    type : CLEAR_USERS
})


export default function OtherUsers(state : Array<Users> = [], action : any) {
    switch (action.type) {
        case SET_USERS:
            return state.concat(action.users);
        case ADD_USER:
            if (state.some(user => (user.id === action.user.id))) {
                return state
            } else {
                return state.concat([action.user]);
            }
        case DELETE_USER:
            return state.filter(user => {
                return user.id !== action.id
            });
        case UPDATE_POSITION:
            return state.map(user=> {
                if (user.id === action.id) {
                    user.position = {
                        ...user.position,
                        x : action.position.x,
                        y : action.position.y,
                        z : action.position.z,
                    }
                    return user
                } else {
                    return user
                }
            })
        case CLEAR_USERS :
            return []
        default:
            return state
    }
}