import React, { useState, useRef, useEffect, useCallback } from 'react';
import future_Minimap from '../assets/img/future_Minimap.png'
import present_Minimap from '../assets/img/present_Minimap.png'
import '../css/statebuttons.css'
import '../css/chatting.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faUser, faMusic, faMusicSlash, faXmark, faTShirt, faGear, faSignOut, faMusicNote, faMusicNoteSlash } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faBars } from '@fortawesome/pro-regular-svg-icons'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setVisibleInventory, setClickMap, setClickCampus, setDanceValue, setDisPlayChat, setDisPlayEmotions, setDisPlaySettings, setDisplayMyPage, setWebRtcComponent } from '../redux/BabylonSettings';
// import { removeCookie } from '../middleware/Cookie'
import { setUserInfo, Info } from '../redux/UserInfo';

import { socket } from '../middleware/Socket';
import ActionPopup from './ActionPopup';
import { useLocation } from 'react-router-dom';
import NpcScript from './NpcScript';
import { StoreState } from '../redux';
import GuestBook from './GuestBook';
import { useNavigate } from 'react-router-dom';

//babylon importing
import { bubbleStateStart, bubbleStateEnd } from '../babylon/BabylonModule'
import { clearUsers } from '../redux/OtherUsers';
import { setInitBabylonSet } from '../redux/BabylonSettings';
import styled from 'styled-components';
import Mypage from './Mypage';
import { useCookies } from 'react-cookie';

const TutorialContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    & > .img-box {
        width: 100%;
        max-width: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    & > .img-box > img {
        width: 100%;
    }
`

const CancelButton = styled.button`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(35%, -35%);
    color : #000;
    font-size: 30px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: #e7e7e7;
    }
`

const FloatingIcon = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 5px 9px 0 rgb(0 0 0 / 31%);
    font-size: 20px;
    cursor: pointer;
    color: #1a3480;
    transition: background-color cubic-bezier(0.35, 0.18, 0.53, 0.91) 0.25s;
    position: relative;
    background-color: rgb(255 255 255);
    &.checked {
        background-color: rgba(26, 52, 128, 0.8);
        color : #fff;
    }
    &.mypageIcon {
        font-size: 28px;
    }

    &:hover > span {
        opacity: 1;
    }
`


function StateButtons() {
    const [cookies, setCookie, removeCookie] = useCookies(['token'])
    const [visibleTutorial, setVisibleTutorial] = useState(true);
    const [minimap, setMinimap] = useState('future');
    const effectSoundRef = useRef() as React.MutableRefObject<HTMLAudioElement>;
    const navigate = useNavigate();
    const audio = useRef() as React.MutableRefObject<HTMLAudioElement>;
    const urlPath = useLocation().pathname.split('/')[1];
    const [msg, setMsg] = useState("");
    const [volume, setVolume] = useState(false);
    const [activeEffectSound, setActiveEffectSound] = useState(true);
    const [cancelMiniMap, setCancelMiniMap] = useState(false);
    const [activeHamburger, setActiveHamburger] = useState(false);
    const chatContainer = useRef() as React.MutableRefObject<HTMLUListElement>;
    const { guestbook, visibleIventory, clickMap, ClickCampus, disPlayMyPage, disPlayNpc, disPlayChat, disPlayEmotions, disPlaySettings, fishingMode, toatalGameMode, webRtcComponent } = useSelector((state: StoreState) => ({
        guestbook: state.BabylonSettings.guestbook,
        visibleIventory: state.BabylonSettings.visibleInventory,
        clickMap: state.BabylonSettings.clickMap,
        ClickCampus: state.BabylonSettings.ClickCampus,
        disPlayMyPage: state.BabylonSettings.disPlayMyPage,
        portalAction: state.BabylonSettings.portalAction,
        allReady: state.BabylonSettings.allReady,
        disPlayNpc: state.BabylonSettings.disPlayNpc,
        disPlayChat: state.BabylonSettings.disPlayChat,
        disPlayEmotions: state.BabylonSettings.disPlayEmotions,
        disPlaySettings: state.BabylonSettings.disPlaySettings,
        fishingMode: state.BabylonSettings.fishingMode,
        currentItems: state.UserInfo.currentItems,
        toatalGameMode: state.BabylonSettings.toatalGameMode,
        webRtcComponent: state.BabylonSettings.webRtcComponent,

    }), shallowEqual);
    const nickname = useSelector((state: StoreState) => (state.UserInfo.nickname), shallowEqual);
    const dispatch = useDispatch();
    const SetClickCampus = (isClick: boolean) => dispatch(setClickCampus(isClick));
    const SetVisibleInventory = (visibleInventory: boolean) => dispatch(setVisibleInventory(visibleInventory));
    const SetClickMap = (isClick: boolean) => dispatch(setClickMap(isClick));
    const SetDisplayMypage = (active: boolean) => dispatch(setDisplayMyPage(active))
    const SetDanceValue = (value: boolean | number) => dispatch(setDanceValue(value));
    const SetDisPlayEmotions = (value: boolean) => dispatch(setDisPlayEmotions(value));
    const SetDisPlayChat = (value: boolean) => dispatch(setDisPlayChat(value));
    const SetDisPlaySettings = (value: boolean) => dispatch(setDisPlaySettings(value));
    const SetWebRtcComponent = (webRtcComponent: boolean) => dispatch(setWebRtcComponent(webRtcComponent));    

    const SetUserInfo = (userinfo: Info) => dispatch(setUserInfo(userinfo));
    const SetInitBabylonSet = () => dispatch(setInitBabylonSet());
    const ClearUsers = () => dispatch(clearUsers());
    const submitSocketMsg = (nickname: string, msg: string) => {
        socket.emit('MSG', nickname, msg);
    }
    const submitSocketEmotion = (filteringEmotionWord: any) => {
        socket.emit("CHAT_EMOTION", filteringEmotionWord);
    }
    const logout = () => {
        removeCookie('token', {path : '/', sameSite : true});
        SetUserInfo({
            id: '',
            nickname: 'Guest',
            token: '',
            chatbot_id : 0,
            ioted_id : 0,
            items: {
                hair: "hair_0",
                cloth: "cloth_0",
                shoes: "shoes_0",
                eyebrow: "eyebrow_0"
            },
            position: {
                x: "-24.579",
                y: "-0.564",
                z: "-1.893"
            },
            rotation: {
                x: "0",
                y: '84',
                z: "0"
            },
            campus: "future",
            role: 2,
            coin: 0,
            status_message: ''
        })
        SetInitBabylonSet();
        ClearUsers();
        navigate('/');
    }
    useEffect(() => {
        var html = chatContainer.current;
        setTimeout(() => {
            socket.on('_MSG', (nickname, msg) => {
                const msgEl = document.createElement('li');
                msgEl.innerHTML = `<b style="color: #ffdd00">${nickname}</b> : ${msg}`;
                html.appendChild(msgEl)
                html.scrollTop = html.scrollHeight
            })
            socket.on('_CHECK_MSG', (flags, msg) => {
                if (flags.flag) {
                    submitSocketMsg(nickname, msg)
                    // socket.emit('MSG', nickname, msg);
                    const msgEl = document.createElement('li');
                    msgEl.innerHTML = `<b style="color: #8cff46">${nickname}</b> : ${msg}`;
                    chatContainer.current.appendChild(msgEl);
                    html.scrollTop = html.scrollHeight
                    setMsg('');
                } else {
                    const msgEl = document.createElement('li');
                    msgEl.style.color = '#ff0000';
                    msgEl.textContent = `[시스템] 부적절한 단어를 포함하고 있습니다.`;
                    chatContainer.current.appendChild(msgEl);
                    setMsg('');
                }
                if (flags.filteringEmotionWord) {
                    submitSocketEmotion(flags.filteringEmotionWord);
                    // socket.emit("CHAT_EMOTION", flags.filteringEmotionWord);
                }
            })
        }, 500)

        socket.on('_callWebRtcComponent', () => {
            if(!webRtcComponent){
                SetWebRtcComponent(true);  
            }
        })

        document.querySelector('#message')?.addEventListener('focus', (e) => {
            if (!document.querySelector('.mychat-container')?.classList.contains('open')) {
                document.querySelector('.mychat-container')?.classList.add('open');
                document.querySelector('.mychat-container ul')?.classList.add('open');
                document.querySelector('.inputWrap input')?.classList.add('open');
                bubbleStateStart("chat");
            }
        })
        document.querySelector('#babylonCanvas')?.addEventListener('click', () => {
            if (document.querySelector('.mychat-container')?.classList.contains('open')) {
                document.querySelector('.mychat-container')?.classList.remove('open');
                document.querySelector('.mychat-container ul')?.classList.remove('open');
                document.querySelector('.inputWrap input')?.classList.remove('open');
                html.scrollTop = html.scrollHeight
                bubbleStateEnd();
            }
        })
        const BGMHandler = () => {
            if (!volume) {
                setVolume(true)
                document.body.removeEventListener('click', BGMHandler);
            };
        }
        document.body.addEventListener('click', BGMHandler)
        setMinimap(urlPath);

        // 효과음
        effectSoundRef.current.volume = 0.4;
    }, [])

    useEffect(() => {
        if (volume) {
            audio.current.volume = 0.06;
            audio.current.play();
        } else {
            audio.current.pause();
        }
    }, [volume])
    useEffect(() => {
        if (guestbook) {
            audio.current.pause();
            setVolume(false);
        }
    }, [guestbook])
    // 채팅 아이콘 클릭 이벤트
    const onToggleChat = useCallback(() => {
        SetDisPlayChat(!disPlayChat);
        setTimeout(() => {
            var html = chatContainer.current;
            html.scrollTop = html.scrollHeight
        }, 100)
    }, [disPlayChat])

    /**
     * 튜토리얼 핸들러
     */
    const onClickTutorialHandler = () => {
        setVisibleTutorial(false);
    }
    /**
     * 플로팅아이콘-마이페이지 클릭 핸들러
     */
    const onClickMypageIconHandler = useCallback(() => {
        if (activeEffectSound) {
            effectSoundRef.current.play()
        }
        SetDisplayMypage(!disPlayMyPage);
    }, [activeEffectSound, disPlayMyPage])
    // 인벤토리 아이콘 클릭 이벤트
    const onClickInventory = useCallback(() => {
        if (activeEffectSound) {
            effectSoundRef.current.play()
        }
        SetVisibleInventory(!visibleIventory)
    }, [visibleIventory, activeEffectSound])

    // 채팅 인풋 체인이 이벤트
    const onChangeChatInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setMsg(e.target.value)
    }, [])

    const submitSocketMsgCheck = (msg: string) => {
        if (msg === '') {
            // window.confirm("메세지를 입력해주세요!");
        } else {
            socket.emit('CHECK_MSG', msg);
        }
    }
    // 채팅 엔터 이벤트
    const obSubmitChatInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            submitSocketMsgCheck(msg)
        }     
    }
    // 채팅 클릭이벤트
    const onClickSubmit = (e: any) => {
        if (msg !== '')
            return submitSocketMsgCheck(msg)
    }
    // 미니맵 클릭 이벤트
    const onClickMap = useCallback((e: any) => {
        if (activeEffectSound) {
            effectSoundRef.current.play()
        }
        if (!clickMap) {
            SetClickMap(true);
        } else {
            SetClickMap(false);
            setCancelMiniMap(false);
        }
    }, [clickMap, minimap, activeEffectSound])

    useEffect(() => {
        if (clickMap) {
            setTimeout(() => {
                setCancelMiniMap(true);
            }, 1100)
        } else {
            setCancelMiniMap(false);
        }
    }, [clickMap])
    // 이펙트사운드 
    const onClickEffectSound = useCallback(() => {
        if (activeEffectSound) {
            setActiveEffectSound(false)
        } else {
            setActiveEffectSound(true)
        }
    }, [activeEffectSound])
    // bgm 이벤트
    const onClickVolume = useCallback(() => {
        if (volume) {
            setVolume(false)
        } else {
            setVolume(true)
        }
    }, [volume])

    // 애니메이션 클릭 이벤트
    const onClickEmotion = useCallback((e: any) => {
        const value = Number(e.target.value);
        SetDanceValue(value)
        SetDisPlayEmotions(false)
    }, [])
    // 미니맵 이미지
    const checkMiniap = () => {
        if (minimap === 'future') {
            return future_Minimap
        } else if (minimap === 'present') {
            return present_Minimap
        }

    }
    const onClickHamburger = useCallback(() => {
        if (activeHamburger && ClickCampus) {
            SetClickCampus(false)
        }
        setActiveHamburger(!activeHamburger);
    }, [activeHamburger, ClickCampus])

    const onClickSettingIcon = () => {
        if (activeEffectSound) {
            effectSoundRef.current.play()
        }
        if (disPlaySettings) {
            SetDisPlaySettings(false)
        } else {
            if (window.innerWidth >= 1024) {
                SetDisPlaySettings(true)
            } else {
                if (activeHamburger) {
                    setActiveHamburger(false);
                    SetDisPlaySettings(true)
                } else {
                    SetDisPlaySettings(true)
                }
            }
        }
    }
    const onClickEmotionsHandler = () => {
        if (activeEffectSound) {
            effectSoundRef.current.play()
        }
        SetDisPlayEmotions(!disPlayEmotions)
    }
    return (
        <>
            <audio src="/assets/sound/ui.wav" ref={effectSoundRef}></audio>
            <audio src="https://pict1.cafe24.com/bgm.mp3" ref={audio} loop></audio>
            {/* 미니맵 취소 버튼 */}
            <button className={cancelMiniMap ? 'cancelMiniMap' : "cancelMiniMap close"} onClick={onClickMap}>
                <FontAwesomeIcon icon={faXmark} className="fa-Volume" />
            </button>
            <div className={!clickMap ? 'topRightContainer' : 'topRightContainer close'}>
            </div>

            {/* 플로팅 아이콘 */}
            <div className="iconsContainer">
                {/* 채팅 아이콘 모바일에서만 활성화 */}
                <button className={disPlayChat ? "chatIcon checked" : "chatIcon"} onClick={onToggleChat}>

                    <FontAwesomeIcon icon={faComment} className="fa-chatIcon" />
                </button>
                {/* 마이페이지 */}
                <button className={disPlayMyPage ? "friendsIcon" : "friendsIcon"} onClick={onClickMypageIconHandler}>
                    <span>마이페이지</span>
                    <FontAwesomeIcon icon={faUser} />
                </button>
                {/* 인벤토리 */}
                <button className={toatalGameMode ? "inventoryIcon animation" : visibleIventory ? "inventoryIcon checked" : "inventoryIcon"} onClick={onClickInventory}>
                    <span>아바타</span>
                    <FontAwesomeIcon icon={faTShirt} className="fa-inventoryIcon" />
                </button>
                {/* 세팅 아이콘 */}
                <div className={toatalGameMode ? 'settingPoz animation' : 'settingPoz'}>
                    <button className={disPlaySettings ? "settingsIcon checked" : "settingsIcon"} onClick={() => onClickSettingIcon()} >
                        <span>환경설정</span>
                        <FontAwesomeIcon icon={faGear} />
                    </button>
                    <div className="settingList" >
                        {/* 로그아웃 */}
                        <div className={disPlaySettings ? 'logoutIcon hiddenIcon active' : 'logoutIcon hiddenIcon'} onClick={logout}>
                            <FontAwesomeIcon icon={faSignOut} />
                            <span className="hiddenText">로그아웃</span>
                        </div>

                        {/* 효과음 */}
                        <div className={disPlaySettings ? 'effect-sound hiddenIcon active' : 'effect-sound hiddenIcon'} onClick={onClickEffectSound}>
                            {activeEffectSound ? <FontAwesomeIcon icon={faMusicNote} /> : <FontAwesomeIcon icon={faMusicNoteSlash} />}
                            <span className="hiddenText">{activeEffectSound ? "효과음" : "효과음 해제"}</span>
                        </div>

                        {/* 볼륨 */}
                        <div className={disPlaySettings ? 'bgmIcon hiddenIcon active' : 'bgmIcon hiddenIcon'} onClick={onClickVolume}>
                            {volume ? <FontAwesomeIcon icon={faMusic} className="fa-Volume" /> : <FontAwesomeIcon icon={faMusicSlash} className="fa-Volume" />}
                            <span className="hiddenText">{volume ? "음소거" : "음소거 해제"}</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* 모바일-아이콘 햄버거 */}
            <nav className={activeHamburger ? 'mobile-menu-container active' : disPlaySettings ? 'mobile-menu-container hidden' : 'mobile-menu-container'}>
                <button className='hamburger' onClick={onClickHamburger}>
                    {!activeHamburger ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faTimes} />}
                </button>
                <ul>
                    <li>
                        <button className='friendsIcon' onClick={onClickMypageIconHandler}>
                            <FontAwesomeIcon icon={faUser} />
                        </button>
                    </li>
                    <li>
                        <button className={visibleIventory ? "inventoryIcon checked" : "inventoryIcon"} onClick={onClickInventory}>
                            <FontAwesomeIcon icon={faTShirt} className="fa-inventoryIcon" />
                        </button>
                    </li>
                </ul>
            </nav>
            <div className={disPlayChat ? "mychat-container" : "mychat-container close"}>
                <ul ref={chatContainer}>
                </ul>
                <div className="inputWrap">
                    {/* 모바일에서는 보내기 버튼 활성화 */}
                    {/* pc에서는 춤추는 아이콘 */}
                    <div className={disPlayEmotions ? 'myEmotionIcon pc checked' : "myEmotionIcon pc"} onClick={onClickEmotionsHandler}>
                        <button className={disPlayEmotions ? 'activeEmotion checked' : 'activeEmotion'}></button>
                        <div className={disPlayEmotions ? 'emotionsContainer open' : 'emotionsContainer'}>
                            <button className='emotionButton' value='1' onClick={onClickEmotion}>넘나신나</button>
                            <button className='emotionButton' value='2' onClick={onClickEmotion}>트위스트</button>
                            <button className='emotionButton' value='3' onClick={onClickEmotion}>덩실덩실</button>
                            <button className='emotionButton' value='4' onClick={onClickEmotion}>파도타기</button>
                            <button className='emotionButton' value='5' onClick={onClickEmotion}>울라울라</button>
                            <button className='emotionButton' value='6' onClick={onClickEmotion}>인디언춤</button>
                            <button className='emotionButton' value='7' onClick={onClickEmotion}>씰룩씰룩</button>
                            <button className='emotionButton' value='8' onClick={onClickEmotion}>저질댄스</button>
                            <button className='emotionButton' value='9' onClick={onClickEmotion}>두비두밥</button>
                            <button className='emotionButton' value='10' onClick={onClickEmotion}>트월트월</button>
                        </div>
                    </div>
                    <input type="text" name="message" id="message" autoComplete='off' placeholder="메세지를 입력해주세요." onChange={onChangeChatInput} value={msg} onKeyUp={obSubmitChatInput} />
                </div>
            </div>
            <div className={!clickMap ? `${minimap} map` : `${minimap} map close`} onClick={onClickMap}>
                <img src={checkMiniap()} alt="월드맵" />
                <span className="worldtxt">월드맵</span>
            </div>
            {/* 포탈 위에 있을 때 */}
            <ActionPopup />
            {/* 마이페이지 */}
            {disPlayMyPage && <Mypage />}
            {/* npc스크립트 */}
            {disPlayNpc !== false && (disPlayNpc === 1 || disPlayNpc === 2 || disPlayNpc === 3 || disPlayNpc === 4 || disPlayNpc === 5 || disPlayNpc === 6 || disPlayNpc === 7 || disPlayNpc === 8 || disPlayNpc === 101 || disPlayNpc === 102 || disPlayNpc === 103 || disPlayNpc === 104 || disPlayNpc === 105 || disPlayNpc === 106 || disPlayNpc === 107 || disPlayNpc === 108 || disPlayNpc === 109) && <NpcScript />}
            {/* 자유방명록 */}
            {guestbook && <GuestBook />}
            {/* {visibleTutorial && <TutorialContainer>
                <div className='img-box'>
                <CancelButton onClick={onClickTutorialHandler}>
                    <FontAwesomeIcon icon={faXmark} />
                </CancelButton>
                    <img src={tutorialImg} alt="" />
                </div>
            </TutorialContainer>} */}
        </>
    );
}

export default StateButtons;