import { io } from "socket.io-client"

export const socket = io(process.env.REACT_APP_SOCKET_URL, {autoConnect: false});

export const Socket_connection = (isMobile, campusName, nickname, userPos, userRot, currentItems, reduxId) => {
    socket.connect();
    socket.on("connect", () => {
    });

    socket.emit('ENTER_USER', {
        device: isMobile,
        campus: campusName,
        userInfo: {
            nickname: nickname,
            position: { x: userPos.x, y: userPos.y, z: userPos.z },
            rotation: { x: 0, y: userRot.y, z: 0 },
            items: {
                hair: currentItems.hair.split('_')[1],
                cloth: currentItems.cloth.split('_')[1],
                shoes: currentItems.shoes.split('_')[1],
                eyebrow: currentItems.eyebrow.split('_')[1]
            }
        },
        id: reduxId
    })
}

export const Socket_disconnection = () => {
    socket.removeAllListeners();
    socket.disconnect();
    socket.close()
}