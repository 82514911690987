import {  useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react';
import "../css/usertoolBox.css"
import {socket} from '../middleware/Socket'
import type {StoreState} from '../redux'
import { setClickInfo, setClickUser, setOpenWait, setWebRtcComponent } from '../redux/BabylonSettings';
import React from 'react';

function UserToolBox({myStream} : any) {
    const {clickInfo, peerConnectId} = useSelector((state : StoreState) => ({
        clickInfo : state.BabylonSettings.clickInfo,
        peerConnectId : state.BabylonSettings.peerConnectId,
    }));

    const dispatch = useDispatch();
    const SetClickUser = (clickUserStatus: boolean) => dispatch(setClickUser(clickUserStatus));
    const SetOpenWait = (openWaitStatus: boolean) => dispatch(setOpenWait(openWaitStatus));
    const SetClickInfo = (clickInfo: any) => dispatch(setClickInfo(clickInfo));
    const SetWebRtcComponent = (webRtcComponent: boolean) => dispatch(setWebRtcComponent(webRtcComponent));

    const toolBox = useRef() as React.MutableRefObject<HTMLDivElement>
    useEffect(()=> {
        if (toolBox.current) {
            toolBox.current.style.top = `${clickInfo.y}px`
            toolBox.current.style.left = `${clickInfo.x}px`
            toolBox.current.style.zIndex = '99'
        }
    }, [toolBox])


    useEffect(() => {
        // 유저 클릭시 webRtc 컴포넌트 호출
        SetWebRtcComponent(true)
        socket.emit('callWebRtcComponent', clickInfo.socketid);
    }, [])
    
    const onTryConnect = () => {
        SetClickUser(false);
        SetOpenWait(true);
        SetClickInfo({status:"calling"});
        console.log(clickInfo.socketid)
        socket.emit('tryPeer', clickInfo.socketid, peerConnectId);
    }

    return(
        <>
            {/* <div className='UserToolBoxContainer' ref={toolBox} onContextMenu={(e) => {e.preventDefault();}}> */}
                <div className='UserToolBoxParents' ref={toolBox} onContextMenu={(e) => {e.preventDefault();}}>
                    <span className='UserToolBoxBtn' onClick={onTryConnect} >화상 대화 신청</span>
                </div>
            {/* </div>   */}
        </>
    );
}

export default UserToolBox;