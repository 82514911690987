import React from 'react';
import "../css/ioted.css";

interface CHATBOT {
    cbt_comment_count : number
    cbt_create_date : string
    cbt_intro : string
    cbt_is_private : number
    cbt_language_type : number
    cbt_like_count : number
    cbt_name : string
    cbt_profile_url : string
    cbt_talk_count : number
    cbt_type : number
    cbt_voice_type : number
    id : number
    user_id : number
}

interface Props {
    chatbots : CHATBOT[]
    onChange : (e:React.ChangeEvent<HTMLInputElement>) => void
    checkedValue : number
    onSumbit : () => void
}


function Ioted({chatbots, onChange, checkedValue, onSumbit} : Props) {
    return (
        <div className="wrapper">
            <div className="inner-wrap">
                <div className="my-profile">
                    <ul className="profile-list">
                        {chatbots.map((chatbot, index)=> {
                            return (
                                <li key={index}>
                                    <label htmlFor={`item_${index}`} className='item'>
                                        <input type="radio" name="profile" id={`item_${index}`} onChange={onChange} value={chatbot.id} checked={checkedValue === chatbot.id} />
                                            <div className="pro-pics">
                                                <div className="pro-img">
                                                    <img src={chatbot.cbt_profile_url ? chatbot.cbt_profile_url : `/assets/img/ioted/empty_7.png`} alt="" />
                                                </div>
                                                <div className="nick">
                                                    <p>{chatbot.cbt_name}</p>
                                                </div>
                                            </div>
                                        <div className='item-checking'></div>
                                    </label>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='btn-wrap'>
                    <button className='in-btn' onClick={onSumbit}>입장하기</button>
                </div>
            </div>
        </div>
    );
}

export default Ioted;