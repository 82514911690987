import { useEffect, useState } from "react";
import FutureDefaultCharacter from './FutureDefaultCharacter'
import * as BBL from '../BabylonModule'
import * as NG from '../NAVIGATOR'
import { SceneLoader, Mesh } from '@babylonjs/core'
function FutureMap() {
    const [load, setLoad] = useState(false);
    useEffect(() => {
        const scene = BBL.getScene(); 

        SceneLoader.LoadAssetContainer('./assets/model/', 'MapFuture.glb', scene, (container) => { 
            let nav = container.meshes[1];

            let lavaMat = container.materials[5];
            lavaMat.roughness = 0.21;
            lavaMat.metallic = 0;
            lavaMat.useRoughnessFromMetallicTextureAlpha = true;
            lavaMat.useRoughnessFromMetallicTextureGreen = false;
            lavaMat.useMetallnessFromMetallicTextureBlue = false;        
            scene.registerBeforeRender(function () {
                lavaMat.albedoTexture.uOffset -= 0.001;
                lavaMat.albedoTexture.vOffset -= 0.0005;
                lavaMat.emissiveTexture.uOffset -= 0.001;
                lavaMat.emissiveTexture.vOffset -= 0.0005;
                lavaMat.bumpTexture.uOffset -= 0.001;
                lavaMat.bumpTexture.vOffset -= 0.0005;
            });

            let mergeFloors = Mesh.MergeMeshes([
                container.meshes[4],
                container.meshes[5],
                container.meshes[6]
            ], true, true, null, false, true);
            mergeFloors.name = "mergeFloors";
            mergeFloors.freezeWorldMatrix();         

            let mergeProps = Mesh.MergeMeshes([
                container.meshes[2],
                container.meshes[3],
            ], true, true, null, false, true);
            mergeProps.name = "mergeProps";
            mergeProps.freezeWorldMatrix();
            
            BBL.grassMatSet(container.materials[3]);
            BBL.brickMatSet(container.materials[1]);
            BBL.brickMatSet(container.materials[4]);
            BBL.mapMatSetup(container.materials[2]);
            let drawShadowMeshes = [
                mergeProps
            ];
            let receiveShadowMeshes = [
                mergeFloors
            ];      
            BBL.waterAndShadowSetup(container.meshes[0], null, [], drawShadowMeshes, receiveShadowMeshes);
            BBL.setNavMesh(nav, false, -0.055);
            container.addAllToScene();

            NG.createAutoNavMesh();
            if (NG.getNavPlugin().navMesh) {
                setLoad(!load);
            }    
                   
        });

    }, [])

    return (
        <>
            {
                load && <FutureDefaultCharacter />
            }
        </>
    );
}

export default FutureMap;