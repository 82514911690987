import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import UserInfo, {USERINFO} from './UserInfo';
import OtherUsers, {Users} from './OtherUsers';
import BabylonSettings, { SETTINGS } from './BabylonSettings';

// const persistConfig = {
//     key : "root",
//     storage,
//     whitelist: ["UserInfo"]
// };

interface StoreState {
    UserInfo : USERINFO,
    BabylonSettings : SETTINGS,
    OthserUsers : Users[]
}

export type {StoreState};

const rootReducer = combineReducers({
    UserInfo,
    OtherUsers,
    BabylonSettings
})

export default rootReducer;