import BabylonSet from './BabylonSet'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getCookie, removeCookie } from '../middleware/Cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { setUserInfo, Info } from '../redux/UserInfo';
import { StoreState } from '../redux';

function Future() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const redux_token = useSelector((state: StoreState) => (state.UserInfo.token), shallowEqual)
    const SetUserInfo = (userinfo: Info) => dispatch(setUserInfo(userinfo));
    const [load, setLoad] = useState(false);
    const value = "future";
    useEffect(() => {
        let completed = false;
        if (!completed) {
            const token = getCookie('token');
            if (token && !redux_token) {
                axios.post(`${process.env.REACT_APP_API_URL}/green_select`).then(res=> {
                    axios.post(`${process.env.REACT_APP_API_URL}/check_token`, {
                        token,
                    })
                        .then((response) => {
                            if (response.data.callback === 500 || response.data.callback === 501) {
                                removeCookie('token');
                                navigate('/')
                            } else if (response.data.callback === 200) {
                                const { data } = response.data
                                const role = 1;
                                let campus = 'future'
                                if (res.data.cnt >= 30) {
                                    campus = 'present'
                                } else {
                                    campus = 'future'
                                }
                                SetUserInfo({
                                    id: data.id,
                                    nickname: data.nick_name,
                                    token: data.token,
                                    chatbot_id : data.current_chatbot_id,
                                    ioted_id : data.ioted_id,
                                    items: {
                                        hair: data.hair,
                                        cloth: data.cloth,
                                        shoes: data.shoes,
                                        eyebrow: data.eyebrow ? data.eyebrow : 'eyebrow_0',
                                    },
                                    position: {
                                        x: "-24.579",
                                        y: "-0.564",
                                        z: "-1.893"
                                    },
                                    rotation: {
                                        x: "0",
                                        y: "84",
                                        z: "0",
                                    },
                                    campus,
                                    role: role,
                                    coin: data.coin,
                                    status_message: data.status_message ? data.status_message : ''
                                });
                                setLoad(true);
                                if (res.data.cnt >= 30) {
                                    navigate('/present')
                                } else {
                                    setLoad(true);
                                }
                            }
                        })
                })
            } else if (!token) {             
                navigate('/')
            } else if (token && redux_token) {
                setLoad(true);
            }
        }
        return () => {
            completed = true;
            setLoad(false);
        }
    }, [])
    return (
        <>
            {load && <BabylonSet value={value}></BabylonSet>}
        </>
    )
};
export default Future;