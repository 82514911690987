import {useEffect} from "react";
import { useNavigate } from 'react-router-dom'

function ErrorPage() {
    const navigation = useNavigate();
    useEffect(()=> {
        window.alert("존재하지 않는 URL입니다")
        navigation("/");
    })
    return(
        <></>
    );
}

export default ErrorPage;