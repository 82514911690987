import React, {MouseEventHandler, useState, useRef, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'

interface props {
    onClick: MouseEventHandler<HTMLButtonElement>
    size ?: number
    backgroundColor?: string
    color?: string
    position?: "relative" | "fixed" | "absolute",
    style ?: React.CSSProperties
}

function Button({onClick,size,backgroundColor, color, position, style }: props) {
    const styles = useRef<React.CSSProperties>({
        ...style,
        position,
        backgroundColor,
        color,
        width : `${size}px`,
        height : `${size}px`,
        borderRadius : '50%',
        cursor : "pointer",
        display : 'felx',
    })
    return (
        <button className='button-component' style={styles.current} onClick={onClick} >
            <FontAwesomeIcon icon={faXmark} />
        </button>
    );
}

Button.defaultProps = {
    size : 40,
    backgroundColor : 'raba(255,255,255,0.8)',
    color : '#1a317c'
}

export default Button;