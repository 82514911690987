import {UserInfoTypes} from './types'

interface friend {
    id: string,
    idx: number,
    nick_name: string,
    online : boolean,
    status_message : string | null
}

interface mail {
    idx: number
    user_id: string
    target_id: string
    category: string
    title: string
    text: string
    reg_date: string
    isRead: boolean,
    friend_id: number
    friend_name : string
    actived: boolean
}

interface USERINFO {
    id : number | string,
    nickname : string,
    token : string,
    socketId : string,
    chatbot_id : number
    ioted_id : number | null
    currentItems : {
        eyebrow : string
        hair : string,
        cloth : string,
        shoes : string
    },
    originItems : {
        eyebrow : string,
        hair : string,
        cloth : string,
        shoes : string
    }
    position : {
        x : string,
        y: string,
        z: string
    }
    rotation : {
        x : string,
        y: string,
        z : string
    }
    campus : string,
    role : number,
    friendsList : Array<friend>
    mailList : Array<mail>
    coin : number,
    status_message : string
}
const initialState : USERINFO = {
    id : 0,
    nickname : "",
    token : "",
    socketId : "",
    chatbot_id : 0,
    ioted_id : null,
    currentItems : {
        eyebrow : "",
        hair : "",
        cloth : "",
        shoes : ""
    },
    originItems : {
        eyebrow : "",
        hair : "",
        cloth : "",
        shoes : ""
    },
    position : {
        x : '',
        y : '',
        z : ''
    },
    rotation : {
        x : '',
        y : '',
        z : ''
    },
    campus : '',
    role : 1,
    friendsList : [],
    mailList : [],
    coin : 0,
    status_message : ''
}

export type {USERINFO};

const SET_USER_INFO = "SET_USER_INFO";
const SET_Avatar_Meshes = "SET_Avatar_Meshes";
const SET_SOCKETID = "SET_SOCKETID"
const SET_CURRENT_ITEMS = "SET_CURRENT_ITEMS"
const SET_ORIGIN_ITEMS = "SET_ORIGIN_ITEMS"
const CHANGE_CAMPUS = 'CHANGE_CAMPUS'
const SET_INIT_STATE = 'SET_INIT_STATE'
const SET_FRIENDS_LIST = 'SET_FRIENDS_LIST'
const SET_CHANGE_FRIENDS_STAT = "SET_CHANGE_FRIENDS_STAT"


interface Info {
    id : number | string,
    nickname : string
    token : string,
    chatbot_id : number
    ioted_id : number
    items : {
        eyebrow : string
        hair : string,
        cloth : string,
        shoes : string
    },
    position : {
        x : string,
        y: string,
        z: string
    },
    rotation : {
        x : string,
        y: string,
        z : string
    },
    coin : number,
    campus : string,
    role : number,
    status_message : string
}
export type {Info};

export const setInitState = () => ({
    type : SET_INIT_STATE,
});

export const setUserInfo  = ({id,ioted_id,nickname, token, items,chatbot_id, position, rotation, campus, role, coin, status_message} : Info) => ({
    type : SET_USER_INFO,
    id,
    ioted_id,
    token,
    chatbot_id,
    nickname,
    items,
    position,
    rotation,
    campus,
    role,
    coin,
    status_message
})

export const setAvatarMeshes = (meshes : any, itemType: string) => ({
    type : SET_Avatar_Meshes,
    meshes,
    itemType
})

export const setSocketID = (socketId : string) =>({
    type : SET_SOCKETID,
    socketId
})

export const setCurrentItems = (items : any) => ({
    type : SET_CURRENT_ITEMS,
    items
})

export const setOriginItems = (items : any) => ({
    type : SET_CURRENT_ITEMS,
    items
})


export const changeCampus = (campus:string, position : {x : string,  y : string,  z : string}, rotation : {x : string, y: string, z : string}) => ({
    type : CHANGE_CAMPUS,
    campus,
    position,
    rotation
})


export const setFriendsList = (userList : Array<friend>) => ({
    type : SET_FRIENDS_LIST,
    userList
})

export const setChangeFriendsStat = (friendId : string, online : boolean) => ({
    type : SET_CHANGE_FRIENDS_STAT,
    friendId,
    online
})

export const setAddFriend = (friend : friend) => ({
    type : UserInfoTypes.SET_ADD_FRIEND,
    friend
})

export const setMailList = (mailList : Array<mail>) => ({
    type : UserInfoTypes.SET_MAIL_LIST,
    mailList
})

export const setReadMail = (idx : number) => ({
    type : UserInfoTypes.SET_READ_MAIL,
    idx
})

export const addNewMail = (mail : mail) => ({
    type : UserInfoTypes.SET_NEW_MAIL,
    mail
})

export const setRemoveMail = (idx : number) => ({
    type : UserInfoTypes.SET_REMOVE_MAIL,
    idx
})

export const setCoin = (coin : number) => ({
    type : UserInfoTypes.SET_CONIN,
    coin
})


export const setStatusMessage = (value : string) => ({
    type: UserInfoTypes.SET_STATUS_MESSAGE,
    value
})

export const setChangeUserNickname = (nickname : string) => ({
    type : UserInfoTypes.SET_CHANGE_NICKNAME,
    nickname
})

interface setChangeUserNicknameAction {
    type : UserInfoTypes.SET_CHANGE_NICKNAME,
    nickname : string
}
interface setInitStateAction {
    type : UserInfoTypes.SET_INIT_STATE,
}

interface setUserInfoAction {
    type : UserInfoTypes.SET_USER_INFO,
    id : number | string,
    token : string,
    nickname : string,
    chatbot_id : number
    ioted_id : number
    items : {
        hair : string,
        cloth : string,
        shoes : string
    },
    position :  {
        x : string,
        y : string,
        z : string
    }
    rotation : {
        x : string,
        y : string,
        z : string       
    }
    coin : number
    campus : string,
    role : number,
    status_message : string
}

interface setAvatarMeshesAction {
    type : UserInfoTypes.SET_Avatar_Meshes,
    meshes : any,
    itemType : string
}

interface setSocketIdAction {
    type : UserInfoTypes.SET_SOCKETID,
    socketId : string
}

interface setCurrentItemsAction {
    type : UserInfoTypes.SET_CURRENT_ITEMS,
    items : {
        hair : string,
        cloth : string,
        shoes : string
    }
}

interface setOriginItemsAction {
    type : UserInfoTypes.SET_ORIGIN_ITEMS,
    items : {
        hair : string,
        cloth : string,
        shoes : string
    }
}

interface changeCampusAction {
    type : UserInfoTypes.CHANGE_CAMPUS,
    campus : string,
    position : {
        x : string,
        y : string,
        z : string
    },
    rotation : {
        x : string,
        y : string,
        z : string
    }
}

interface SetFriendsList {
    type : UserInfoTypes.SET_FRIENDS_LIST,
    userList : Array<friend>
}

interface setChangeFriendsStat {
    type : UserInfoTypes.SET_CHANGE_FRIENDS_STAT,
    friendId : string,
    online : boolean
}

interface SetAddFriend {
    type : UserInfoTypes.SET_ADD_FRIEND,
    friend : friend
}

interface setMailList {
    type : UserInfoTypes.SET_MAIL_LIST,
    mailList : Array<mail>
}

interface setReadMail {
    type : UserInfoTypes.SET_READ_MAIL,
    idx : number
}

interface addNewMail {
    type : UserInfoTypes.SET_NEW_MAIL,
    mail : mail
}

interface SetRemoveMail {
    type : UserInfoTypes.SET_REMOVE_MAIL,
    idx : number
}

interface setCoin {
    type : UserInfoTypes.SET_CONIN,
    coin : number
}

interface setStatusMessage {
    type: UserInfoTypes.SET_STATUS_MESSAGE,
    value : string
}



type Actions =
    | setUserInfoAction
    | setAvatarMeshesAction
    | setSocketIdAction
    | setCurrentItemsAction
    | setOriginItemsAction
    | changeCampusAction
    | setInitStateAction
    | SetFriendsList
    | setChangeFriendsStat
    | SetAddFriend
    | setMailList
    | setReadMail
    | addNewMail
    | SetRemoveMail
    | setCoin
    | setStatusMessage
    | setChangeUserNicknameAction


export default function UserInfo(state : USERINFO = initialState, action : Actions) {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state,
                id : action.id,
                nickname : action.nickname,
                token : action.token,
                chatbot_id : action.chatbot_id,
                ioted_id : action.ioted_id,
                currentItems : {
                    ...action.items
                },
                originItems : {
                    ...action.items
                },
                position : {
                    ...action.position
                },
                rotation : {
                    ...action.rotation
                },
                coin : action.coin,
                campus : action.campus,
                role : action.role,
                status_message : action.status_message
            }
        case SET_SOCKETID:
            return {
                ...state,
                socketID : action.socketId
            }
        case SET_Avatar_Meshes:
            return {
                ...state,
                avatarMeshes : {
                    [action.itemType] : action.meshes
                }
            }
        case SET_CURRENT_ITEMS:
            return {
                ...state,
                currentItems : {
                    ...action.items
                }
            }
        case SET_ORIGIN_ITEMS:
            return {
                ...state,
                originItems : {
                    ...action.items
                }
            }
        case CHANGE_CAMPUS:
            return {
                ...state,
                campus : action.campus,
                position : {
                    ...action.position
                },
                rotation : {
                    ...action.rotation
                }
            }
        case SET_INIT_STATE:
            return initialState;
        case SET_FRIENDS_LIST:
            return {
                ...state,
                friendsList : action.userList
            }
        case SET_CHANGE_FRIENDS_STAT:
            const newFriendsList = state.friendsList.map(user=> user.id === action.friendId ? {...user, online : action.online} : user);
            return {
                ...state,
                friendsList : [...newFriendsList]
            }
        case UserInfoTypes.SET_ADD_FRIEND:
            return {
                ...state,
                friendsList : [...state.friendsList.concat([action.friend])]
            }
        case UserInfoTypes.SET_MAIL_LIST:
            return {
                ...state,
                mailList : [...action.mailList]
            }
        case UserInfoTypes.SET_READ_MAIL:
            let newMailList = state.mailList.map(mail => mail.idx === action.idx ? {...mail, isRead : true} : mail);
            return {
                ...state,
                mailList : newMailList
            }
        case UserInfoTypes.SET_NEW_MAIL:
            return {
                ...state,
                mailList : [
                    ...state.mailList,
                    action.mail
                ]
            }
        case UserInfoTypes.SET_NEW_MAIL:
            return {
                ...state,
                mailList : state.mailList.concat(action.mail)
            }
        case UserInfoTypes.SET_REMOVE_MAIL:
            let removedMailList = state.mailList.filter(mail => mail.idx === action.idx ? false : true)
            return {
                ...state,
                mailList : removedMailList
            }
        case UserInfoTypes.SET_CONIN:
            return {
                ...state,
                coin : action.coin
            }
        case UserInfoTypes.SET_STATUS_MESSAGE:
            return {
                ...state,
                status_message : action.value
            }
        case UserInfoTypes.SET_CHANGE_NICKNAME:
            return {
                ...state,
                nickname : action.nickname
            }
        default:
            return state
    }
}

// const store = createStore(reducer);
// console.log(store.getState());

// const listener = () => {
//     const state = store.getState();
//     console.log(state)
// }

// const unsubcribe = store.subscribe(listener);
