import { Cookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie/cjs/types";

const cookies = new Cookies();


export const setCookie = (name : string, value : string, options?: CookieSetOptions) => {
    return cookies.set(name, value, {...options})
}

export const getCookie = (name: string) => {
    return cookies.get(name)
}

export const removeCookie = (name : string) => {
    cookies.remove(name);
    return true
}