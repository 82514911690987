import {BabylonSettingTypes} from './types'

interface board {        
    category: string, 
    class: string,
    depart: string,
    file_link: string | null,
    idx: number,
    img: string,
    link_url: string | null,
    rdcnt: string | null,
    reg_date: string,
    text: string,
    title: string,
    use_at: string
}

interface SETTINGS {
    allReady : boolean,
    importType : string,
    visibleInventory : boolean,
    navState : string,
    clickMap : boolean,
    ClickCampus : boolean,
    ClickClassRoom : boolean,
    disPlayNpc : boolean | number,
    disPlayMyPage : boolean,
    portalAction : boolean | number,
    danceValue : boolean | number,
    cameraActionAni : boolean,
    guestbook : boolean,
    calender : boolean,
    disPlayChat : boolean,
    disPlayEmotions : boolean,
    disPlaySettings : boolean,
    boardList : Array<board>,
    clickBoard : boolean | number,
    clickWishBoard : boolean | number,
    clickPoster : boolean | number,
    clickIdentity : boolean | string,
    clickRobotAPI : boolean,
    placeSubName : string 
    fishingMode : boolean,
    isStartFishing : boolean,
    fishingSuccess : number | boolean,
    fishList : Array<any>,
    displayFriends : boolean
    displayMail : boolean,
    displayFame : boolean,
    displayGameTutorial : boolean,
    countGrowTree : number,
    displayTree : boolean,
    toStoreSendMail : boolean | number,
    displayCafeInfo : boolean | number,
    displayGallery : boolean | string,
    toatalGameMode : boolean | string,
    clickUser : boolean,
    clickInfo : ClickInfo,
    openWait : boolean,
    openVideoArea : boolean,
    connectingMsg : boolean,
    videoChatCancelBtn : boolean,
    cameraSwitch : boolean,
    audioSwitch : boolean,
    peerConnectId : string,
    peerObj : object,
    peerNickName : string,
    reqConnect : boolean,
    webRtcComponent : boolean
}

interface ClickInfo {
    socketid : string,
    clickusernickname: string,
    x : number,
    y : number,
    status: string,
    peerId : string
}

export type { SETTINGS }


const initialState : SETTINGS = {
    allReady : false,
    importType : 'setup',
    visibleInventory : false,
    navState : 'ready',
    clickMap : false,
    ClickCampus : false,
    ClickClassRoom : false,
    disPlayNpc : false,
    disPlayMyPage : false,
    portalAction : false,
    danceValue : false,
    cameraActionAni : false,
    guestbook : false,
    calender : false,
    disPlayChat : false,
    disPlayEmotions : false,
    disPlaySettings : false,
    boardList : [],
    clickBoard : false,
    clickWishBoard : false,
    clickPoster : false,
    clickIdentity : false,
    clickRobotAPI : false,
    placeSubName : '',
    fishingMode : false,
    isStartFishing : false,
    fishingSuccess : false,
    fishList : [],
    displayFriends : false,
    displayMail : false,
    displayFame : false,
    displayGameTutorial : false,
    countGrowTree : 0,
    displayTree : false,
    toStoreSendMail : false,
    displayCafeInfo : false,
    displayGallery : false,
    toatalGameMode : false,
    clickUser: false,
    clickInfo: {
        socketid : '',
        clickusernickname : '',
        x : 0,
        y : 0,
        status: 'normal',
        peerId: ''
    },
    openWait: false,
    openVideoArea: false,
    connectingMsg: false,
    videoChatCancelBtn: false,
    cameraSwitch: false,
    audioSwitch: false,
    peerConnectId: '',
    peerObj: {},
    peerNickName: '',
    reqConnect: false,
    webRtcComponent : false
}



const SET_ALL_READY = "SET_ALL_READY";
const SET_IMPORT_TYPE = "SET_IMPORT_TYPE";
const SET_VISIBLE_INVENTORT = 'SET_VISIBLE_INVENTORT';
const SET_NAV_STATE = 'SET_NAV_STATE';
const SET_CLICK_MAP = "SET_CLICK_MAP";
const SET_CLICK_CAMPUS = 'SET_CLICK_CAMPUS';
const SET_CLICK_CLASSROOM = "SET_CLICK_CLASSROOM";
const SET_DISPLAY_NPC = 'SET_DISPLAY_NPC';
const SET_DISPLAY_MYPAGE = 'SET_DISPLAY_MYPAGE';
const SET_PORTAL_ACTION = 'SET_PORTAL_ACTION';
const SET_DANCE_VALUE = "SET_DANCE_VALUE";
const SET_INIT_BABYLON_SET = "SET_INIT_BABYLON_SET";
const SET_CAMERA_ACTION_ANI = "SET_CAMERA_ACTION_ANI";
const SET_GUEST_BOOK = "SET_GUEST_BOOK"
const SET_CALENDER = "SET_CALENDER";
const SET_DISPLAY_CHAT = "SET_DISPLAY_CHAT";
const SET_DISPLAY_EMOTIONS = "SET_DISPLAY_EMOTIONS";
const SET_DISPLAY_SETTINGS = "SET_DISPLAY_SETTINGS";
const SET_PLACE_SUB_NAME = "SET_PLACE_SUB_NAME";
const SET_FISHING_MODE = "SET_FISHING_MODE";
const SET_IS_START_FISHING = "SET_IS_START_FISHING";
const SET_FISHING_SUCCESS = "SET_FISHING_SUCCESS"
const SET_FISH_LISTS = "SET_FISH_LISTS"
const SET_DISPLAY_FRIENDS = "SET_DISPLAY_FRIENDS"
const SET_DISPLAY_MAIL = "SET_DISPLAY_MAIL";
const SET_TOTAL_GAME_MODE = "SET_TOTAL_GAME_MODE";
const SET_CLICK_USER = "SET_CLICK_USER";
const SET_ClICK_INFO = "SET_ClICK_INFO";
const SET_OPEN_WAIT = "SET_OPEN_WAIT";
const SET_OPEN_VIDEO_AREA = "SET_OPEN_VIDEO_AREA";
const SET_CONNETCING_MSG = "SET_CONNECTING_MSG";
const SET_VIDEO_CHAT_CANCEL_BTN = "SET_VIDEO_CHAT_CANCEL_BTN";
const SET_CAMERA_SWITCH = "SET_CAMERA_SWITCH";
const SET_AUDIO_SWITCH = "SET_AUDIO_SWITCH";
const SET_PEERCONNECT_ID = "SET_PEERCONNECT_ID";
const SET_PEER = "SET_PEER";
const SET_PEERNICKNAME = "SET_PEERNICKNAME";
const SET_REQ_CONNECT = "SET_REQ_CONNECT";
const SET_WEBRTC_COMPONENT = "SET_WEBRTC_COMPONENT";


interface setInitState {
    type : BabylonSettingTypes.SET_INIT_BABYLON_SET;
}

interface setNavStateAction {
    type : BabylonSettingTypes.SET_NAV_STATE;
    _state : string
}


interface setAllReadyAction {
    type : BabylonSettingTypes.SET_ALL_READY;
    isReady : boolean;
}

interface setImportTypeAction {
    type : BabylonSettingTypes.SET_IMPORT_TYPE;
    importtype : string
}

interface setVisibleInventoryAction {
    type : BabylonSettingTypes.SET_VISIBLE_INVENTORT;
    visibleInventory : boolean
}

interface setClickMapAction {
    type : BabylonSettingTypes.SET_CLICK_MAP;
    isClick : boolean
}

interface setClickCampusAction {
    type : BabylonSettingTypes.SET_CLICK_CAMPUS;
    isClick : boolean
}

interface setClickClassRoomAction {
    type : BabylonSettingTypes.SET_CLICK_CLASSROOM;
    isClick : boolean
}

interface setDisplayNpcAction {
    type : BabylonSettingTypes.SET_DISPLAY_NPC
    active : boolean | number
}

interface setDisplayMyPageNpcAction {
    type : BabylonSettingTypes.SET_DISPLAY_MYPAGE
    active : boolean 
}

interface setPortalActionNpcAction {
    type : BabylonSettingTypes.SET_PORTAL_ACTION
    active : boolean | number
}

interface setDanceValueAction {
    type : BabylonSettingTypes.SET_DANCE_VALUE
    value : boolean | number
}

interface setCameraActionAniAction {
    type : BabylonSettingTypes.SET_CAMERA_ACTION_ANI
    active : boolean
}

interface setGuestBook {
    type : BabylonSettingTypes.SET_GUEST_BOOK,
    active : boolean
}

interface setCalender {
    type : BabylonSettingTypes.SET_CALENDER,
    active : boolean
}

interface setdisPlayChat {
    type : BabylonSettingTypes.SET_DISPLAY_CHAT,
    active : boolean
}
// SET_DISPLAY_EMOTIONS
interface setDisPlayEmotions {
    type : BabylonSettingTypes.SET_DISPLAY_EMOTIONS,
    active : boolean
}

interface setDisPlaySetting {
    type : BabylonSettingTypes.SET_DISPLAY_SETTINGS,
    active : boolean
}
interface setPlaceSubName {
    type : BabylonSettingTypes.SET_PLACE_SUB_NAME,
    active : string
} 
interface setFishingMode {
    type : BabylonSettingTypes.SET_FISHING_MODE,
    active : boolean
}

interface setIsStartFishing {
    type : BabylonSettingTypes.SET_IS_START_FISHING,
    active : boolean
}

interface setFishingSuccess {
    type : BabylonSettingTypes.SET_FISHING_SUCCESS,
    active : boolean
}


interface setFishLists {
    type : BabylonSettingTypes.SET_FISH_LISTS,
    fishList : Array<any>
}


interface setDisplayFriends {
    type : BabylonSettingTypes.SET_DISPLAY_FRIENDS,
    active : boolean
}

interface setDisplayMail {
    type : BabylonSettingTypes.SET_DISPLAY_MAIL,
    active : boolean
}

interface setDisplayFame {
    type : BabylonSettingTypes.SET_DISPLAY_FAME,
    active : boolean
}
interface setDisplayGameTutorial {
    type : BabylonSettingTypes.SET_DISPLAY_GAME_TUTORIAL,
    active : boolean
}
interface setCountGrowTree {
    type : BabylonSettingTypes.SET_COUNT_GROW_TREE,
    count : number
}

interface setDisplayTree {
    type : BabylonSettingTypes.SET_DISPLAY_TREE,
    active : boolean
}

interface setToStoreSendMail {
    type : BabylonSettingTypes.SET_TO_STORE_SEND_MAIL,
    active : boolean | number
}

interface setDisplayCafeInfo {
    type : BabylonSettingTypes.SET_DISYPLAY_CAFE_INFO,
    active : boolean | number
}

interface setDisplayGallery {
    type : BabylonSettingTypes.SET_DISPLAY_GALLERY,
    active : boolean | string
}

interface setTotalGameMode {
    type : BabylonSettingTypes.SET_TOTAL_GAME_MODE,
    active : boolean | string
}

interface setClickUser {
    type : BabylonSettingTypes.SET_CLICK_USER,
    active : boolean
}

interface setClickInfo {
    type : BabylonSettingTypes.SET_ClICK_INFO,
    active : boolean
}

interface setOpenWait {
    type : BabylonSettingTypes.SET_OPEN_WAIT,
    active: boolean
}

interface setOpenVideoArea {
    type : BabylonSettingTypes.SET_OPEN_VIDEO_AREA,
    active: boolean
}

interface setConnectingMsg {
    type : BabylonSettingTypes.SET_CONNECTING_MSG,
    active: boolean
}

interface setVideoChatCancelBtn {
    type : BabylonSettingTypes.SET_VIDEO_CHAT_CANCEL_BTN,
    active: boolean
}

interface setCameraSwitch {
    type : BabylonSettingTypes.SET_CAMERA_SWITCH,
    active : boolean
}

interface setAudioSwitch {
    type : BabylonSettingTypes.SET_AUDIO_SWITCH,
    active: boolean
}

interface setPeerConnectId {
    type : BabylonSettingTypes.SET_PEERCONNECT_ID,
    active: string
}

interface setPeer {
    type : BabylonSettingTypes.SET_PEER,
    active: any
}

interface setPeerNickName {
    type : BabylonSettingTypes.SET_PEERNICKNAME,
    active: any
}

interface setReqConnect {
    type : BabylonSettingTypes.SET_REQ_CONNECT,
    active: boolean
}

interface setWebRtcComponent {
    type : BabylonSettingTypes.SET_WEBRTC_COMPONENT,
    active: boolean
}

type Actions =
    | setNavStateAction
    | setAllReadyAction
    | setImportTypeAction
    | setVisibleInventoryAction
    | setClickMapAction
    | setClickCampusAction
    | setClickClassRoomAction
    | setDisplayNpcAction
    | setDisplayMyPageNpcAction
    | setPortalActionNpcAction
    | setDanceValueAction
    | setInitState
    | setCameraActionAniAction
    | setGuestBook
    | setCalender
    | setdisPlayChat
    | setDisPlayEmotions
    | setDisPlaySetting
    | setPlaceSubName
    | setFishingMode
    | setIsStartFishing
    | setFishingSuccess
    | setFishLists
    | setDisplayFriends
    | setDisplayMail
    | setDisplayFame
    | setDisplayGameTutorial
    | setCountGrowTree
    | setDisplayTree
    | setToStoreSendMail
    | setDisplayCafeInfo
    | setDisplayGallery
    | setTotalGameMode
    | setClickUser
    | setClickInfo
    | setOpenWait
    | setOpenVideoArea
    | setConnectingMsg
    | setVideoChatCancelBtn
    | setCameraSwitch
    | setAudioSwitch
    | setPeerConnectId
    | setPeer
    | setPeerNickName
    | setReqConnect
    | setWebRtcComponent

export const setInitBabylonSet = () => ({
    type : SET_INIT_BABYLON_SET
})

export const setNavState = (_state : string) => ({
    type : SET_NAV_STATE,
    _state
})

export const setAllReady = (isReady : boolean) => ({
    type : SET_ALL_READY,
    isReady
})

export const setImportType = (importtype : string) => ({
    type : SET_IMPORT_TYPE,
    importtype
})

export const setVisibleInventory = (visibleInventory : boolean) => ({
    type: SET_VISIBLE_INVENTORT,
    visibleInventory
})

export const setClickMap = (isClick: boolean) => ({
    type : SET_CLICK_MAP,
    isClick
})

export const setClickCampus = (isClick : boolean) => ({
    type : SET_CLICK_CAMPUS,
    isClick
})

export const setClickClassRoom = (isClick : boolean) => ({
    type : SET_CLICK_CLASSROOM,
    isClick
})

export const setDisplayNpc = (active : boolean | number) => ({
    type : SET_DISPLAY_NPC,
    active,
})

export const setDisplayMyPage = (active : boolean) => ({
    type : SET_DISPLAY_MYPAGE,
    active
})

export const setPortalAction = (active : boolean | number) => ({
    type : SET_PORTAL_ACTION,
    active
})

export const setDanceValue = (value : boolean | number) => ({
    type : SET_DANCE_VALUE,
    value
})

export const setCameraActionAniAction = (active : boolean) => ({
    type : SET_CAMERA_ACTION_ANI,
    active
})

export const setGuestBookAction = (active : boolean) => ({
    type : SET_GUEST_BOOK,
    active
})

export const setCalender = (active : boolean) => ({
    type : SET_CALENDER,
    active
})

export const setDisPlayChat = (active : boolean) => ({
    type : SET_DISPLAY_CHAT,
    active
})

export const setDisPlayEmotions = (active : boolean) => ({
    type : SET_DISPLAY_EMOTIONS,
    active
})

export const setDisPlaySettings = (active : boolean) => ({
    type : SET_DISPLAY_SETTINGS,
    active
})


export const setPlaceSubName = (active : string) => ({
    type : BabylonSettingTypes.SET_PLACE_SUB_NAME,
    active
})
export const setFishingMode = (active : boolean) => ({
    type : SET_FISHING_MODE,
    active
})


export const setIsStartFishing = (active : boolean) => ({
    type : SET_IS_START_FISHING,
    active
})

export const setFishingSuccess = (active : number |boolean) => ({
    type : SET_FISHING_SUCCESS,
    active
})

export const setFishLists = (fishList : Array<any>) => ({
    type : SET_FISH_LISTS,
    fishList
})

export const setDisplayFriends = (active : boolean) => ({
    type : BabylonSettingTypes.SET_DISPLAY_FRIENDS,
    active
})

export const setDisplayMail = (active : boolean) => ({
    type : BabylonSettingTypes.SET_DISPLAY_MAIL,
    active
})

export const setDisplayFame = (active :boolean) => ({
    type : BabylonSettingTypes.SET_DISPLAY_FAME,
    active
})

export const setDisplayGameTutorial = (active : boolean) => ({
    type : BabylonSettingTypes.SET_DISPLAY_GAME_TUTORIAL,
    active
})

export const setCountGrowTree = (count : number) => ({
    type : BabylonSettingTypes.SET_COUNT_GROW_TREE,
    count
})

export const setDisplayTree = (active : boolean) => ({
    type : BabylonSettingTypes.SET_DISPLAY_TREE,
    active
})

export const setToStoreSendMail = (active : boolean | number) => ({
    type: BabylonSettingTypes.SET_TO_STORE_SEND_MAIL,
    active
})

export const setDisplayCafeInfo = (active : boolean | number) => ({
    type: BabylonSettingTypes.SET_DISYPLAY_CAFE_INFO,
    active
})


export const setDisPlayGallery = (active : boolean | string) => ({
    type : BabylonSettingTypes.SET_DISPLAY_GALLERY,
    active
})

export const setTotalGameMode = (active : boolean | string) => ({
    type : BabylonSettingTypes.SET_TOTAL_GAME_MODE,
    active
})

export const setClickUser = (active: boolean) => ({
    type : SET_CLICK_USER,
    active
})

export const setClickInfo = (active : ClickInfo) => ({
    type : SET_ClICK_INFO,
    active
})

export const setOpenWait = (active : boolean) => ({
    type : SET_OPEN_WAIT,
    active
})

export const setOpenVideoArea = (active : boolean) => ({
    type : SET_OPEN_VIDEO_AREA,
    active
})

export const setConnectingMsg = (active: boolean) => ({
    type : SET_CONNETCING_MSG,
    active
})

export const setVideoChatCancelBtn = (active: boolean) => ({
    type : SET_VIDEO_CHAT_CANCEL_BTN,
    active
})

export const setCameraSwitch = (active: boolean) => ({
    type : SET_CAMERA_SWITCH,
    active
})

export const setAudioSwitch = (active: boolean) => ({
    type : SET_AUDIO_SWITCH,
    active
})

export const setPeerConnectId = (active: string) => ({
    type : SET_PEERCONNECT_ID,
    active
})

export const setPeer = (active: any) => ({
    type : SET_PEER,
    active
})

export const setPeerNickName = (active: any) => ({
    type : SET_PEERNICKNAME,
    active
})

export const setReqConnect = (active: boolean) => ({
    type : SET_REQ_CONNECT,
    active
})

export const setWebRtcComponent = (active: boolean) => ({
    type : SET_WEBRTC_COMPONENT,
    active
})

export default function BabylonSettings(state : SETTINGS = initialState, action : Actions) {
    switch (action.type) {
        case SET_ALL_READY:
            return {
                ...state,
                allReady : action.isReady
            }
        case SET_IMPORT_TYPE:
            return {
                ...state,
                importType : action.importtype
            }
        case SET_NAV_STATE:
            return {
                ...state,
                navState : action._state
            }
        case SET_CLICK_MAP:
            return {
                ...state,
                clickMap : action.isClick
            }
        case SET_CLICK_CAMPUS:
            return {
                ...state,
                ClickCampus : action.isClick,
                disPlayMyPage : false,
                disPlayChat : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                guestbook : false,
                calender : false,
                visibleInventory : false,
                ClickClassRoom : false,
                portalAction : false,
                disPlayEmotions : false,

            }
        case SET_CLICK_CLASSROOM:
            return {
                ...state,
                ClickClassRoom : action.isClick,
                ClickCampus : false,
                disPlayMyPage : false,
                disPlayChat : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                guestbook : false,
                calender : false,
                visibleInventory : false,
                portalAction : false,
                disPlayEmotions : false,

            }
        case SET_DISPLAY_NPC:
            return {
                ...state,
                disPlayNpc : action.active,
            }
        case SET_PORTAL_ACTION:
            return {
                ...state,
                portalAction : action.active,
                ClickCampus : false,
                disPlayMyPage : false,
                disPlayChat : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                guestbook : false,
                calender : false,
                visibleInventory : false,
                ClickClassRoom : false,
                disPlayEmotions : false,
                displayChat : false,
            }
        case SET_DISPLAY_MYPAGE:
            return {
                ...state,
                disPlayMyPage : action.active,
                portalAction : false,
                ClickCampus : false,
                disPlayChat : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                guestbook : false,
                calender : false,
                visibleInventory : false,
                ClickClassRoom : false,
                disPlayEmotions : false,
                displayChat : false,
            }
        case SET_VISIBLE_INVENTORT:
            return {
                ...state,
                visibleInventory : action.visibleInventory,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                disPlayChat : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                guestbook : false,
                calender : false,
                ClickClassRoom : false,
                disPlayEmotions : false,
                displayChat : false,
            }
        case SET_DISPLAY_CHAT : {
            return {
                ...state,
                disPlayChat : action.active,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                guestbook : false,
                calender : false,
                ClickClassRoom : false,
            }
        }
        case SET_DISPLAY_EMOTIONS : {
            return {
                ...state,
                disPlayEmotions : action.active,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                guestbook : false,
                calender : false,
                ClickClassRoom : false,
                displayChat : false,
            }
        }
        case SET_DANCE_VALUE:
            return {
                ...state,
                danceValue : action.value,
            }
        case SET_INIT_BABYLON_SET:
            return {
                ...state,
                ...initialState
            }
        case SET_CAMERA_ACTION_ANI:
            return {
                ...state,
                cameraActionAni : action.active
            }   
        case SET_GUEST_BOOK: {
            return  {
                ...state,
                guestbook : action.active,
                disPlayEmotions : false,
                disPlayChat : false,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                calender : false,
                ClickClassRoom : false,
                displayChat : false,
            }
        }
        case SET_CALENDER: {
            return {
                ...state,
                calender : action.active,
                disPlayEmotions : false,
                guestbook : false,
                disPlayChat : false,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                disPlaySettings : false,
                displayFriends : false,
                displayMail : false,
                ClickClassRoom : false,
            }
        }
        case SET_DISPLAY_SETTINGS: {
            return {
                ...state,
                calender : false,
                disPlayEmotions : false,
                guestbook : false,
                disPlayChat : false,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                displayFriends : false,
                displayMail : false,
                ClickClassRoom : false,
                disPlaySettings : action.active,
                displayChat : false,
            }
        }
    
        case SET_PLACE_SUB_NAME :
            return {
                ...state,
                placeSubName : action.active
            }
        case SET_FISHING_MODE: {
            return {
                ...state,
                fishingMode : action.active,
                calender : false,
                disPlayEmotions : false,
                guestbook : false,
                disPlayChat : false,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                displayFriends : false,
                displayMail : false,
                ClickClassRoom : false,
                disPlaySettings : false
            }
        }
        case SET_IS_START_FISHING: {
            return {
                ...state,
                isStartFishing : action.active
            }
        }

        case SET_FISHING_SUCCESS: {
            return {
                ...state,
                fishingSuccess : action.active
            }
        }

        case SET_FISH_LISTS :
            return {
                ...state,
                fishList : action.fishList
            }
        case SET_DISPLAY_FRIENDS:
            return {
                ...state,
                displayFriends : action.active,
                calender : false,
                disPlayEmotions : false,
                guestbook : false,
                disPlayChat : false,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                displayMail : false,
                ClickClassRoom : false,
                disPlaySettings : false
            }
        case SET_DISPLAY_MAIL:
            return {
                ...state,
                displayMail : action.active,
                displayFriends : false,
                calender : false,
                disPlayEmotions : false,
                guestbook : false,
                disPlayChat : false,
                visibleInventory : false,
                disPlayMyPage : false,
                portalAction : false,
                ClickCampus : false,
                ClickClassRoom : false,
                disPlaySettings : false
            }
        case BabylonSettingTypes.SET_DISPLAY_FAME:
            return {
                ...state,
                displayFame : action.active
            }
        case BabylonSettingTypes.SET_DISPLAY_GAME_TUTORIAL:
            return {
                ...state,
                displayGameTutorial : action.active
            }
        case BabylonSettingTypes.SET_COUNT_GROW_TREE:
            return {
                ...state,
                countGrowTree : action.count,
            }
        case BabylonSettingTypes.SET_DISPLAY_TREE:
            return {
                ...state,
                displayTree : action.active
            }
        case BabylonSettingTypes.SET_TO_STORE_SEND_MAIL:
            return {
                ...state,
                toStoreSendMail : action.active
            }
        case BabylonSettingTypes.SET_DISYPLAY_CAFE_INFO:
            return {
                ...state,
                displayCafeInfo : action.active
            }
        case BabylonSettingTypes.SET_DISPLAY_GALLERY:
            return {
                ...state,
                displayGallery : action.active
            }
        case BabylonSettingTypes.SET_TOTAL_GAME_MODE:
            return {
                ...state,
                toatalGameMode : action.active
            }
        case SET_CLICK_USER:{
            return {
                ...state,
                clickUser : action.active
            }
        }
        case SET_ClICK_INFO: {
            return {
                ...state,
                clickInfo : Object.assign(state.clickInfo, action.active)
            }
        }
        case SET_OPEN_WAIT: {
            return {
                ...state,
                openWait : action.active
            }
        }
        case SET_OPEN_VIDEO_AREA: {
            return {
                ...state,
                openVideoArea : action.active
            }
        }
        case SET_CONNETCING_MSG: {
            return {
                ...state,
                connectingMsg : action.active
            }
        }
        case SET_VIDEO_CHAT_CANCEL_BTN: {
            return {
                ...state,
                videoChatCancelBtn : action.active
            }
        }
        case SET_CAMERA_SWITCH: {
            return {
                ...state,
                cameraSwitch: action.active
            }
        }
        case SET_AUDIO_SWITCH: {
            return {
                ...state,
                audioSwitch: action.active
            }
        }
        case SET_PEERCONNECT_ID: {
            return {
                ...state,
                peerConnectId: action.active
            }
        }
        case SET_PEER: {
            return {
                ...state,
                peerObj: action.active
            }
        }
        case SET_PEERNICKNAME: {
            return {
                ...state,
                peernickname: action.active
            }
        }
        case SET_REQ_CONNECT: {
            return {
                ...state,
                reqConnect: action.active
            }
        }
        case SET_WEBRTC_COMPONENT: {
            return {
                ...state,
                webRtcComponent: action.active
            }
        }
        default:
            return state;
    }
}