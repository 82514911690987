import React, { useEffect } from "react";
import '../css/ActionPupup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setPortalAction  } from "../redux/BabylonSettings";
import { useLocation } from 'react-router-dom';


function ActionPopup() {
    const onEnter = (e) => {
        if (e.keyCode) {
            if (e.keyCode === 13) {
                if (actionType[location][portalAction].link) {
                    SetPortalAction();
                    // 링크가 있는 경우
                    window.open(actionType[location][portalAction].link);
                } else {
                    // 링크가 없는 경우
                    if (actionType[location][portalAction].function === "ready") {
                        SetPortalAction();
                        window.alert("준비중입니다.");
                    }
                }
            }
        } else {
            if (actionType[location][portalAction].link) {
                SetPortalAction();
                // 링크가 있는 경우
                window.open(actionType[location][portalAction].link);
            } else {
                // 링크가 없는 경우
                if (actionType[location][portalAction].function === "ready") {
                    SetPortalAction();
                    window.alert("준비중입니다.")
                }
            }
        }
    }
    const location = useLocation().pathname.split('/')[1];
    const dispatch = useDispatch();
    const SetPortalAction = () => dispatch(setPortalAction(false))
    const { portalAction } = useSelector((state) => ({
        portalAction: state.BabylonSettings.portalAction,
    }), shallowEqual);

    const actionType = {
        "future": [
            {
                //5 행정동
                idx: 1,
                des: <><b>강의실</b>으로 이동하실래요?</>,
                link: 'https://www.webex.com/ko/index.html'
            }                    
        ],
        "present": [
            {
                //5 행정동
                idx: 1,
                des: <><b>강의실</b>으로 이동하실래요?</>,
                link: 'https://www.webex.com/ko/index.html'
            }                    
        ]
    }

    useEffect(() => {
        if (portalAction !== false) {
            window.addEventListener('keyup', onEnter)
            return (() => {
                window.removeEventListener('keyup', onEnter)
            })
        }
    }, [portalAction])

    return (
        <>
            <div className={portalAction === false ? "actionPopupContainer" : "actionPopupContainer open"}>
                <button className="cancelAction" onClick={() => SetPortalAction()}>
                    <FontAwesomeIcon icon={faXmark} className="fa-cancel" />
                </button>
                <div className="actionScript">{portalAction !== false ? actionType[location][portalAction].des : "팝업이벤트"}</div>
                <div className="actionSubDes">
                    엔터키를 누르시면 이동 하실 수 있습니다
                </div>
                <button type="button" className="actionMove" onClick={onEnter}>이동하기</button>
            </div>
        </>
    );
}

export default React.memo(ActionPopup);