import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import { setUserInfo } from '../redux/UserInfo';
import {useNavigate} from 'react-router-dom'
import symbol from '../assets/img/symbol.webp';
import logo from '../assets/img/logo.webp';

import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.09) 135%);
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
`

const Back = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */
    & > video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    @media (max-width : 650px) {
        /* top: 20%; */
        /* transform: translateX(-50%); */
    }
`

const Logo = styled.div`
    width: 60%;
    min-width: 240px;
    max-width: 281.3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4%;
    position: relative;
    z-index: 2;
    & > img {
        width: 100%;
    }
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 639px;
    max-height: 70%;
    overflow-y: auto;
    /* justify-content: center; */
    border-radius: 28px;
    background-color: #fff;
    position: relative;
    z-index: 2;
    padding: 50px 45px 40px;
    @media (max-width : 650px) {
        padding: 10% 5%;
        border-radius: 20px;
    }
`
const Title = styled.h1`
    font-family: var(--fn-inter);
    font-weight: 700;
    font-size: 26px;
    letter-spacing: -1.3px;
    color : #144167;
`

const SubText = styled.p`
    font-family: var(--fn-sans);
    font-size: 18px;
    color : #a5a5a5;
    margin-top: 6px;
    margin-bottom: 20px;
    @media (max-width : 650px) {
        font-size: 16px;
    }
`

const PozContainer = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
        margin-bottom: 28px;
    }
    @media (max-width : 650px) {
        flex-direction: column;
        align-items: unset;
    }
`

interface FiledProps {
    width ?: string
}

const FiledContainer = styled.div<FiledProps>`
    display: flex;
    flex-direction: column;
    width: ${props=> props.width ? 'auto' : '100%'};
    &:last-of-type {
        margin-left: auto;
    }
    & > span {
        font-family: var(--fn-sans);
        font-weight: 500;
        font-size: 18px;
        color : #536777;
        margin-bottom: 11px;
    }
    & > span.essential::after {
        content: "*";
    }
    & > input {
        width: ${props=> props.width ? `${props.width}` : '100%'};
        height: 51px;
        background-color: unset;
        border-radius: 7px;
        border: 1px solid #b9b9b9;
        padding: 0 22px 0 10px;
        font-family: var(--fn-sans);
        font-weight: 400;
        font-size: 18px;
    }
    & > input:focus {
        outline: none;
        background-color: #f7f7f7;
        border: unset;
    }
    @media (max-width : 650px) {
        &:last-of-type {
            margin-left: 0;
            margin-top: 22px;
        }
    }
`


const SubmitButton = styled.button`
    width: 100%;
    min-height: 57px;
    background-color: #8367f9;
    color: #fff;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fn-sans);
    font-weight: 700;
    font-size: 19px;
    margin-top: 37px;
    cursor: pointer;
    &:hover {
        background-color: #6f53e1;
    }
`

function SignUp() {
    interface ID {
        id : string,
        token : string,
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id, token }: ID = useSelector((state : any) => (
    {
        id : state.UserInfo.id,
        token : state.UserInfo.token,
    }))
    // const MyInfo = useSelector((state : any)=> (state.UserInfo))

    const [input, setInput] = useState({
        tel : '',
        email : '',
        nickname : '',
        name : '',
    })
    const {tel, email, nickname, name} = input;
    const onChange = (e:any) => {
        let {name, value} = e.target;
        if (name === 'tel') {
            value = value.replace(/[^0-9]/g,'')
        }
        setInput({
            ...input,
            [name] : value
        })
    }

    const SetUserInfo = (userinfo: any) => dispatch(setUserInfo(userinfo))

    const onSubmit = (e:any) => {
        if (name ==='') {
            return alert('이름을 입력해주세요!');
        } else if (tel === '') {
            return alert('전화번호를 입력해주세요!');
        } else if(email === '') {
            return alert('이메일을 입력해주세요!');
        } else if(nickname === '') {
            return alert('닉네임을 입력해주세요!');
        }
    axios.post(`${process.env.REACT_APP_API_URL}/user_add`, {
            kakao_id : id,
            token : token,
            nick_name : nickname,
            mobile : tel,
            email : email,
        })
        .then((res:any)=> {
            // 회원가입 성공했을 때
            if (res.data.result === 200) {
                SetUserInfo({
                    id : id,
                    nickname : nickname,
                    token : token,
                    items : {
                        eyebrow : 'eyebrow_0',
                        hair : 'hair_0',
                        cloth : 'cloth_0',
                        shoes : 'shoes_0',
                    },
                    position: {
                        x: "-24.579",
                        y: "-0.564",
                        z: "-1.893"
                    },
                    rotation: {
                        x: "0",
                        y: '84',
                        z: "0"
                    },
                    campus : 'future',
                    role : 1
                })
                navigate('/future');
            }
            
        })
    }

    return(
        <Container >
            <Back>
                <video src="/assets/video/intro.mp4" autoPlay playsInline muted></video>
            </Back>

            <FormContainer>
                <Title>추가 정보 입력</Title>
                <SubText>다양한 이벤트 참여를 위해 추가 정보를 입력해 주세요</SubText>
                <PozContainer>
                    <FiledContainer width='256px'>
                        <span className='essential'>이름</span>
                        <input type="text" name="name" id="name" placeholder="이름을 입력해주세요." value={name} onChange={onChange} />
                    </FiledContainer>
                    <FiledContainer width='256px'>
                        <span className='essential'>닉네임</span>
                        <input type="text" name="nickname" id="nickname" placeholder="닉네임을 입력해주세요." value={nickname} onChange={onChange} />
                    </FiledContainer>
                </PozContainer>
                <PozContainer>
                    <FiledContainer>
                        <span className='essential'>전화번호</span>
                        <input type="text" name="tel" id="tel" placeholder="전화번호를 입력해주세요." value={tel} onChange={onChange} />
                    </FiledContainer>
                </PozContainer>
                <PozContainer>
                    <FiledContainer>
                        <span className='essential'>이메일 주소</span>
                        <input type="text" name="email" id="email" placeholder="이메일을 입력해주세요." value={email} onChange={onChange} />
                    </FiledContainer>
                </PozContainer>
                {/* <PozContainer>
                    <FiledContainer width='256px'>
                        <span>소속</span>
                        <input type="text" name="belong" id="belong" placeholder="소속을 입력해주세요." value={belong} onChange={onChange} />
                    </FiledContainer>
                    <FiledContainer width='187px'>
                        <span>직급</span>
                        <input type="text" name="rank" id="rank" placeholder="직급을 입력해주세요." value={rank} onChange={onChange} />
                    </FiledContainer>
                </PozContainer> */}
                <SubmitButton onClick={onSubmit}>메타버스 시작하기</SubmitButton>
                {/* <Title>소방학교 교육 메타버스에 오신<br/>
                    여러분을 환영합니다!</Title>
                <SubText>회원가입 후<br/>
                    "소방학교 교육 메타버스"를 자유롭게 이용하실 수 있습니다</SubText>
                <InputContainer>
                    <Input type="text" name="name" id="name" placeholder="이름을 입력해주세요." value={name} onChange={onChange} />
                </InputContainer>
                <InputContainer>
                    <Input type="text" name="rank" id="rank" placeholder="직급을 입력해주세요." value={rank} onChange={onChange} />
                </InputContainer>
                <InputContainer>
                    <Input type="text" name="tel" id="tel" placeholder="전화번호를 입력해주세요." value={tel} onChange={onChange} />
                </InputContainer>
                <InputContainer>
                    <Input type="text" name="email" id="email" placeholder="이메일을 입력해주세요." value={email} onChange={onChange} />
                </InputContainer>
                <InputContainer>
                    <Input type="text" name="nickname" id="nickname" placeholder="닉네임을 입력해주세요." value={nickname} onChange={onChange} />
                </InputContainer>
                <SubmitButton onClick={onSubmit}>입장하기</SubmitButton> */}
            </FormContainer>
        </Container>
    );
}

export default SignUp;