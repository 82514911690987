import Recast from 'recast-detour'
import {RecastJSPlugin} from '@babylonjs/core';
import {getNavMesh} from './BabylonModule'
let navigationPlugin, recast = null;

const navMeshParameters = {
    cs: 0.1,
    ch: 0.1,
    walkableSlopeAngle: 35,
    walkableHeight: 5,
    walkableClimb: 10,
    walkableRadius: 1,
    maxEdgeLen: 12.,
    maxSimplificationError: 1.3,
    minRegionArea: 8,
    mergeRegionArea: 20,
    maxVertsPerPoly: 6,
    detailSampleDist: 6,
    detailSampleMaxError: 1,
    tileSize: null,
    borderSize: null
};

export const createNav = async () => {
    try {
        if (!recast) {
            recast = await Recast();
            navigationPlugin = new RecastJSPlugin(recast);
        } else {
            navigationPlugin = new RecastJSPlugin(recast);
        }
        return true;
    } catch (error) {
        return error
    }
};

export const getNavPlugin = () => {
    return navigationPlugin;
};


export const createAutoNavMesh = () => {
    navigationPlugin.createNavMesh([getNavMesh()], navMeshParameters);
    return navigationPlugin;
};

export const clearNavPlugin = () => {
    navigationPlugin.dispose();
    navigationPlugin = null;
};