import React, { useEffect } from "react";
import { Vector3, Tools, Color3 } from '@babylonjs/core'
// redux
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setImportType, setClickUser, setClickInfo } from "../redux/BabylonSettings";
// fc
import { socket } from '../middleware/Socket'
import * as BBL from './BabylonModule'
import {useMediaQuery} from 'react-responsive';

function OtherUser({ userData }) {
    let { id, nickname, position, rotation, items } = userData;
    //redux
    const importType = useSelector(state => state.BabylonSettings.importType, shallowEqual);
    const dispatch = useDispatch();
    const SetImportType = (importType) => dispatch(setImportType(importType));

    const isDesktop = useMediaQuery({
        query: "(min-width:768px) and (max-width:1024px)",
      });
    const isMobile = useMediaQuery({
        query: "(max-width:767px)",
    });

    const isClickUser = useSelector(state => state.BabylonSettings.clickUser, shallowEqual);
    const SetClickUser = (isClickUserA) => dispatch(setClickUser(isClickUserA));
    const SetClickInfo = (data) => dispatch(setClickInfo(data));

    useEffect(() => {
        let trueValue, playerAni = null;
        let checkDistance, checkPointVector3 = 0;
        let deleteArr = [];
        let userAnis = [];
        let bubbleStateInterval, bubbleUpperState, bubbleLowerState = null;
        let bubbleStateOn = false;

        const scene = BBL.getScene();

        let eyebrowHexColors = [
            "#000000",
            "#6A392A",
            "#FCEF00",
            "#FC9200",
            "#FF9F9F",
            "#5885E8",
            "#D198E8"
        ];

        let agentParams = {
            radius: 0.1,
            height: 2,
            maxAcceleration: 130.0,
            maxSpeed: 6.0,
            collisionQueryRange: 0.1,
            pathOptimizationRange: 0.0,
            separationWeight: 10.0
        };

        const userOption = BBL.createSetUser(
            deleteArr, id, nickname, position, rotation, items, userAnis, agentParams, isClickUser, SetClickUser, SetClickInfo, isDesktop
        );
        playerAni = userAnis[0];
        playerAni.start(true, 1.0, playerAni.from, playerAni.to, true);

        let user = userOption.user;
        let particleSystem = userOption.particleSystem;
        const shadowGenerator = userOption.shadowGenerator;
        let playerHair = userOption.playerHair;
        let playerCloth = userOption.playerCloth;
        let playerShoes = userOption.playerShoes;
        let playerEyeBrowMat = userOption.userEyeBrowMat;
        let userSkeleton = userOption.userSkeleton;
        let crowd = userOption.crowd;
        let agents = userOption.agents;
        let userBubbleState = userOption.userBubbleState;
        let bubbleStateUrl = userOption.bubbleStateUrl;
        let nickNameText = userOption.nickNameText;


        checkPointVector3 = user.position;

        let renderFunction = function () {
            if (trueValue) {
                user.position = crowd.getAgentPosition(agents[0].idx);
                checkDistance = Vector3.Distance(user.position, checkPointVector3);
                let vel = crowd.getAgentVelocity(agents[0].idx);
                if (vel.length() > 0.1) {
                    vel.normalize();
                    user.rotation.y = Math.atan2(vel.x, vel.z);
                    if (checkDistance > 3) {
                        if (playerAni !== userAnis[2]) {
                            playerAni.stop();
                            playerAni = userAnis[2];
                            playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                            agentParams.maxSpeed = 6;
                            crowd.updateAgentParameters(agents[0], agentParams);
                        }
                    } else if (checkDistance > 1) {
                        if (playerAni !== userAnis[1]) {
                            playerAni.stop();
                            playerAni = userAnis[1];
                            playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                            agentParams.maxSpeed = 3;
                            crowd.updateAgentParameters(agents[0], agentParams);
                        }
                    }
                } else {
                    if (playerAni !== userAnis[0]) {
                        playerAni.stop();
                        BBL.multiParticlesState(false, particleSystem)
                        playerAni = userAnis[0];
                        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                        trueValue = false;
                        crowd.agentTeleport(agents[0], user.position);
                    }
                }

            }
        }
        scene.onBeforeRenderObservable.add(renderFunction);

        // socket
        if (importType === 'setup') {
            socket.emit('SETUP_ALL')
            SetImportType('add_user');
        }

        socket.on('_MOVE_POZ', ({ id, x, y, z }) => {
            if (id === userData.id) {
                const tp = new Vector3(x, y, z);
                trueValue = true;
                checkDistance = Vector3.Distance(user.position, tp);
                checkPointVector3 = tp;
                if (checkDistance > 3) {
                    if (playerAni.isPlaying) {
                        if (playerAni !== userAnis[2]) {
                            playerAni.stop();
                            playerAni = userAnis[2];
                            playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                            agentParams.maxSpeed = 6;
                            crowd.updateAgentParameters(agents[0], agentParams);
                        }
                    } else {
                        playerAni = userAnis[2];
                        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                        agentParams.maxSpeed = 6;
                        crowd.updateAgentParameters(agents[0], agentParams);
                    }

                } else if (checkDistance < 3) {
                    if (playerAni.isPlaying) {
                        if (playerAni !== userAnis[1]) {
                            playerAni.stop();
                            playerAni = userAnis[1];
                            playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                            agentParams.maxSpeed = 3;
                            crowd.updateAgentParameters(agents[0], agentParams);
                        }
                    } else {//bug control ex lack
                        playerAni = userAnis[1];
                        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                        agentParams.maxSpeed = 3;
                        crowd.updateAgentParameters(agents[0], agentParams);
                    }
                }
                crowd.agentGoto(agents[0], tp);
                BBL.multiParticlesState(true, particleSystem);
            }
        })

        socket.on('_CHANGE_CUSTOM', ({ id, items }) => {

            if (id === userData.id) {
                let hairValue = items.hair.split('_')[1];
                let clothValue = items.cloth.split('_')[1];
                let shoesValue = items.shoes.split('_')[1];
                let eyebrowValue = items.eyebrow.split('_')[1];

                if (shadowGenerator) shadowGenerator.removeShadowCaster(playerHair, true)
                playerHair.dispose()
                playerHair = userOption.hairArr.getChildMeshes()[hairValue].clone(id + "hairClone", null, false)
                playerHair.alwaysSelectAsActiveMesh = true;
                playerHair.skeleton = userSkeleton;

                playerHair.parent = user.getChildTransformNodes()[0];
                if (shadowGenerator) shadowGenerator.getShadowMap().renderList.push(playerHair);

                if (shadowGenerator) shadowGenerator.removeShadowCaster(playerCloth, true)
                playerCloth.dispose()
                playerCloth = userOption.clothArr.getChildMeshes()[clothValue].clone(id + "clothClone", null, false)
                playerCloth.alwaysSelectAsActiveMesh = true;
                playerCloth.skeleton = userSkeleton;
                playerCloth.isVisible = true;
                playerCloth.parent = user.getChildTransformNodes()[0];
                if (shadowGenerator) shadowGenerator.getShadowMap().renderList.push(playerCloth);

                if (shadowGenerator) shadowGenerator.removeShadowCaster(playerShoes, true)
                playerShoes.dispose()
                playerShoes = userOption.shoesArr.getChildMeshes()[shoesValue].clone(id + "shoesClone", null, false)
                playerShoes.alwaysSelectAsActiveMesh = true;
                playerShoes.skeleton = userSkeleton;

                playerShoes.parent = user.getChildTransformNodes()[0];
                if (shadowGenerator) shadowGenerator.getShadowMap().renderList.push(playerShoes);


                if (Number(clothValue) === 17) {
                    playerHair.isVisible = false;
                    playerShoes.isVisible = false;
                    user.getChildTransformNodes()[0].getChildMeshes()[0].isVisible = false;
                    user.getChildTransformNodes()[0].getChildMeshes()[1].isVisible = false;
                    user.getChildTransformNodes()[0].getChildMeshes()[2].isVisible = false;                  
                } else {
                    user.getChildTransformNodes()[0].getChildMeshes()[0].isVisible = true;
                    user.getChildTransformNodes()[0].getChildMeshes()[1].isVisible = true;
                    user.getChildTransformNodes()[0].getChildMeshes()[2].isVisible = true;
                    playerHair.isVisible = true;
                    playerShoes.isVisible = true;
                }
                playerEyeBrowMat.albedoColor = Color3.FromHexString(eyebrowHexColors[eyebrowValue]).toLinearSpace();
            };
        });


        socket.on('_TELEPORT_LOCAL', ({ id, x, y, z, rotY }) => {
            if (id === userData.id) {
                if (playerAni !== userAnis[0]) {
                    playerAni.stop();
                    BBL.multiParticlesState(false, particleSystem)
                    playerAni = userAnis[0];
                    playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                    trueValue = false;
                }
                trueValue = false;
                crowd.agentTeleport(agents[0], new Vector3(x, y, z));
                user.position = crowd.getAgentPosition(agents[0].idx);
                user.rotation.y = Tools.ToRadians(rotY);
            }
        })

        socket.on('_DANCE_ANI', ({ id, aniCount, rotationY }) => {
            if (id === userData.id) {
                trueValue = false;
                crowd.agentTeleport(agents[0], user.position);
                user.position = crowd.getAgentPosition(agents[0].idx);
                user.rotation.y = Number(rotationY);
                let userAni = null;
                switch (aniCount) {
                    case 0: userAni = userAnis[3];
                        break;
                    case 1: userAni = userAnis[4];
                        break;
                    case 2: userAni = userAnis[5];
                        break;
                    case 3: userAni = userAnis[6];
                        break;
                    case 4: userAni = userAnis[7];
                        break;
                    case 5: userAni = userAnis[8];
                        break;
                    case 6: userAni = userAnis[9];
                        break;
                    case 7: userAni = userAnis[10];
                        break;
                    case 8: userAni = userAnis[11];
                        break;
                    case 9: userAni = userAnis[12];
                        break;
                    default: userAni = userAnis[0];
                        break;
                }
                if (playerAni !== userAni) {
                    playerAni.stop();
                    playerAni = userAni;
                    playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                }
            }
        });
        if (userOption.faceEmotion[0]) {
            let userFaceMat = user.getChildTransformNodes()[0].getChildMeshes()[2].material.clone("userFaceMat");
            userFaceMat.albedoTexture = userOption.faceEmotion[0].texture;
            user.getChildTransformNodes()[0].getChildMeshes()[2].material = userFaceMat;
            deleteArr.push(userFaceMat);

            socket.on("_CHAT_EMOTION", ({ id, word }) => {
                if (id === userData.id) {
                    if (userFaceMat.albedoTexture === userOption.faceEmotion[0].texture) {
                        let transName = word;
                        if (word.includes("??")) transName = "놀람";
                        else if (word.includes("!?")) transName = "화남2";
                        userOption.faceEmotion.forEach((e) => {
                            if (transName.indexOf(e.faceName) != -1) {
                                userFaceMat.albedoTexture = e.texture;
                                return;
                            }
                        });
                        setTimeout(() => {
                            userFaceMat.albedoTexture = userOption.faceEmotion[0].texture;
                        }, 3000);
                    };
                };
            });
        }

        socket.on('_BUBBLE_STATE_START', ({ id, state }) => {
            if (id === userData.id) {
                if (bubbleStateInterval !== null) {
                    clearInterval(bubbleStateInterval);
                    bubbleStateInterval = null;
                }
                switch (state) {
                    case "chat":
                        if (userBubbleState.source !== bubbleStateUrl[1]) userBubbleState.source = bubbleStateUrl[1];
                        bubbleStateInterval = setInterval(() => {
                            if (bubbleStateOn) userBubbleState.cellId < 28 ? userBubbleState.cellId++ : userBubbleState.cellId = 1;
                        }, 33);
                        break;
                    case "closet":
                        if (userBubbleState.source !== bubbleStateUrl[0]) userBubbleState.source = bubbleStateUrl[0];
                        bubbleStateInterval = setInterval(() => {
                            if (bubbleStateOn) userBubbleState.cellId < 90 ? userBubbleState.cellId++ : userBubbleState.cellId = 1;
                        }, 33);
                        break;
                }
                if (bubbleLowerState !== null) {
                    clearInterval(bubbleLowerState);
                    bubbleLowerState = null;
                }
                userBubbleState.isVisible = true;
                bubbleUpperState = setInterval(() => {
                    if (userBubbleState.alpha < 1) {
                        userBubbleState.alpha += 0.05;
                    }
                    else {
                        clearInterval(bubbleUpperState);
                        bubbleUpperState = null;
                    }
                }, 50);

                userBubbleState.cellId = 1;
                bubbleStateOn = true;
            }
        });

        socket.on('_BUBBLE_STATE_END', ({ id }) => {
            if (id === userData.id) {
                if(bubbleUpperState !== null){
                    clearInterval(bubbleUpperState);
                    bubbleUpperState = null;
                }
                bubbleLowerState = setInterval(() => {
                    if (userBubbleState.alpha > 0.06) {
                        userBubbleState.alpha -= 0.05;
                    }
                    else {
                        userBubbleState.isVisible = false;
                        userBubbleState.alpha = 0;
                        clearInterval(bubbleLowerState);
                        bubbleStateOn = false;
                        if (bubbleStateInterval !== null) {
                            clearInterval(bubbleStateInterval);
                            bubbleStateInterval = null;
                        }
                    }
                }, 50);
            }
        });

        socket.on('_CHANGE_MY_NAME', ({ id, nickname }) => {
            if (id === userData.id) {
                nickNameText.text = nickname;
            }
        });


        return () => {
            scene.onBeforeRenderObservable.remove(renderFunction);
            deleteArr.forEach(object => {
                object.dispose();
            })
        }
    }, [])

    return (
        <>
        </>
    )
}

export default React.memo(OtherUser)