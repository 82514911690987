import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react';
import "../css/usertoolBox.css"
import "../css/videoChat.css"
import {socket} from '../middleware/Socket'
import type {StoreState} from '../redux'
import { setOpenWait, setWebRtcComponent } from '../redux/BabylonSettings';
import React from 'react';

function RtcCallReady() {
    const {clickInfo} = useSelector((state : StoreState) => ({
        clickInfo : state.BabylonSettings.clickInfo
    }));
    const dispatch = useDispatch();
    const SetOpenWait = (openWaitStatus: boolean) => dispatch(setOpenWait(openWaitStatus));
    


    const onCancel = () => {
        SetOpenWait(false);
        socket.emit('rejectConnect', clickInfo.socketid)
    }

    return(
        <>
            <div className="callReadyBox">
                <div className='callReadyContainer'>
                    <div className='callReadyMsg'>수락 대기중 ...</div>
                </div>  
                <div className='callReadyCancelBtn' onClick={onCancel}>취소하기</div>
            </div>
        </>
    );
}

export default RtcCallReady;