import "../css/inventory.css";
import { useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setCurrentItems } from '../redux/UserInfo';
import { setVisibleInventory } from '../redux/BabylonSettings';
import Button from "../Atomic/Button";
import { socket } from '../middleware/Socket';
import styled from 'styled-components'

import axios from "axios";
interface AllItems {
    [key: string]: any
}
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
`
const EyeBrowContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 8px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    & > label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 90%;
        height: 84px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: center;
        cursor: pointer;
        position: relative;
    	background-color: rgba(255, 255, 255, 0.5);
    }
    & > label input {
        display: none;
    }
    & > label > img {
        height: 100%;
    }
    & .itemImg {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        border-radius: 10px;
    }
    & > label input:checked ~ .itemImg {
        background-color: rgba(204, 255, 208, 0.3);
        border: solid 4px #00d10e;
    }
    &::-webkit-scrollbar {
        width: 10px;
        margin-right: 10px;
    }
    &::-webkit-scrollbar-thumb {
        width: 3px;
        background-color: #d4d4d4;
        border-radius: 12px;
    }
    & > label:not(:last-of-type) {
        margin-bottom: 10px;
    }
    & > label:last-of-type {
        margin-bottom: 25px;
    }
`



function Inventory() {
    const [itemType, setItemType] = useState('eyebrow');
    const dispatch = useDispatch();
    const SetCurrentItems = (items: object) => dispatch(setCurrentItems(items));
    const SetVisibleInventory = (visibleInventory: boolean) => dispatch(setVisibleInventory(visibleInventory));

    const { currentItems, id, role } = useSelector((state: any) => ({
        currentItems: state.UserInfo.currentItems,
        id: state.UserInfo.id,
        role: state.UserInfo.role
    }), shallowEqual);

    const visibleIventory: boolean = useSelector((state: any) => (state.BabylonSettings.visibleInventory));
    const items: AllItems = {
        eyebrow: [
            {
                name: '눈썹01',
                value: '0'
            },
            {
                name: '눈썹02',
                value: '1'
            },
            {
                name: '눈썹03',
                value: '2'
            },
            {
                name: '눈썹04',
                value: '3'
            },
            {
                name: '눈썹05',
                value: '4'
            },
            {
                name: '눈썹06',
                value: '5'
            },
            {
                name: '눈썹07',
                value: '6'
            }
        ],
        hair: [
            {
                name: "헤어01",
                value: "0"
            },
            {
                name: "헤어02",
                value: "1"
            },
            {
                name: "헤어03",
                value: "2"
            },
            {
                name: "헤어04",
                value: "3"
            },
            {
                name: "헤어05",
                value: "4"
            },
            {
                name: "헤어06",
                value: "5"
            },
            {
                name: "헤어07",
                value: "6"
            },
            {
                name: "헤어08",
                value: "7"
            },
            {
                name: "헤어09",
                value: "8"
            },
            {
                name: "헤어10",
                value: "9"
            },
            {
                name: "헤어11",
                value: "10"
            },
            {
                name: "헤어12",
                value: "11"
            },
            {
                name: "헤어13",
                value: "12"
            },
            {
                name: "헤어14",
                value: "13"
            },
            {
                name: "헤어15",
                value: "14"
            },
            {
                name: "헤어16",
                value: "15"
            },
            {
                name: "헤어17",
                value: "16"
            },
            {
                name: "헤어18",
                value: "17"
            },
            {
                name: "헤어19",
                value: "18"
            },
            {
                name: "헤어20",
                value: "19"
            },
            {
                name: "헤어21",
                value: "20"
            },
            {
                name: "헤어22",
                value: "21"
            },
            {
                name: "헤어23",
                value: "22"
            },
            {
                name: "헤어24",
                value: "23"
            },
            {
                name: "헤어25",
                value: "24"
            }
        ],
        cloth: [
            {
                name: "옷01",
                value: "0"
            },
            {
                name: "옷02",
                value: "1"
            },
            {
                name: "옷03",
                value: "2"
            },
            {
                name: "옷04",
                value: "3"
            },
            {
                name: "옷05",
                value: "4"
            },
            {
                name: "옷06",
                value: "5"
            },
            {
                name: "옷07",
                value: "6"
            },
            {
                name: "옷08",
                value: "7"
            },
            {
                name: "옷09",
                value: "8"
            },
            {
                name: "옷10",
                value: "9"
            },
            {
                name: "옷11",
                value: "10"
            },
            {
                name: "옷12",
                value: "11"
            },
            {
                name: "옷13",
                value: "12"
            },
            {
                name: "옷14",
                value: "13"
            },
            {
                name: "옷15",
                value: "14"
            },
            {
                name: "옷16",
                value: "15"
            },
            {
                name: "옷17",
                value: "16"
            }
        ],
        shoes: [
            {
                name: "신발01",
                value: "0"
            },
            {
                name: "신발02",
                value: "1"
            },
            {
                name: "신발03",
                value: "2"
            },
            {
                name: "신발04",
                value: "3"
            },
            {
                name: "신발05",
                value: "4"
            },
            {
                name: "신발06",
                value: "5"
            },
            {
                name: "신발07",
                value: "6"
            },
            {
                name: "신발08",
                value: "7"
            },
            {
                name: "신발09",
                value: "8"
            },
            {
                name: "신발10",
                value: "9"
            },
            {
                name: "신발11",
                value: "10"
            },
            {
                name: "신발12",
                value: "11"
            },
            {
                name: "신발13",
                value: "12"
            },
            {
                name: "신발14",
                value: "13"
            },
            {
                name: "신발15",
                value: "14"
            },
            {
                name: "신발16",
                value: "15"
            },
            {
                name: "신발17",
                value: "16"
            },
            {
                name: "신발18",
                value: "17"
            },
            {
                name: "신발19",
                value: "18"
            },
            {
                name: "신발20",
                value: "19"
            },
        ],
        allinOne: [
            {
                name: "옷18",
                value: "17"
            },
        ]
    }

    const onChangeItem = (e: any) => {
        e.stopPropagation()
        if (itemType !== "allinOne") {
            const changeItems = {
                ...currentItems,
                [itemType]: `${itemType}_${e.target.value}`
            }
            SetCurrentItems(changeItems)
        } else {
            const changeItems = {
                ...currentItems,
                cloth: `cloth_${e.target.value}`
            }
            SetCurrentItems(changeItems)
        }
    }

    const onChangeType = (e: any) => {
        setItemType(e.target.value);
    }


    const divItems = items[itemType].map((obj: any, index: any) => {
        return (
            <div className="itemWrap" key={index}>
                <div className="itemBox">
                    <label htmlFor={`item_${index}`} className="item" style={{ display: 'flex', backgroundImage: `url('./assets/img/items/${itemType}/${obj.name}.png')` }}>
                        <input type="radio" name="item" id={'item_' + index} value={obj.value} checked={itemType !== "allinOne" ? `${itemType}_${obj.value}` === currentItems[itemType] : `${itemType}_${obj.value}` === currentItems.cloth} onChange={onChangeItem} />
                        <div className="itemImg" ></div>
                    </label>
                </div>
            </div>
        )
    })
    const onClickWrapper = (e: any) => {
        SetVisibleInventory(false);
        socket.emit('CHANGE_CUSTOM', currentItems);
        if (role === 1) {
            axios.post(`${process.env.REACT_APP_API_URL}/save_avata`, {
                id: id,
                hair: currentItems.hair,
                cloth: currentItems.cloth,
                shoes: currentItems.shoes,
                eyebrow: currentItems.eyebrow
            });
        }
    }
    return (
        <>
            {
                visibleIventory
                &&
                <>
                    <Wrapper onClick={onClickWrapper}>
                    </Wrapper>
                    <div className='inventoryContainer'>
                        <Button onClick={() => {
                            SetVisibleInventory(false);
                            socket.emit('CHANGE_CUSTOM', currentItems);
                        }} position="absolute" style={{ top: 0, left: '50%', transform: 'translate(-50%, -120%)' }} />
                        <div className='changeCategoryContainer'>
                            <label htmlFor="eyebrow">
                                <input type="radio" name="inventoryCategory" id="eyebrow" value="eyebrow" checked={itemType === 'eyebrow'} onChange={onChangeType} />
                                <div className="labelImg eyebrow">
                                    <h2>눈썹</h2>
                                </div>
                            </label>
                            <label htmlFor="hair">
                                <input type="radio" name="inventoryCategory" id="hair" value="hair" checked={itemType === 'hair'} onChange={onChangeType} />
                                <div className="labelImg hair">
                                    <h2>헤어</h2>
                                </div>
                            </label>
                            <label htmlFor="cloth">
                                <input type="radio" name="inventoryCategory" id="cloth" value="cloth" checked={itemType === 'cloth'} onChange={onChangeType} />
                                <div className="labelImg cloth">
                                    <h2>의상</h2>
                                </div>
                            </label>
                            <label htmlFor="shoes">
                                <input type="radio" name="inventoryCategory" id="shoes" value="shoes" checked={itemType === 'shoes'} onChange={onChangeType} />
                                <div className="labelImg shoes">
                                    <h2>신발</h2>
                                </div>
                            </label>
                            <label htmlFor="allinOne">
                                <input type="radio" name="inventoryCategory" id="allinOne" value="allinOne" checked={itemType === 'allinOne'} onChange={onChangeType} />
                                <div className="labelImg allinOne">
                                    <h2>올인원</h2>
                                </div>
                            </label>

                        </div>
                        {itemType !== "eyebrow" && <div className='itemsContainer' >
                            {divItems}
                        </div>}
                        {
                            itemType === "eyebrow" && <EyeBrowContainer>
                                {items[itemType].map((obj: any, index: any) => {
                                    return (
                                        <label key={index} htmlFor={`item_${index}`}>
                                            <img src={`./assets/img/items/${itemType}/${itemType}-0${obj.value}.webp`} alt="" />
                                            <input type="radio" name="item" id={'item_' + index} value={obj.value} checked={`${itemType}_${obj.value}` === currentItems[itemType]} onChange={onChangeItem} />
                                            <div className="itemImg" ></div>
                                        </label>
                                    )
                                })}
                            </EyeBrowContainer>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Inventory;