import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import OtherUsers from "../OtherUsers";
import * as BBL from '../BabylonModule'
import * as BS from "../../redux/BabylonSettings";
import { SceneLoader, Vector3, Tools } from '@babylonjs/core'
import { Socket_connection } from '../../middleware/Socket';

function PresentDefaultCharacter() {
    const dispatch = useDispatch()
    const SetDisPlayNpc = (active) => dispatch(BS.setDisplayNpc(active))
    const SetClickMap = (isClick) => dispatch(BS.setClickMap(isClick));
    const SetCameraActionAniAction = (active) => dispatch(BS.setCameraActionAniAction(active));
    const SetAllReady = (isReady) => dispatch(BS.setAllReady(isReady));
    const SetDanceValue = (value) => dispatch(BS.setDanceValue(value));
    const SetClickUser = (isClickUserA) => dispatch(BS.setClickUser(isClickUserA));

    let userPos = useSelector((state) => (state.UserInfo.position));
    let userRot = useSelector((state) => (state.UserInfo.rotation));

    const scene = BBL.getScene();
    const [custom, setCustom] = useState(false);
    const [isJustFinishRender, setIsJustFinishRender] = useState(false);
    const [thisItem, setThisItem] = useState({
        hair: null,
        cloth: null,
        shoes: null
    });

    // redux state & dispatch
    let { clickMap, danceValue, disPlayNpc, currentItems, nickname, reduxId, allReady, visibleInventory } = useSelector((state) => ({
        clickMap: state.BabylonSettings.clickMap,
        danceValue: state.BabylonSettings.danceValue,
        disPlayNpc: state.BabylonSettings.disPlayNpc,
        calender: state.BabylonSettings.calender,
        currentItems: state.UserInfo.currentItems,
        nickname: state.UserInfo.nickname,
        reduxId: state.UserInfo.id,
        allReady: state.BabylonSettings.allReady,
        visibleInventory: state.BabylonSettings.visibleInventory
    }));

    useEffect(() => {
        let nameArr = [
            "mergeFloors",
            "mergeProps"
        ];
        BBL.setParticles();
        BBL.playerNavMoveSetup(nameArr, reduxId, SetClickUser);
        SceneLoader.LoadAssetContainer('./assets/model/', 'Avatar_Item.glb', scene, (container) => {       
      
            BBL.setAvatarItems(container, 90, 18, 43);
            BBL.avatarMatSet(container.materials[0]);
            BBL.playerEmotionSetup();
            container.addAllToScene();
            setCustom(!custom);
        });

        //minimap setup
        let mPosArr = [
            new Vector3 (19.344, -0.364, -0.444), //강
            new Vector3 (-43.151, -0.731, -18.374), //댐
            new Vector3 (-1.532, -0.731, -57.272), //바다
            new Vector3 (-24.972, -0.731, -20.091), //도심
            new Vector3 (-22.917, -0.608, 24.128), //공사장
            new Vector3 (24.371, -0.742, 21.877), //석유열차
            new Vector3 (19.297, -0.563, 23.984), //화학공장
            new Vector3 (12.726, -0.563, -27.803) //고층빌딩
        ];
        let mRotArr = [
            270,
            327,
            180,
            179,
            0,
            131,
            0,
            155.4
        ];
        let mCameraAlpha = [
            6.283,
            5.246,
            1.623,
            1.574,
            4.715,
            2.408,
            4.6567,
            1.945
        ];

        BBL.minimapSetup('Minimap_UI_ioted.glb', 'ioted_UITex', mPosArr, mRotArr, mCameraAlpha, SetClickMap,
            [Math.PI / 2, Math.PI * 3 / 2, Math.PI]);

        let npcDistance = 7;
        let multiNpc1 = [
            {
                name: `대화하기\n  <강 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (17.060, -0.564, 1.363),
                rot: new Vector3(0, Tools.ToRadians(270), 0),
                eventNum: 1,
                checkEventValue: 1,
                setEventValue: 1,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            },
            {
                name: `대화하기\n  <댐 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (-42.784, -0.742, -16.731),
                rot: new Vector3(0, Tools.ToRadians(0), 0),
                eventNum: 2,
                checkEventValue: 2,
                setEventValue: 2,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            },            
        ];     
        let multiNpc2 = [
            {
                name: `대화하기\n  <바다 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (-0.161, -0.842, -62.722),
                rot: new Vector3(0, Tools.ToRadians(180), 0),
                eventNum: 3,
                checkEventValue: 3,
                setEventValue: 3,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            },
            {
                name: `대화하기\n  <도심 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (-23.220, -0.686, -22.173),
                rot: new Vector3(0, Tools.ToRadians(180), 0),
                eventNum: 4,
                checkEventValue: 4,
                setEventValue: 4,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            }
        ];
        let multiNpc3 = [
            {
                name: `대화하기\n  <공사장 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (-23.314, -0.708, 26.593),
                rot: new Vector3(0, Tools.ToRadians(0), 0),
                eventNum: 5,
                checkEventValue: 5,
                setEventValue: 5,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            },
            {
                name: `대화하기\n  <석유열차 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (27.587, -0.742, 21.141),
                rot: new Vector3(0, Tools.ToRadians(125), 0),
                eventNum: 6,
                checkEventValue: 6,
                setEventValue: 6,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            }
        ];
        let multiNpc4 = [
            {
                name: `대화하기\n  <화학공장 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (21.673, -0.676, 29.931),
                rot: new Vector3(0, Tools.ToRadians(0), 0),
                eventNum: 7,
                checkEventValue: 7,
                setEventValue: 7,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            },
            {
                name: `대화하기\n  <고층빌딩 npc>`,
                textColor: "#1eb8d4",
                outlineColor: "#000000",
                pos: new Vector3 (17.128, -0.663, -28.413),
                rot: new Vector3(0, Tools.ToRadians(180), 0),
                eventNum: 8,
                checkEventValue: 8,
                setEventValue: 8,
                event: disPlayNpc,
                SetEvent: SetDisPlayNpc
            }
        ];
        BBL.multiNpcSetup("NPC01", multiNpc1, npcDistance, false, setIsJustFinishRender);    
        BBL.multiNpcSetup("NPC02", multiNpc2, npcDistance, false, setIsJustFinishRender);
        BBL.multiNpcSetup("NPC03", multiNpc3, npcDistance, false, setIsJustFinishRender);
        BBL.multiNpcSetup("NPC04", multiNpc4, npcDistance, true, setIsJustFinishRender);          
        
        return () => {
            setThisItem({
                hair: null,
                shoes: null,
                cloth: null,
            })
            setIsJustFinishRender(false);
        }
    }, []);

    useEffect(() => {
        if (custom) {
            let items = BBL.changeCustom(currentItems, thisItem);
            items.sValue ?
                setThisItem({
                    ...thisItem,
                    cloth: items.cloth,
                })
                :
                setThisItem({
                    ...thisItem,
                    hair: items.hair,
                    shoes: items.shoes,
                    cloth: items.cloth
                })
        }
    }, [currentItems, custom]);

    //minimap click out
    useEffect(() => {
        clickMap ? BBL.minimapOn(200, 3) : BBL.minimapOff(14, 3);
    }, [clickMap]);

    useEffect(() => {
        if (isJustFinishRender) {
            SetAllReady(true);
            setTimeout(() => {
                BBL.setAllReady();
            }, 1500);

            Socket_connection('pc', 'present', nickname, userPos, userRot, currentItems, reduxId);
        }
    }, [isJustFinishRender])

    useEffect(() => {
        if (danceValue) {
            BBL.playerDance(danceValue, SetDanceValue);
        }
    }, [danceValue])

    useEffect(() => {
        if (disPlayNpc !== false) {
            BBL.npcTalkCameraWorkIn(disPlayNpc);
        } else if (disPlayNpc === false && allReady) {
            BBL.npcTalkCameraWorkOut();
        };
    }, [disPlayNpc])

    useEffect(() => {
        if (visibleInventory) {
            BBL.cameraWorks(SetCameraActionAniAction, true);
            BBL.bubbleStateStart("closet")
        } else if (allReady && !visibleInventory) {
            BBL.cameraWorks(SetCameraActionAniAction, false);
            BBL.bubbleStateEnd();
        }
    }, [visibleInventory]);

    return (
        <>
            {
                isJustFinishRender && <OtherUsers />
            }        
        </>
    );
}

export default PresentDefaultCharacter;
