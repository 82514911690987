import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { setDisplayNpc } from "../redux/BabylonSettings";
import styled, { keyframes } from "styled-components";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../css/NpcScript.css'
import "../pages/Loading.css";

import Typewriter from 'typewriter-effect';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from "./alert/Alert";
const Animation = keyframes`
    0% {
        transform: translate(-89%, -91%) rotate(-2.5deg);
    }
    50% {
        transform: translate(-89%, -91%) rotate(2.5deg);
    }
    100% {
        transform: translate(-89%, -91%) rotate(-2.5deg);
    }
`

const Animation_M = keyframes`
    0% {
        transform: translate(-80%, -84%) rotate(-2.5deg);
    }
    50% {
        transform: translate(-80%, -87%) rotate(2.5deg);
    }
    100% {
        transform: translate(-80%, -84%) rotate(-2.5deg);
    }
`

const BackAniMation = styled.div`
    width: 640px;
    height: 650px;
    background: linear-gradient(282.73deg,#7bb4e8 7.64%,rgba(177,208,237,.44) 89.14%);
    border-radius: 55px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-89%, -91%);
    animation: ${Animation} infinite 5s ease-in-out;
    @media (max-width : 800px) {
        width: 300px;
        height: 300px;
        transform: translate(-80%, -84%);
        animation: ${Animation_M} infinite 5s ease-in-out;

    }
    @media (max-width : 280px) {
        display: none;
    }
`


const CommentsContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 29px 81px;
    /* max-width: 866px; */
    border-radius: 30px;
    overflow: hidden;
    max-width: 820px;
    transition: all 0.25s;
    margin: 0 auto;
    @media screen and (max-width: 717px) and (max-height : 512px) {
        padding: 4% 2.5%;
    
    }
    @media (max-width : 600px) {
        padding: 10% 8%;
    }
`

const LodingWrapper = styled.div`
  width: 100%;
  height : 100%;
  position: fixed;
  top : 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
`;

const LodingVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


function NpcScript() {
    const POST_MAIL = "POST_MAIL";
    const EXIT_NPC = "EXIT_NPC";
    const OUT_LINK = "OUT_LINK";
    const NEXT_SCRIPT = "NEXT_SCRIPT";
    const OX_QUIZ = "OX_QUIZ";
    const SUBMIT_QUIZ = "SUBMIT_QUIZ"
    const GREEN_ENERGY = "GREEN_ENERGY"


    const [alertMsg, setAlertMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const alertHandler = (active, text) => {
        setAlertMsg(text)
        setSuccess(active);
    }
    /**
     * OUT_LINK
     * {
        * type : OUT_LINK,
        * text : "안전영상 보러가기",
        * url : 'https://www.youtube.com/channel/UCbPBCZhgG_kX_1Wb0cdu5lw'
     * }
     * 
     */
    const npm = [
        {
            idx: 1, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 2, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 3, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 4, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 5, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 6, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 7, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 8, // 원래지구
            start: {
                img: null,
                comments: '친구들이 도와준 덕분에 그린에너지를 모아 현재 지구로 올 수 있었어!! 깨끗한 지구에서 살 수 있다니 정말 행복하다.🤓 앞으로도 깨끗한 지구를 위해 노력해줄꺼지~?😲',
                btnText: [],
                next: null
            }
        },
        {
            idx: 101, // 쓰레기 바다
            start: {
                img: null,
                quiz_idx: 1,
                comments: '바다는 지구에 있는 물 약 97.5%를 차지하고, 지구 표면의 약 71%를 덮고 있어요. 또한 지구에 있는 생물 99%가 바다에 살고 있을 만큼 아주 거대한 생태계랍니다.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 1,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 2,
                        comments: '기름 오염 사고는 한번 일어나면 해양 환경과 주변 생태계를 완전히 망가뜨립니다. 회복되는 데에 무려 10년 이상 걸린다고 해요. 그렇기 때문에 기름이 바다에 유출되지 않게 예방하는 것이 가장 중요해요.',
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 2,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 3,
                                comments: '호주 해변에서 발견된 1000여 마리의 바다거북이 사체 중 절반 이상의 내장에서 플라스틱 쓰레기가 발견되었다고 해요. 매년 약 800만 톤의 플라스틱 폐기물이 바다에 버려지고 있어 심각한 해양 오염이 우려되는 상황이죠.',
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 3,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        quiz_idx: 4,
                                        comments: '플라스틱 컵보다는 개인 텀블러를 사용하고, 비닐 봉지보다는 장바구니를 사용해요! 일회용 수저 사용을 줄이는 것도 좋은 방법이에요!',
                                        btnText: [{
                                            type: OX_QUIZ,
                                            text: "문제 풀기"
                                        }],
                                        next: {
                                            img: null,
                                            quiz_idx: 4,
                                            comments: '',
                                            btnText: [
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "X"
                                                },
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "O"
                                                }
                                            ],
                                            next: {
                                                img: null,
                                                type: GREEN_ENERGY,
                                                comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                                btnText: [],
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            idx: 102, // 말라버린 강
            start: {
                img: null,
                quiz_idx: 5,
                comments: '물이 흐르는 길, 강! 하늘에서 내린 비가 바다로 흘러가는 경로로 우리나라 고유어로는 ‘가람’이라고 해요.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 5,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 6,
                        comments: '강은 육지 위를 흐르면서 생명체에 필요한 수분을 공급하고, 육지를 침식하거나 흙, 암석 조각들을 이동시키며 민물고기가 살 수 있는 터전을 만드는 역할을 하고 식수, 농업용수, 수상교역로, 에너지 공급 등 많은 역할을 하며 우리에게 도움을 주고 있어요.',
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 6,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 7,
                                comments: '동남아시아와 브라질 북부 지역은 평년보다 강수량이 늘고, 미국 남부지역은 이상고온 현상이 나타난며, 미국 서부와 캐나다 서해안 지역은 기온이 떨어지면서 한파가 늘어나는 경향이 있다. 중국 남부지역은 강수량이 줄어들고 있어요',
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 7,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        quiz_idx: 8,
                                        comments: '독일과 네덜란드, 스위스 경제의 주축인 라인강은 폭염과 가뭄으로 몇 주 동안 수상 운송이 불가능했다고 해요. 이외에도 중부 유럽과 흑해를 다니는 길인 다뉴브강, 아시아에서 가장 긴 강 중국의 양쯔강 등 전세계 강의 수위가 낮아지고 있어요.',
                                        btnText: [{
                                            type: OX_QUIZ,
                                            text: "문제 풀기"
                                        }],
                                        next: {
                                            img: null,
                                            quiz_idx: 8,
                                            comments: '',
                                            btnText: [
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "X"
                                                },
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "O"
                                                }
                                            ],
                                            next: {
                                                img: null,
                                                type: GREEN_ENERGY,
                                                comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                                btnText: [],
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            idx: 103, // 무너진 댐
            start: {
                img: null,
                quiz_idx: 9,
                comments: '전국적으로 약 18,403개의 댐이 있다고 해요! 생각보다 꽤 많은 댐이 있죠? 강수량이 많을 때는 물을 가두어 두고, 강수량이 적을 때는 물을 흘려보내 홍수와 가뭄을 조절하는 역할을 해요.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 9,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 10,
                        comments: "우리나라에서 가장 큰 댐은 높이는 123m, 만수위는 198m, 제방 길이는 530m, 총 가용 저수량은 무려 29억 톤을 가진 강원도 춘천에 있는 북한강의 지류 '소양강 다목적댐'이에요!",
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 10,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 11,
                                comments: "댐은 일정 수위를 넘기면 물넘이를 통해 안전하고 신속하게 물을 방류해요. 하지만 집중호우로 인해 물넘이의 용량 이상의 물이 댐으로 밀려 들어오고 상류에서 부러진 나무와 토사 등이 다량 유입되어 물넘이를 막아버린다면 댐은 무너져 버려요.",
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 11,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        quiz_idx: 12,
                                        comments: '이렇게 중요한 댐은 누가 관리할까요? 댐은 국가적으로 중요한 시설물이기 때문에 나라에서 직접 관리해요. 우리나라는 한국수자원공사(K-water)에서 관리하고 있어요!',
                                        btnText: [{
                                            type: OX_QUIZ,
                                            text: "문제 풀기"
                                        }],
                                        next: {
                                            img: null,
                                            quiz_idx: 12,
                                            comments: '',
                                            btnText: [
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "X"
                                                },
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "O"
                                                }
                                            ],
                                            next: {
                                                img: null,
                                                quiz_idx: 13,
                                                comments: '댐은 어떻게 관리해야 할까요? 최근에는 기후 변화로 인해 한꺼번에 많은 비가 내려 극한강우량(PMP)에 안전하도록 댐의 높이를 충분히 높게 짓고 있어요!',
                                                btnText: [{
                                                    type: OX_QUIZ,
                                                    text: "문제 풀기"
                                                }],
                                                next: {
                                                    img: null,
                                                    quiz_idx: 13,
                                                    comments: '',
                                                    btnText: [
                                                        {
                                                            type: SUBMIT_QUIZ,
                                                            text: "X"
                                                        },
                                                        {
                                                            type: SUBMIT_QUIZ,
                                                            text: "O"
                                                        }
                                                    ],
                                                    next: {
                                                        img: null,
                                                        type: GREEN_ENERGY,
                                                        comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                                        btnText: [],
                                                    }
                                                }
                                            },


                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            idx: 104, // 폐공사장
            start: {
                img: null,
                quiz_idx: 14,
                comments: '과거 우리나라는 생산 위주의 중·경공업 바탕으로 발전해 왔으나, 산업구조의 변화로 인해 도시의 중심이었던 산업단지는 기능을 잃고 운영이 중지된 채 방치되고 있어요.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 14,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 15,
                        comments: '방치된 폐공사장은 관리가 되지 않아 곰팡이가 피거나 쥐가 생기는 등 사람들이 기피하는 흉물이 되기 마련이죠. 문화체육관광부와 지방자치단체는 ‘폐산업시설 문화재생 사업’으로 이런 곳을 되살리고 있어요.',
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 15,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 16,
                                comments: '전남 담양의 해동문화예술촌은 2010년 폐업한 해동주조장을 다양한 전시와 공연을 여는 복합문화 공간으로 재탄생 시켰죠.',
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 16,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        type: GREEN_ENERGY,
                                        comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                        btnText: [],
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            idx: 105, // 가동중지 석유화학 공장
            start: {
                img: null,
                quiz_idx: 17,
                comments: '석유화학공장에서 사용되는 천연 가스, 석유 등의 화석 연료는 이용 과정에서 이산화탄소의 배출량이 많아, 온실 효과에 의한 지구 온난화를 일으키는 원인으로 손꼽히고 있습니다.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 17,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 18,
                        comments: '지속 가능한 발전을 할 수 있는 새로운 에너지의 개발은 필수적인 과제입니다. 그래서 우리는 화석 에너지의 고갈뿐만 아니라 지구 온난화 같은 문제들을 해결하기 위해서 새로운 신·재생 에너지를 개발해야 합니다.',
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 18,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 19,
                                comments: "재생에너지의 정확한 명칭은 '재생가능에너지'로서 '다시 사용할 수 있는 에너지'입니다. 우리나라 법률에 따른 재생에너지는 총 8개분야로, 태양에너지, 풍력, 지열에너지, 수력, 해양에너지, 바이오에너지, 폐기물에너지 등입니다.",
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 19,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        quiz_idx: 20,
                                        comments: '신에너지란, 기존의 화석연료를 변환시켜 이용하거나 수소·산소 등의 화학 반응을 통하여 전기 또는 열을 이용하는 에너지를 이야기합니다. 기존 연료를 새로운 방법으로 이용하거나 ​화학 반응을 이용하는 에너지라는 의미입니다.',
                                        btnText: [{
                                            type: OX_QUIZ,
                                            text: "문제 풀기"
                                        }],
                                        next: {
                                            img: null,
                                            quiz_idx: 20,
                                            comments: '',
                                            btnText: [
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "X"
                                                },
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "O"
                                                }
                                            ],
                                            next: {
                                                img: null,
                                                type: GREEN_ENERGY,
                                                comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                                btnText: [],
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            idx: 106, // 운행이 중지된 석유 열차
            start: {
                img: null,
                quiz_idx: 21,
                comments: '운송수단에 사용되는 천연 가스, 석유 등의 화석 연료는 이용 과정에서 이산화탄소의 배출량이 많아, 온실 효과에 의한 지구 온난화를 일으키는 원인으로 손꼽히고 있습니다. 따라서 대체에너지를 연료로 이용하는 운송수단이 필요합니다.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 21,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 22,
                        comments: '파리기후 협약으로 온실가스 감축이라는 이슈와 함께 전 세계적으로 대중화 되어가는 추세 속에 우리나라 역시 전기차 보조금 지원 등의 정책 등을 통해 전기차 보급 확대를 위한 노력이 계속 되고 있습니다.',
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 22,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 23,
                                comments: "다음은 바로 ‘하이퍼루프(Hyperloop)’입니다. 원통형 튜브관 안에서 강한 공기압을 활용해 이동하는 열차로 최대 1,126km/h의 속도로 달릴 수가 있어 초고속 이동수단으로 각광받고 있습니다.",
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 23,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        quiz_idx: 24,
                                        comments: '하이퍼루프는 신재생에너지 중 태양광을 이용한 이동수단이라고 할 수 있는데요. 하이퍼루프는 가속에 사용되는 선형 유도 모터 튜브 지붕에 태양광 패널을 설치하고 이를 통해 충전되는 방식입니다.',
                                        btnText: [{
                                            type: OX_QUIZ,
                                            text: "문제 풀기"
                                        }],
                                        next: {
                                            img: null,
                                            quiz_idx: 24,
                                            comments: '',
                                            btnText: [
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "X"
                                                },
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "O"
                                                }
                                            ],
                                            next: {
                                                img: null,
                                                type: GREEN_ENERGY,
                                                comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                                btnText: [],
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            idx: 107, // 버려진 빌딩
            start: {
                img: null,
                quiz_idx: 25,
                comments: '인간이 행하는 건축행위는 막대한 양의 탄소를 배출합니다. 건설단계에서부터 건물 운용, 보수, 그리고 해체에 이르기까지 끊임없이 환경 오염이 일어납니다. 때문에, 건물을 운영하는 과정에서 탄소 배출을 줄일 수 있도록 노력하는 것이 중요합니다.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 25,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 26,
                        comments: '탄소 배출을 줄이기 위한 친환경 건축에서 중요한 두 가지 개념이 바로 ‘탄소 중립’과 ‘제로에너지 건축’입니다.',
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 26,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 27,
                                comments: "먼저 ‘탄소 중립’이란, 배출되는 탄소의 양과 다시 흡수되는 양을 같도록 맞추어, 최종적으로 탄소 배출이 0이 되도록 하는 것입니다.",
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 27,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        quiz_idx: 28,
                                        comments: '‘제로에너지(zero-energy) 건축’은 단열과 공기 유출 차단을 강화해 에너지 사용을 줄이고 신재생에너지 설비로 에너지를 생산하는 건물을 짓는 것을 말합니다.',
                                        btnText: [{
                                            type: OX_QUIZ,
                                            text: "문제 풀기"
                                        }],
                                        next: {
                                            img: null,
                                            quiz_idx: 28,
                                            comments: '',
                                            btnText: [
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "X"
                                                },
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "O"
                                                }
                                            ],
                                            next: {
                                                img: null,
                                                type: GREEN_ENERGY,
                                                comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                                btnText: [],
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            idx: 108, // 전력이 없는 도심
            start: {
                img: null,
                quiz_idx: 29,
                comments: '태양광 패널을 이용한 운송 수단 및 도로는 지속적으로 발전할 경우 충분한 대체 수단이 될 수 있을 것입니다. 태양광 패널로 만들어진 자전거 도로는 약 1km 길이에 걸쳐 설치된 태양광 패널로 최대 2,000MWh의 전기를 생산할 수 있습니다.',
                btnText: [{
                    type: OX_QUIZ,
                    text: "문제 풀기"
                }],
                next: {
                    img: null,
                    quiz_idx: 29,
                    comments: '',
                    btnText: [
                        {
                            type: SUBMIT_QUIZ,
                            text: "X"
                        },
                        {
                            type: SUBMIT_QUIZ,
                            text: "O"
                        }
                    ],
                    next: {
                        img: null,
                        quiz_idx: 30,
                        comments: '신재생 에너지의 단점은 자연을 이용하기때문에 일정하게 에너지를 생산할 수 없다는 점이 있어요. 그래서 불규칙한 수요와 공급을 조절하기 위해 저장 장치를 만들었죠',
                        btnText: [{
                            type: OX_QUIZ,
                            text: "문제 풀기"
                        }],
                        next: {
                            img: null,
                            quiz_idx: 30,
                            comments: '',
                            btnText: [
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "X"
                                },
                                {
                                    type: SUBMIT_QUIZ,
                                    text: "O"
                                }
                            ],
                            next: {
                                img: null,
                                quiz_idx: 31,
                                comments: "에너지 저장장치는 태양광 발전 등을 통해 생산한 전기를 배터리에 저장했다가 필요 시 공급해 전력 효율을 높이는 장치입니다. 에너지 저장장치의 특징은 태양광은 낮에만 발전해 전기를 공급할 수 있으나 에너지 저장장치를 활용하면 낮에 생산한 전기를 저장했다가 밤에 사용할 수 있다는 것입니다.",
                                btnText: [{
                                    type: OX_QUIZ,
                                    text: "문제 풀기"
                                }],
                                next: {
                                    img: null,
                                    quiz_idx: 31,
                                    comments: '',
                                    btnText: [
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "X"
                                        },
                                        {
                                            type: SUBMIT_QUIZ,
                                            text: "O"
                                        }
                                    ],
                                    next: {
                                        img: null,
                                        quiz_idx: 32,
                                        comments: '지열에너지를 이용한 전기의 생산도 있습니다.',
                                        btnText: [{
                                            type: OX_QUIZ,
                                            text: "문제 풀기"
                                        }],
                                        next: {
                                            img: null,
                                            quiz_idx: 32,
                                            comments: '',
                                            btnText: [
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "X"
                                                },
                                                {
                                                    type: SUBMIT_QUIZ,
                                                    text: "O"
                                                }
                                            ],
                                            next: {
                                                img: null,
                                                quiz_idx: 33,
                                                comments: '태양열의 약 47%가 지표면을 통해 지하에 저장됩니다.',
                                                btnText: [{
                                                    type: OX_QUIZ,
                                                    text: "문제 풀기"
                                                }],
                                                next: {
                                                    img: null,
                                                    quiz_idx: 33,
                                                    comments: '',
                                                    btnText: [
                                                        {
                                                            type: SUBMIT_QUIZ,
                                                            text: "X"
                                                        },
                                                        {
                                                            type: SUBMIT_QUIZ,
                                                            text: "O"
                                                        }
                                                    ],
                                                    next: {
                                                        img: null,
                                                        quiz_idx: 34,
                                                        comments: '‘땅 위의 온도는 계절에 따라 변하지만 지표면 가까운 땅 속 온도는 거의 변하지 않고 10~20도 사이를 유지합니다. 이 때 지구 내부로부터 표면을 거쳐 외부로 나오는 열을 이용해 전기를 생산하거나 난방을 할 수 있습니다.',
                                                        btnText: [{
                                                            type: OX_QUIZ,
                                                            text: "문제 풀기"
                                                        }],
                                                        next: {
                                                            img: null,
                                                            quiz_idx: 34,
                                                            comments: '',
                                                            btnText: [
                                                                {
                                                                    type: SUBMIT_QUIZ,
                                                                    text: "X"
                                                                },
                                                                {
                                                                    type: SUBMIT_QUIZ,
                                                                    text: "O"
                                                                }
                                                            ],
                                                            next: {
                                                                img: null,
                                                                type: GREEN_ENERGY,
                                                                comments: '덕분에 지구를 위한 그린에너지를 모았어! 조금만 더 모으면 원래의 지구로 돌아갈 수 있을 거 같아!',
                                                                btnText: [],
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
    ]
    const Video = useRef();
    const npcImg = useRef(null);
    const [active, setActive] = useState(false);
    const [video, setVideo] = useState(false);
    const [btnAnimation, setBtnAnimation] = useState(false);
    const { disPlayNpc, id, chatbot_id, ioted_id } = useSelector((state) => ({
        disPlayNpc: state.BabylonSettings.disPlayNpc,
        id: state.UserInfo.id,
        chatbot_id: state.UserInfo.chatbot_id,
        ioted_id: state.UserInfo.ioted_id
    }));
    const [targetNpc, setTargetNpc] = useState(npm.filter(obj => obj.idx === disPlayNpc ? true : false)[0].start);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const SetDisplayNpc = (active) => dispatch(setDisplayNpc(active, ''))
    const [isQuizMode, setIsQuizMode] = useState(false);
    const [isAblePrsent, setIsAblePresent] = useState(false);
    // 타이포애니메이션 관리
    useEffect(() => {
        setTimeout(() => {
            npcImg.current = targetNpc.img
            setActive(true);
        }, 600)
        return () => {
            setTargetNpc(null);
            // npcImg.current = null;
            setBtnAnimation(false);
            setActive(false);
        }
    }, [])

    const ClickNextButtonHandler = (e, type, url) => {
        // 더 보기
        if (type === NEXT_SCRIPT) {
            if (targetNpc.next !== null) {
                setBtnAnimation(false);
                setActive(false);
                setTargetNpc(targetNpc.next);
                npcImg.current = targetNpc.next.img;
                setTimeout(() => {
                    setActive(true);
                }, 100)
            } else {
                SetDisplayNpc(false)
            }
        }
        // 퀴즈
        if (type === OX_QUIZ) {
            axios.get(`${process.env.REACT_APP_API_URL}/ox_select`, {
                params: {
                    quiz_idx: targetNpc.quiz_idx
                }
            }).then(res => {
                setTargetNpc({
                    ...targetNpc.next,
                    comments: res.data[0].question,
                    answer: res.data[0].reply
                })
                setBtnAnimation(false);
                setActive(false);
                setIsQuizMode(true);
                setTimeout(() => {
                    setActive(true);
                }, 100)
            })
            // console.log("여기도 통신")
        }
        if (type === SUBMIT_QUIZ) {
            if (targetNpc.answer === e.target.value) {
                axios.post(`${process.env.REACT_APP_API_URL}/ioted_post_api`, { type: 'talk', idx: targetNpc.quiz_idx, user_id: ioted_id, chatbot_id: chatbot_id }).then(res => {
                    if (res.data.code === 201 || res.data.code === 200) {
                        setSuccess(true);
                        setAlertMsg('챗봇에 지식이 추가됐어요');
                        if (targetNpc.next !== null) {
                            if (targetNpc.next.type === GREEN_ENERGY) {
                                axios.post(`${process.env.REACT_APP_API_URL}/green_insert`, { user_id: id }).then(result => {
                                    if (result.data.cnt >= 30) {
                                        setTargetNpc({
                                            ...targetNpc.next,
                                            comments: '지구를 위한 그린에너지를 모두 채웠어요! 이제 현재 지구로 돌아가볼까요??',
                                            btnText: [],
                                            next: null
                                        })
                                        setIsAblePresent(true);
                                        setBtnAnimation(false);
                                        setActive(false);
                                        setIsQuizMode(false);
                                        setTimeout(() => {
                                            setActive(true);
                                        }, 100)
                                    } else {
                                        setTargetNpc(targetNpc.next);
                                        setBtnAnimation(false);
                                        setActive(false);
                                        setIsQuizMode(false);
                                        setTimeout(() => {
                                            setActive(true);
                                        }, 100)
                                    }
                                })
                            } else {
                                setTargetNpc(targetNpc.next);
                                setBtnAnimation(false);
                                setActive(false);
                                setIsQuizMode(false);
                                setTimeout(() => {
                                    setActive(true);
                                }, 100)
                            }
                        } else {
                            SetDisplayNpc(false)
                        }
                    } else if (res.data.code === -112) {
                        setTargetNpc({
                            ...targetNpc.next,
                            comments: '하나의 질문엔 최대 10개의 답변을 생성할 수 있습니다.',
                            btnText: [],
                            next: null
                        })
                        setBtnAnimation(false);
                        setActive(false);
                        setIsQuizMode(false);
                        setTimeout(() => {
                            setActive(true);
                        }, 100)
                    }
                })
            } else {
                setTargetNpc(targetNpc.next);
                setBtnAnimation(false);
                setActive(false);
                setIsQuizMode(false);
                setTimeout(() => {
                    setActive(true);
                }, 100)
            }
        }
        // 링크 연결
        if (type === OUT_LINK) {
            if (url) {
                window.open(url, "_blank");
            }
        }
    }

    const onReset = () => {
        if (isAblePrsent) {
            setVideo(true);
            setTimeout(() => {
                SetDisplayNpc(false);
                setVideo(false);
                navigation('/present');
            }, 10000)
        } else {
            SetDisplayNpc(false)
        }
    }

    return (
        <>
            {video && <LodingWrapper>
                {video && <LodingVideo ref={Video} src={`/assets/video/loading.mp4`} muted playsInline autoPlay loop></LodingVideo>}
            </LodingWrapper>}
            {
                disPlayNpc !== false && targetNpc !== null && <div className="npcScriptContainer">

                    {npcImg.current !== null && <div className={targetNpc.imgType === "MOBILE_NONE" ? "npcImgContainer mobile_none" : "npcImgContainer"}>
                        {/* <img src={npcImg.current} alt="" /> */}
                        <Swiper className="mySwiper" pagination={true} navigation={true} modules={[Navigation, Pagination]}>
                            {
                                npcImg.current.map((src, index) => {
                                    return (
                                        <SwiperSlide key={index} style={{ backgroundImage: `url('${src}')` }}>
                                            {/* <img src={src} alt="" /> */}
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>}
                    <div className="npcCommentContainer">
                        {success && <Alert active={success} text={alertMsg} alertHandler={alertHandler} />}
                        <div className="npcCommentTail"></div>
                        <CommentsContainer>
                            <BackAniMation></BackAniMation>
                            {active &&
                                <Typewriter
                                    options={{
                                        delay: 20
                                    }}
                                    onInit={(typewriter) => {
                                        typewriter.typeString(targetNpc.comments)
                                            .callFunction(() => {
                                                // 타입이 끝나는 지점
                                                setBtnAnimation(true);
                                            })
                                            .start();
                                    }}
                                />
                            }
                        </CommentsContainer>
                    </div>
                    <div className="npcButtonContainer">
                        {!isQuizMode && <button className={btnAnimation ? "npcNextCancelButton animation" : "npcNextCancelButton"} onClick={onReset} >{isAblePrsent ? '현재로 돌아가기' : '안녕, 다음에봐!'}</button>}
                        {
                            targetNpc.btnText.map((obj, index) => {
                                return (
                                    <button value={obj.text} key={index} className={btnAnimation ? "npcNextActionButton animation" : "npcNextActionButton"} onClick={(e) => ClickNextButtonHandler(e, obj.type, obj.url)}>{obj.text}</button>
                                )
                            })
                        }
                        {/* <button className={btnAnimation ? "npcNextActionButton animation" :"npcNextActionButton"} onClick={ClickNextButtonHandler}>{targetNpc.btnText}</button> */}
                    </div>
                </div>
            }

        </>
    );
}

export default NpcScript;

