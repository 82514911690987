import React, {useEffect, useState, useRef} from "react";
import '../css/guestBook.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faXmark, faPlus, faMusic, faMusicSlash } from '@fortawesome/pro-solid-svg-icons'
import axios from "axios";
import {socket} from '../middleware/Socket';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setGuestBookAction } from '../redux/BabylonSettings';
import styled, {keyframes} from "styled-components";
import rightFlower from '../assets/img/guestbook/right.webp'
import leftFlower from '../assets/img/guestbook/left.webp'
const InputContainerAni = keyframes`
    0% {
        transform: translateY(5%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`

const Back = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    display: none;
    align-items: center;
    background: rgba(0,0,0,0.0);
    justify-content: center;
    &.open {
        display: flex;
    }

`

const Container = styled.div`
    width: 90%;
    height: 80%;
    max-height: 650px;
    max-width: 1280px;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #000;
    position: relative;
    &.open {
        display: flex;
    }

    & > .img-container {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & > .img-container.left {
        left: 0;
        width: 218.4px;
        transform: translateX(-50%);
    }
    & > .img-container.right {
        right: 0;
        width: 199.4px;
        transform: translateX(50%);
    }
    & > .img-container > img {
        width: 100%;
    }
    @media (max-width: 1024px) {
        max-height : 750px;
        height: 75%;
        & > .img-container {
            display: none;
        }
    }
`

const TopContainer = styled.div`
    width: 100%;
    padding-top: 40px;
    padding-bottom: 52px;
    position: relative;
    z-index: 2;
    & > div.guestBookTitleInfo {
        display: flex;
        align-items: baseline;
        justify-content: center;
    }
    & h1 {
        font-family: '210Soopilmyungjo040-Regular';
        font-weight: 400;
        color: #fff;
        font-size: 40px;
    }
    @media (max-width: 1024px) {
        padding-top: 20px;
        padding-bottom: 32px;
        & > div.guestBookTitleInfo {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
        }
        & h1 {
            font-size: 26px;
            padding-left: 5%;
        }
    }
`

const Button = styled.button`
    cursor: pointer;
    padding: 6.5px 15px;
    font-family: var(--fn-sans);
    letter-spacing: -0.9px;
    font-weight: 500;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: unset;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 55px;
    right: 54px;
    &.hidden {
        display: none;
    }
    & > span {
        margin-right: 5px;
    }
    &:hover {
        background-color: #fff;
        color : #000;
    }
    @media (max-width: 1024px) {
        top: 19px;
        right: 22px;
    }
`

const CommentsContainer = styled.div`
    width: 100%;
    height: calc(100% - 137px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
    & .guestBookPozContainer {
        width: 100%;
        padding: 0 5% 2%;
        height: 100%;
        overflow-y: auto;
        position: relative;
        z-index: 2;
        transition: all 0.5s ease-in-out;
    }
    & .guestBookPozContainer.active {
        transform: translateY(100%);
    }
    & .guestBookPozContainer::-webkit-scrollbar-thumb {
        background-color: #fff;
    }
    & .guestBookPozContainer::-webkit-scrollbar {
        width: 4px;
        border-radius: 12px;
    }
    & .commentsBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #fff;
        position: relative;
        font-family: '210Soopilmyungjo020-Regular';
    }
    & .commentsBox:not(:last-of-type) {
        margin-bottom: 27px;
    }
    /* text */
    & .commentsBox > p {
        width: 100%;
        font-size: 20px;
        display: flex;
        justify-content: center;
        font-weight: normal;
        background-color: #4a4a4a;
        padding: 18px 0;
        line-height: 1.5;
    }
    & .commentsInfo {
        padding-top: 9px;
        margin-left: auto;
    }
    & .commentsInfo > .dot::before {
        content: ' ';
    }
    & .commentsInfo > .dot::after {
        content: ' ';
    }

    @media (max-width: 1024px) {
        height: calc(100% - 81px);
        & .commentsBox {
            align-items: center;
        }
        & .commentsBox > p {
            font-size: 16px;
            letter-spacing: -0.48px;
            padding: 15px 4px;
            justify-content: center;
            text-align: center;
        }
        & .commentsInfo {
            margin-left: 0;
        }
        & .commentsBox:not(:last-of-type) {
            margin-bottom: 38px;
        }
    }
`


const InputContainer = styled.div`
    width: 100%;
    padding: 0 5%;
    flex-direction: column;
    align-items: center;
    display: none;
    opacity: 0;
    &.active {
        animation: ${InputContainerAni} 0.25s 0.25s ease-in-out forwards;
        display: flex;
    }
    & > input {
        width: 100%;
        text-align: center;
        width: 100%;
        height: 58px;
        font-family: '210Soopilmyungjo020-Regular';
        background-color: #4a4a4a;
        font-size: 20px;
        color: #fff;
    }
    & > .btn-container {
        display: flex;
        align-items: center;
        margin-top: 30px;
    }
    & > .btn-container > .show-list {
        cursor: pointer;
        padding: 6.5px 15px;
        font-family: var(--fn-sans);
        letter-spacing: -0.9px;
        font-weight: 500;
        color: #fff;
        font-size: 12px;
        border: 1px solid #fff;
        border-radius: 5px;
        background-color: unset;
        margin-right: 12px;
    }
    & > .btn-container > .show-list:hover {
        background-color: #fff;
        color: #000;
    }
    & > .btn-container > .submit {
        cursor: pointer;
        padding: 6.5px 15px;
        font-family: var(--fn-sans);
        letter-spacing: -0.9px;
        font-weight: 500;
        border-radius: 5px;
        font-size: 12px;
        color : #000;
        background-color: #20d1ff;
    }
    & > .btn-container > .submit:hover {
        background-color: #5ecae5;
    }
`

const ControllerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top : 0;
    right : 0;
    transform: translateX(calc(100% + 15px));
    & > .bgm {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        font-size: 26px;
        background-color: rgba(0,0,0,0.16);
        cursor: pointer;
    }
    & > .bgm:hover {
        background-color: rgba(0,0,0,0.32);
    }
    & > .cancel {
        width: 50px;
        border-radius: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        background-color: #1a317c;
        margin-top: 15px;
        color: #fff;
        cursor: pointer;
    }
    & > .cancel:hover {
        background-color: #2c4597;
    }
    @media (max-width : 1440px) {
        left: 50%;
        right: unset;
        transform: translate(-50%, calc(-100% - 15px));
        flex-direction: row;
        & > .bgm {
            width: 50px;
            height: 50px;
            font-size: 20px;
        }
        & > .cancel {
            margin-top: 0;
            margin-left: 15px;
        }
    }
`


function GuestBook() {
    const [input, setInput] = useState('');
    const audio = useRef()
    const [activeBGM, setActiveBGM] = useState(true);
    const [comments, setComments] = useState([]);
    const commentsContainer = useRef();
    const dispatch = useDispatch();
    const {guestbook}= useSelector((state)=>({
        guestbook : state.BabylonSettings.guestbook,
    }), shallowEqual);
    const [activeInput, setActiveInput] = useState(false);


    const {id, nickname, role}= useSelector((state)=>({
        id : state.UserInfo.id,
        nickname : state.UserInfo.nickname,
        role : state.UserInfo.role
    }), shallowEqual);
    
    const SetGuestBookAction = (active) => dispatch(setGuestBookAction(active))
    // 나중에 주석해제
    useEffect(()=> {
        axios.post(`${process.env.REACT_APP_API_URL}/board_list`).then((response)=> {
            setComments([...response.data.list])
        })
        audio.current.volume = 0.5;
    }, []);
    useEffect(()=> {
        if (activeBGM) {
            audio.current.play();
        } else {
            audio.current.pause();
        }
    }, [activeBGM])
    socket.on('_GUEST_BOOK', (id,text, date, nickName)=> {
        const comment = [{
            text,
            reg_date : date,
            nick_name : nickName
        }]
        setComments(comments.concat(comment));
        // commentsContainer.current.scrollTop = commentsContainer.current.scrollHeight
        document.querySelector(".guestBookPozContainer").scrollTop = document.querySelector(".guestBookPozContainer").scrollHeight
    })

    const clickWriteButtonHandler = () => {
        if (role === 1) {
            setActiveInput(true);
        } else {
            window.alert("로그인 후 이용가능한 서비스입니다.")
        }
    }
    /**
     * 글쓰기 이벤트 핸들러
     * @param {*} e 
     */
    const onSubmit = (e) => {
        if (input !== '') {
            let today = new Date();   
            let year = today.getFullYear(); // 년도
            let month = today.getMonth() + 1;  // 월
            let date = today.getDate();  // 날짜
            if (date < 10) {
                date = `0${date}`
            }
            const fullToday = `${year}-0${month}-${date}`
            axios.post(`${process.env.REACT_APP_API_URL}/board_insert`, {
                reg_id : id,
                text : input
            }).then((response)=> {
                setActiveInput(false);
                socket.emit('GUEST_BOOK', id, input, fullToday, nickname);
                setInput('')
            })
        } else {
            window.alert("메세지를 입력해주세요.");
        }
    }
    const onChangeInput = (e) => {
        if (e.target.value.length <= 70) {
            setInput(e.target.value);
        } else {
            window.alert("최대 70자까지 작성하실 수 있습니다.")
        }
    };

    const onClickVolumHandler = () => {
        setActiveBGM(!activeBGM)
    }
    
    return(
        <>
            <audio src="/assets/bgm.mp3" ref={audio} loop></audio>
            <Back className="open">
                <Container className="open">
                    <div className="img-container right">
                        <img src={rightFlower} alt="" />
                    </div>
                    <div className="img-container left">
                        <img src={leftFlower} alt="" />
                    </div>
                    <ControllerContainer>
                        <button className="bgm" onClick={onClickVolumHandler}>
                            {activeBGM ? <FontAwesomeIcon icon={faMusicSlash} /> : <FontAwesomeIcon icon={faMusic} />}
                        </button>
                        <button className="cancel" onClick={()=> SetGuestBookAction(false)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </ControllerContainer>
                    <TopContainer className="guestBookTopContainer">
                        <div className="guestBookTitleInfo">
                            <h1>추모 방명록</h1>
                            <Button className={activeInput ? 'hidden' : ''} onClick={clickWriteButtonHandler}>
                                <span>방명록 쓰기</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </div>
                    </TopContainer>
                    <InputContainer className={activeInput ? 'active' : ''}>
                        <input type="text" name="" id="" value={input} onChange={onChangeInput} placeholder="추모 메세지를 이곳에 입력해주세요. 70자까지 작성하실 수 있습니다." />
                        <div className="btn-container">
                            <button className="show-list" onClick={()=> setActiveInput(false)}>목록보기</button>
                            <button className="submit" onClick={onSubmit}>
                                <span>방명록 쓰기</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                    </InputContainer>
                    <CommentsContainer>
                        <div className={activeInput ? "guestBookPozContainer active" : "guestBookPozContainer"} ref={commentsContainer}>
                            {
                                comments.map((obj, index)=>(
                                    <div key={index} className="commentsBox">
                                        <p>{obj.text}</p>
                                        <div className="commentsInfo">
                                            <span className="commentsRegDate">{obj.reg_date}</span>
                                            <span className="dot">•</span>
                                            <span className="commentsRegNickName">{obj.nick_name}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </CommentsContainer>
                </Container>
            </Back>
        </>
    );
}

export default React.memo(GuestBook);