import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState, useCallback } from 'react';
import "../css/usertoolBox.css"
import {socket} from '../middleware/Socket'
import { setCameraSwitch, setAudioSwitch, setClickInfo, setOpenVideoArea, setOpenWait, setVideoChatCancelBtn } from '../redux/BabylonSettings';
import {useMediaQuery} from 'react-responsive';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons'
import { faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const createEmptyAudioTrack = () => {
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const dst = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    const track = dst.stream.getAudioTracks()[0];
    return Object.assign(track, { enabled: false });
  };
  
export const createEmptyVideoTrack = ({ width, height }) => {
    const canvas = Object.assign(document.createElement('canvas'), { width, height });
    canvas.getContext('2d').fillRect(0, 0, width, height);

    const stream = canvas.captureStream();
    const track = stream.getVideoTracks()[0];

    return Object.assign(track, { enabled: false });
};

function WebRtc() {
    const {connectingMsg, clickInfo, videoChatCancelBtn, cameraSwitch, audioSwitch, openWait, peerConnectId, peerObj, openVideoArea} = useSelector((state) => ({
        connectingMsg : state.BabylonSettings.connectingMsg,
        clickInfo : state.BabylonSettings.clickInfo,
        videoChatCancelBtn: state.BabylonSettings.videoChatCancelBtn,
        cameraSwitch: state.BabylonSettings.cameraSwitch,
        audioSwitch: state.BabylonSettings.audioSwitch,
        openWait: state.BabylonSettings.openWait,
        peerConnectId: state.BabylonSettings.peerConnectId,
        peerObj : state.BabylonSettings.peerObj,
        openVideoArea : state.BabylonSettings.openVideoArea
    }));

    const {userInfo} = useSelector((state) => ({
        userInfo : state.UserInfo
    }));
    
    const dispatch = useDispatch();
    const SetOpenWait = (openWaitStatus) => dispatch(setOpenWait(openWaitStatus));
    const SetOpenVideoArea = (OpenVideoAreaStatus) => dispatch(setOpenVideoArea(OpenVideoAreaStatus));
    const SetClickInfo = (data) => dispatch(setClickInfo(data));
    const SetVideoChatCancelBtn = (cancelBtn) => dispatch(setVideoChatCancelBtn(cancelBtn))
    const SetCamaraSwitch = (value) => dispatch(setCameraSwitch(value));
    const SetAudioSwitch = (value) => dispatch(setAudioSwitch(value));

    const [reqConnect, setReqConnect] = useState(false)
    const [reqId,  setReqId] = useState('');
    const [reqNickname, setReqNickname] = useState('');
    const [reqResult, setReqResult] = useState(null);
    const [reqRejectMsg, setReqRejectMsg] = useState(false);
    const [alarmExit, setAlarmExit] = useState(false);
    const [socketNickname, setSocketNickname] = useState('');

    let peerIds = useRef('');
    const remoteVideoRef = useRef(null);
    const currentUserVideoRef = useRef(null);
    const peerInstance = useRef(null);

    const nickname= useSelector((state)=>state.UserInfo.nickname);
    const [msg, setMsg] = useState("");

    // MediaQuery
    const isDesktop = useMediaQuery({
        query: "(min-width:768px)",
      });
    const isMobile = useMediaQuery({
        query: "(max-width:767px)",
    });

    // 첫 렌더링 막기
    const didMountAudio = useRef(false);
    const didMountCamera = useRef(false);

    let peerConnection;

    // 마이크 끄기 / 켜기
    useEffect(() => {
        if (didMountAudio.current) {
            const myVideo = currentUserVideoRef.current.srcObject;
            if(myVideo){
                myVideo.getAudioTracks().forEach((track) => (track.enabled = !track.enabled));
            }
        } else {
            didMountAudio.current = true;
        }
    }, [audioSwitch]);

    // 카메라 끄기 / 켜기
    useEffect(() => {
        if (didMountCamera.current) {
            const myVideo = currentUserVideoRef.current.srcObject;
            if(myVideo){
                myVideo.getVideoTracks().forEach((track) => (track.enabled = !track.enabled));
            }
        } else {
            didMountCamera.current = true;
        }
        
    }, [cameraSwitch]);

    // init
    useEffect(()=> {
        // 소켓
        subscribeSocket();

        // peerJS
        peerObj.on('call', async (call) =>  {
            let constraints = { video: true, audio: { echoCancellation: false } };
            try{
                await navigator.mediaDevices.getUserMedia(constraints).then(async (mediaStream) => {
                    currentUserVideoRef.current.srcObject = mediaStream;
                    currentUserVideoRef.current.playsInline = true;
                    currentUserVideoRef.current.muted = true;
                    currentUserVideoRef.current.onloadedmetadata = () => {
                        currentUserVideoRef.current.play();
                    }    
                });
            } catch(e) {
                // console.log(e);
                // currentUserVideoRef.current.srcObject = {};
            }
            
            call.answer(currentUserVideoRef.current.srcObject);
            call.on('stream', function(remoteStream) {
                remoteVideoRef.current.srcObject = remoteStream
                remoteVideoRef.current.playsInline = true;
                remoteVideoRef.current.onloadedmetadata = () => {
                    remoteVideoRef.current.play();
                }
            });
        })
        peerInstance.current = peerObj;
        
        // 새로고침, 닫기 등 화면 이탈 감지
        const handler = (e) => {
            // e.preventDefault();
            // e.returnValue = '';
            handlereload()
        }
        
        window.addEventListener('beforeunload', handler);
        window.addEventListener('unload', handler);
        return() => { 
        }
    }, []);
    
    // 소켓관련 묶음
    const subscribeSocket = () => {
        // 화상대화 신청 > 받은사람이 거절 시 
        socket.on('_rejectConnect', async (from_id)=> {
            setReqConnect(false);
            handleRejectConnect();
            socket.emit('exitReqConnect', from_id);
        })

        // 화상대화 화면 > 종료
        socket.on('_chatClose', (from_id, reload) => {
            handleVideoChatExit(true);
            setReqConnect(false);
            setAlarmExit(true);
            window.localStorage.removeItem('socketId');
        })

        // 화상대화 신청 > 대기중에 신청자가 취소
        socket.on('_exitReqConnect', from_id => {
            setReqConnect(false);
        })

        // "다른사람과 이미 통화중 입니다"
        socket.on('_rejectCalling', from_id => {
            SetOpenWait(false)
            setSocketNickname(from_id)
            clearData();
            const alreadyCallAlert = document.querySelector('.AlreadyCallContainer');
            alreadyCallAlert.classList.add('show')
            setTimeout(() => {
                alreadyCallAlert.classList.remove('show')
            }, 2000)
        });

        // peerJS 
        socket.on("_tryPeer", (req_socketId, req_nickname, req_peerId) => {
            // let checkMobile = getMobile();
            // if(checkMobile === "mobile"){
            //     socket.emit('mobileCheck', req_socketId);
            //     return;
            // }

            if(clickInfo.status === 'normal'){
                setReqConnect(true);
                setReqNickname((reqNickname) => req_nickname)
                SetClickInfo({socketid : req_socketId, clickusernickname: req_nickname, status:"calling"})
                setReqId((reqId) => req_socketId)
                peerIds.current = req_peerId
                
                if(window.localStorage.getItem("socketId")){
                    window.localStorage.removeItem('socketId');
                }
                window.localStorage.setItem("socketId", req_socketId);
            } else {
                socket.emit('callingState', req_socketId);
            }
        })

        socket.on("_connectPeer", (req_result, yourPeerId) => {
            if(req_result) {    // 수락
                SetOpenWait(false);
                SetOpenVideoArea(true);
                call(yourPeerId);
            } else {            // 거절
                SetOpenWait(false);
                SetClickInfo({status: "normal"});
                setReqRejectMsg(true);
            }
        })

        // socket.on("_mobileCheck", () => {
        //     // alert("모바일 유저에게는 화상대화를 신청할 수 없습니다.");
        //     SetOpenWait(false)
        //     clearData();
        //     const mobileCancelAlert = document.querySelector('.mobileCancel');
        //     mobileCancelAlert.classList.add('show')
        //     setTimeout(() => {
        //         mobileCancelAlert.classList.remove('show')
        //     }, 2000)
        // })
    }

    // =============================== 1:1 Text Chat =============================
    useEffect(()=> {
        let peerChatContainer = document.getElementById("chatUl");
        var html = peerChatContainer;
        subscribeSocketChat(html);
   
        return () => {
            setMsg('');
            socket.off("_CHECK_MSG2")
            socket.off("_MSG2")
        }
    }, [])

    // 1:1 Text Chat 소켓 묶음
    const subscribeSocketChat = (html) => {
        socket.on('_MSG2', (nickname, msg) => {
            // 받은 글
            const msgEl = document.createElement('li');
            msgEl.classList.add('peerMsg');
            msgEl.innerHTML = `
                <span>${nickname}</span>
                <div>${msg}</div>
            `;
            html.appendChild(msgEl)
            html.scrollTop = html.scrollHeight;
            
        })
        socket.on('_CHECK_MSG2', (flag, msg)=> {
            if (flag) {
                let peerSocketId;
                if(clickInfo.socketid === ''){
                    peerSocketId = window.localStorage.getItem('socketId');
                } else {
                    peerSocketId = clickInfo.socketid;
                }
                socket.emit('MSG2', nickname, msg, peerSocketId);
                // 내가 쓴 글
                const msgEl = document.createElement('li');
                msgEl.classList.add('myMsg');
                // msgEl.style.color = '#fff';
                msgEl.textContent = `${msg}`;
                html.appendChild(msgEl);
                html.scrollTop = html.scrollHeight
                setMsg('');
            } else {
                const msgEl = document.createElement('li');
                msgEl.style.color = '#ff0000';
                msgEl.textContent = `[시스템] 부적절한 단어를 포함하고 있습니다.`;
                html.appendChild(msgEl);
                setMsg('');
            }
        }) 
    }
    // ========================================================================

    // 거절메세지 "상대방이 거절했습니다"
    const handleRejectConnect = () => {
        SetClickInfo({status: "normal"});
        setReqRejectMsg(true);
    }
    
    // 새로고침, 닫기 등 화면 이탈 시 
    const handlereload = () => {
        let peerId;
        let reload = true;

        if(!clickInfo.socketid){
            peerId = window.localStorage.getItem('socketId');
        } else {
            peerId = clickInfo.socketid;
        }

        // 1. 웹 페이지 자체의 변화가 있을경우 = reqFlag > false / openVideoArea > false 
        // 2. 화상채팅 화면이 닫혀있는경우 = reqFlag > false / openVideoArea > false
        
        // 화상채팅을 닫으면서 localStorage의 socketId를 제거했으므로 그 기준으로 1,2 판별
        if(window.localStorage.getItem('socketId') === null){
            peerId = null;
        } 
        // 1. 화면 O / 새로고침 O => socketId O 
        // 2. 화면 X / 새로고침 O => socketId X
        // 3. 대화신청 O / 새로고침 O => socketId O
        if(peerId !== null){
            // 화면 O / 새로고침 O => socketId O 
            // 대화신청 O / 새로고침 O => socketId O    
            socket.emit('chatClose', peerId, reload, reqId);
            SetClickInfo({status: "normal"});
            handleVideoChatExit(true);
        } else {
            socket.emit('chatClose', peerId, reload);
        }
    }

    // ================================peerJS ==============================
    const call = async (remotePeerId) => {
        let constraints = { video: true, audio: { echoCancellation: false } };
        let emptyStream = "";

        try{
            await navigator.mediaDevices.getUserMedia(constraints).then(async (mediaStream) => {
                currentUserVideoRef.current.srcObject = mediaStream;
                currentUserVideoRef.current.playsInline = true;
                currentUserVideoRef.current.muted = true;
                currentUserVideoRef.current.onloadedmetadata = () => {
                    currentUserVideoRef.current.play();
                }            
            });
        } catch(e) {
            const audioTrack = createEmptyAudioTrack();
            const videoTrack = createEmptyVideoTrack({ width:640, height:480 });
            emptyStream = new MediaStream([audioTrack, videoTrack]);
        }

        console.log(emptyStream);

        const calls = peerInstance.current.call(remotePeerId, currentUserVideoRef.current.srcObject? currentUserVideoRef.current.srcObject : emptyStream)  
        
        calls.on('stream', (remoteStream) => {
            remoteVideoRef.current.srcObject = remoteStream
            remoteVideoRef.current.playsInline = true;
            remoteVideoRef.current.onloadedmetadata = () => {
                remoteVideoRef.current.play();
            }
        });
        
      }
    // =====================================================================

    // async function getCameras() {
    //     try {
    //       const camerasSelect = document.getElementById("cameras");
    //       const devices = await navigator.mediaDevices.enumerateDevices();
    //       const cameras = devices.filter((device) => device.kind === "videoinput");
    //       const currentCamera = currentUserVideoRef.current.srcObject.getVideoTracks()[0];
    //       cameras.forEach((camera) => {
    //         const option = document.createElement("option");
    //         option.value = camera.deviceId;
    //         option.innerText = camera.label;
    //         if (currentCamera.label === camera.label) {
    //           option.selected = true;
    //         }
    //         camerasSelect.appendChild(option);
    //       });
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }

    // async function getMedia(deviceId) {
    //     const initialConstrains = {
    //       audio: true,
    //       video: { facingMode: "user" },
    //     };
    //     const cameraConstraints = {
    //       audio: true,
    //       video: { deviceId: { exact: deviceId } },
    //     };
    //     try {
    //       let myStream = await navigator.mediaDevices.getUserMedia(
    //         deviceId ? cameraConstraints : initialConstrains
    //       );
    //       currentUserVideoRef.current.srcObject = myStream;
    //       if (!deviceId) {
    //         await getCameras();
    //       }
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }

    //   async function handleCameraChange() {
    //     const camerasSelect = document.getElementById("cameras");
    //     await getMedia(camerasSelect.value);
    //     if (peerInstance.current) {
    //       const videoTrack = currentUserVideoRef.current.srcObject.getVideoTracks()[0];
    //       debugger
    //       const videoSender = peerInstance.current
    //         .getSenders()
    //         .find((sender) => sender.track.kind === "video");
    //       videoSender.replaceTrack(videoTrack);
    //     }
    //   }

    // 화상대화 종료시 비디오트랙 사용 중지
    const trackStop = () => {
        if(currentUserVideoRef.current.srcObject !== null){
            currentUserVideoRef.current.srcObject.getTracks().forEach(track => {
                track.stop();
            })
        }
        
        if(remoteVideoRef.current.srcObject !== null){
            remoteVideoRef.current.srcObject.getTracks().forEach(track => {
                track.stop();
            })
        }

        SetAudioSwitch(false);
        SetCamaraSwitch(false)
    }

    // 세션에 담은 클릭한 유저정보 초기화
    const clearData = () => {
        if(window.localStorage.getItem("socketId")){
            window.localStorage.removeItem('socketId');
        }
        SetClickInfo({socketid : '', clickusernickname: '', status: 'normal'});
        const chatUl = document.getElementById('chatUl');
        chatUl.innerHTML = '';
    }

    // ============================ handler ==============================
    // 화상대화 > 거절하기 클릭
    const handleClickReject = () => {
        socket.emit('connectPeer', reqId, false)
        setReqConnect(false);
        SetClickInfo({status:"normal"});
    }

    // 화상대화 > 수락하기 클릭
    const handleClickAccept = async () => {
        SetOpenVideoArea(true)
        setReqConnect(false);
        socket.emit('connectPeer', reqId, true ,peerConnectId)
    }

    // 대화를 종료하시겠습니까 > 취소 클릭
    const handleVideoChatExitCancel = () => {
        SetVideoChatCancelBtn(false)
    }

    // 대화를 종료하시겠습니까 > 확인 클릭
    const handleVideoChatExit = (rejectFlag) => {
        if(!rejectFlag){
            let peerId ;
            if(!clickInfo.socketid){
                peerId = window.localStorage.getItem('socketId');
            } else {
                peerId = clickInfo.socketid;
            }
            socket.emit('chatClose', peerId);
        }
        
        SetClickInfo({status: "normal"});
        SetOpenVideoArea(false);
        SetVideoChatCancelBtn(false);
        SetOpenWait(false);
        clearData();
        trackStop();
    }

    // 대화가 종료되었습니다 > 확인 클릭
    const handleAlarmExit = () => {
        setAlarmExit(false);
    }

    // 거절메세지 확인버튼
    const handleReqReject = () => {
        setReqRejectMsg(false);
    }

    // 화상대화 진행중 > 종료 클릭
    const handleClickVideoChatCancel = () => {
        SetVideoChatCancelBtn(true)
    }

    // 1:1 Text Chat onChange
    const handleChangeChatInput = useCallback((e) => {
        setMsg(e.target.value)
    }, [])

    // 1:1 Text Chat onKeyUp
    const handleSubmitChatInput = (e) => {
        if (e.keyCode === 13) {
            if (msg === '') return alert("메세지를 입력해주세요!");
            socket.emit('CHECK_MSG2', msg);
        }
    }

    // 1:1 Text Chat 전송
    const handleClickSubmit = (e) => {
        if (msg === '') return alert("메세지를 입력해주세요!");
        socket.emit('CHECK_MSG2', msg);
    }

    // 화상대화 진행중 > 카메라 끄기:켜기
    const handleClickCameraSwitch = () => {
        SetCamaraSwitch(!cameraSwitch);
    }

    // 화상대화 진행중 > 오디오 끄기:켜기
    const handleClickAudioSwitch = () => {
        SetAudioSwitch(!audioSwitch);
    }

    return(
        <>   
            {
                reqConnect && reqResult === null &&
                isDesktop && 
                <>
                <div className='reqCallContainer' >
                    <span className='reqCallSpan'>{reqNickname}님에게 화상대화 신청이 도착했습니다</span>    
                    <div className='reqCallBtnContainer'>
                        <button className='reqCallRejectBtn' onClick={handleClickReject}>
                            <span className='reqCallReject'>거절하기</span>
                        </button>
                        <button className='reqCallAcceptBtn' onClick={handleClickAccept}>
                        <span className='reqCallAccept'>수락하기</span>
                        </button>
                    </div>
                </div>
                </>
            }  
            {
                reqConnect && reqResult === null &&
                isMobile && <>
                    <div className='reqCallBtnContainer_mobile'>
                        <button className='reqCallRejectBtn_mobile' onClick={handleClickReject}>
                            <span className='reqCallReject_mobile'>거절하기</span>
                        </button>
                        <button className='reqCallAcceptBtn_mobile' onClick={handleClickAccept}>
                        <span className='reqCallAccept_mobile'>수락하기</span>
                        </button>
                    </div>
                    <div className='reqCallContainer_mobile' >
                        
                        <span className='reqCallSpan_mobile'>{reqNickname}님에게 화상대화 신청이 도착했습니다</span>
                    </div>
                </>
            }  
            {
                videoChatCancelBtn ? 
                    <div className='askVideoChatExit'>
                        
                        <div className='askVideoChatExitMsgContainer'>
                            <span className='askVideoChatExitMsg'>대화를 종료하시겠습니까?</span>
                        </div>
                        
                        
                        <div className='askVideoChatExitBtnContainer'>
                            <div className='askVideoChatExitBtnCancel' onClick={handleVideoChatExitCancel}>취소</div>
                            <div className='askVideoChatExitBtnOk' onClick={() => handleVideoChatExit(false)}>확인</div>
                        </div>
                    </div>
                :
                    <>
                    </>
            }

            {
                alarmExit ? 
                    <>
                        <div className="callReadyBox">
                            <div className='videoChatFinish'>
                                <div className='videoChatFinishMsg'>대화가 종료되었습니다</div>
                            </div>  
                            <div className='callReadyCancelBtn' onClick={handleAlarmExit}>확인</div>
                        </div>
                    </>
                :
                    <>
                    </>
            }

            {
                reqRejectMsg ?
                <>
                    <div className="callReadyBox">
                        <div className='videoChatFinish'>
                            <div className='videoChatFinishMsg'>상대방이 거절했습니다</div>
                        </div>  
                        <div className='callReadyCancelBtn' onClick={handleReqReject}>확인</div>
                    </div>
                </>
                :
                <>
                </>
            }
            
            <div className='AlreadyCallContainer'> 
                <span className='AlreadyCall'>{`${socketNickname}님이 다른사람과 이미 통화중입니다`}</span>
            </div>           

            {/* <div className='mobileCancel'>
                <span className='mobileCancelSpan'>모바일 유저에게는 화상대화를 신청할 수 없습니다.</span>
            </div> */}

            <div className='videoArea' style={{display: openVideoArea ? '' : 'none'  }}>
                {connectingMsg && isDesktop ? 
                    <div className='connectingMsgContainer'>
                        <span className='connectingMsg'>화상대화 연결중 ...</span>
                    </div>
                    :
                    <></>
                }
                
                {/* {isDesktop &&
                    <> */}
                        <div className='exitVideoChatContainer' onClick={handleClickVideoChatCancel}>
                            <span className='eixtVideoChat'>종료</span>
                        </div>
                        
                        <div className='videoAreaContainer'>
                            <div className='myVideoContainer mr'>
                                <video ref={remoteVideoRef} id='peerVideo'/>
                                <div className='peerVideoUserName'>{clickInfo.clickusernickname}</div>
                            </div>
                            <div className={"mychatContainer"}>
                                <ul id={"chatUl"} >
                                </ul>
                                <div className="inputContainer">
                                    <input 
                                        type="text" 
                                        name="message" 
                                        id="messageOutLine" 
                                        autoComplete='off' 
                                        placeholder="메세지를 입력해주세요." 
                                        onChange={handleChangeChatInput}
                                        value={msg} 
                                        onKeyUp={handleSubmitChatInput}/>
                                    <div className="sendBtn" onClick={handleClickSubmit}><FontAwesomeIcon style={{fontSize : '24px'}} icon={faPaperPlaneTop} /></div>
                                </div>
                            </div>
                            <div className='myVideoContainer ml'>
                                <video ref={currentUserVideoRef} id='myVideo'/>
                                <div className='myVideoUserName'>{userInfo.nickname}</div>
                                {/* <select id='cameras' onChange={handleCameraChange} style={{margin: '0px 0px 275px 40px', width: '300px'}}></select> */}
                            </div>
                        </div>
                        
                        <div className='switchContainer'>
                            <div className='switchFlex'>
                                <div className='cameraSwitchBtn' onClick={handleClickCameraSwitch}>
                                    {cameraSwitch ? <FontAwesomeIcon color='#fff' icon={faVideo} /> :<FontAwesomeIcon color='#fff' icon={faVideoSlash} />}
                                    <span className='cameraSwitchText'>{cameraSwitch? '카메라 켜기': '카메라 끄기'}</span>
                                </div>
                                <div className='audioSwitchBtn' onClick={handleClickAudioSwitch}>
                                    {audioSwitch ? <FontAwesomeIcon color='#fff' icon={faMicrophone} /> :<FontAwesomeIcon color='#fff' icon={faMicrophoneSlash} />}

                                    <span className='audioSwitchText'>{audioSwitch? '마이크 켜기' : '마이크 끄기'}</span>
                                </div>
                            </div>
                        </div>
                    {/* </>
                } */}

                {/* {isMobile &&
                    <>
                        <div className='videoAreaContainer'>
                        <div className='myVideoContainer'>
                                <video ref={remoteVideoRef} id='peerVideo'/>
                                <div className='peerVideoUserName'>{clickInfo.clickusernickname}</div>
                            </div>    
                            <div className='myVideoContainer'>
                                <video ref={currentUserVideoRef} id='myVideo'/>
                                <div className='myVideoUserName'>{userInfo.nickname}</div>
                            </div>
                        </div>

                        <div className='exitVideoChatContainer' onClick={handleClickVideoChatCancel}>
                            <span className='eixtVideoChat'>종료</span>
                        </div>
                        
                        <div className='switchContainer'>
                            <div className='switchFlex'>
                                <div className='cameraSwitchBtn' onClick={handleClickCameraSwitch}>
                                    <span className='cameraSwitchText'>{cameraSwitch? '카메라 켜기': '카메라 끄기'}</span>
                                </div>
                                <div className='audioSwitchBtn' onClick={handleClickAudioSwitch}>
                                    <span className='audioSwitchText'>{audioSwitch? '마이크 켜기' : '마이크 끄기'}</span>
                                </div>
                            </div>
                        </div>
                        <div className='mobileChatContainer'>
                        <div className={"mychatContainer"}>
                                <ul id={"chatUl"} >
                                </ul>
                                <div className="inputContainer">
                                    <input 
                                        type="text" 
                                        name="message" 
                                        id="message" 
                                        autoComplete='off' 
                                        placeholder="메세지를 입력해주세요." 
                                        onChange={handleChangeChatInput}
                                        value={msg} 
                                        onKeyUp={handleSubmitChatInput}/>
                                    <div className="sendBtn" onClick={handleClickSubmit}><FontAwesomeIcon icon={faPaperPlaneTop} /></div>
                                </div>
                            </div>
                        </div> 
                    </>
                } */}
            </div> 
        </>
        
    );
}

export default WebRtc;

