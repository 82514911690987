import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setDisplayMyPage } from '../redux/BabylonSettings';
import axios from "axios";
import { StoreState } from "../redux";
import styled from "styled-components";
import { setChangeUserNickname } from "../redux/UserInfo";
import { myNickNameChange } from "../babylon/BabylonModule"
import Container from "./Atomic/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import "../css/mypage.css";

const NoticeItem = styled.div`
    width: 100%;
    display: grid;
    padding: 12px 0;
    justify-content: center;
    grid-template-columns: 80% 20%;
    font-family: var(--fn-sans);
    font-size: 18px;
    & > * {
        text-align: center;
    }
    &.owner {
        /* display: flex; */
        /* justify-content: center; */
        grid-template-columns: 80% 20%;
    }
    &._top {
        background-color: rgba(0, 0, 0, 0.1);
    }
    & > .title {
        text-align: center;
    }
    & > button {
        background-color: unset;
        font-family: var(--fn-sans);
        font-size: 18px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 5px 8px;
        width: fit-content;
        margin: 0 auto;
        cursor: pointer;
    }
    & > button:hover {
        border: 1px solid #277ed2;
        background-color: #277ed2;
        color : #fff;
    }
`

interface TALK {
    id : number
    tkq_question : string
    usr_nick_name : string
    answer : REPLY[]
}
interface REPLY {
    id : number,
    tka_answer : string
}

const TableHead = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 10% 90%;
    background-color: rgba(0,0,0,0.3);
    color :#fff;
    font-family: var(--fn-sans);
    font-size: 16px;
    padding: 6px 0;
    & > * {
        text-align: center;
    }
`
const TableBody = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 10% 90%;
    background-color: rgba(0,0,0,0.1);
    color :#000;
    font-family: var(--fn-sans);
    font-size: 16px;
    padding: 6px 0;
    & > * {
        text-align: center;
    }
    & > p {
        padding: 0 12px;
    }
    @media (max-width : 1024px) {
        color : #fff;
    }
`

function Mypage() {
    const { id, nickname, chatbot_id } = useSelector((state: StoreState) => ({
        id: state.UserInfo.id,
        nickname: state.UserInfo.nickname,
        chatbot_id : state.UserInfo.chatbot_id
    }), shallowEqual);
    const ContainerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const SetDisplayMapage = (active: boolean) => dispatch(setDisplayMyPage(active));

    const [talkList, setTalkList] = useState<TALK[]>([])
    useEffect(() => {

        const getData = async () => {
            const talkList : TALK[] = await (await axios.get(`${process.env.REACT_APP_API_URL}/ioted_get_api`, {
                params : {
                    type : 'talk',
                    id : chatbot_id
                }
            })).data;
            const Updated = talkList.map(talk => ({...talk, answer : []}))
            setTalkList(Updated);
        }
        getData()
    }, [])
    const onLeaveClubEditorBoard = (e: React.MouseEvent<HTMLDivElement>) => {
        if (ContainerRef.current === e.target) {
            SetDisplayMapage(false)
        }
    }
    /** 공지사항 클릭하면 나올 idx */
    const [activedNoticeIdx, setActivedNoticeIdx] = useState<null | number>(null);
    /** 공지사항 상세보기 핸들러 */
    const onToggleFileHandler = (idx : number, id : number) => {
        if (talkList[idx].answer.length > 0) {
            setActivedNoticeIdx(idx)
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/ioted_get_api`, {
                params : {
                    type: 'reply',
                    id
                }
            }).then(res => {
                setTalkList(prev=> prev.map(talk => {
                    if (talk.id === id) {
                        return {
                            ...talk,
                            answer : res.data
                        }
                    } else {
                        return talk
                    }
                }))
                setActivedNoticeIdx(idx)
            })
        }
    }
    

    return (
        <Container onClick={onLeaveClubEditorBoard} Ref={ContainerRef}>
            <div className="tab-menu pro-lec">
                <button className="close-board-btn" onClick={() => SetDisplayMapage(false)}><FontAwesomeIcon icon={faX} className="fa-lg" /></button>
                <div className="tab-inner-wrap">
                    <div className="tab-inner active">
                        <div className="stu-file">
                            <NoticeItem className="owner _top">
                                <p className="title">톡리스트</p>
                                <p>작성자</p>
                                {/* <p>날짜</p> */}
                            </NoticeItem>
                            <div className="stu-file-inner">
                                <ul className="stu-file-item">
                                    {
                                        talkList.map((data,i) => {
                                            return (
                                                <li key={i} className={activedNoticeIdx === i ? "stu-file-list active" : "stu-file-list"} onClick={() => onToggleFileHandler(i, data.id)} >
                                                    <NoticeItem>
                                                        <p>{data.tkq_question}</p>
                                                        <p>{data.usr_nick_name}</p>
                                                        {/* <p>{data.reg_date.split(' ')[0]}</p> */}
                                                    </NoticeItem>
                                                    <div className="stu-desc">
                                                        <TableHead>
                                                            <span>순번</span>
                                                            <p>답변</p>
                                                        </TableHead>
                                                        {
                                                            data.answer.map((talk,i) =>{
                                                                return (
                                                                    <TableBody key={i}>
                                                                        <span>{i+1}</span>
                                                                        <p>{talk.tka_answer}</p>
                                                                    </TableBody>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default React.memo(Mypage);