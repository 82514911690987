import '@babylonjs/loaders';
import World from "./World";
import Inventory from "../components/Inventory";
import StateButtons from "../components/StateButtons";
import Loading from '../pages/Loading';
import { useEffect, useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as NG from "./NAVIGATOR"

import UserToolBox from '../babylon/UserToolBox';
import RtcReadyCall from '../babylon/RtcCallReady';
import WebRtc from '../babylon/WebRtc';
import { setPeerConnectId, setPeer } from '../redux/BabylonSettings';
import Peer from 'peerjs';


function BabylonSet(value) {
    const allReady = useSelector((state) => (state.BabylonSettings.allReady), shallowEqual);
    const canvas = useRef(null);
    const [load2, setLoad2] = useState(false);

    const dispatch = useDispatch();
    const SetPeerConnectId = (myPeerId) => dispatch(setPeerConnectId(myPeerId))
    const SetPeer = (peerObj) => dispatch(setPeer(peerObj))
    const {clickUser, openWait, webRtcComponent} = useSelector((state) => ({
        clickUser : state.BabylonSettings.clickUser,
        openWait : state.BabylonSettings.openWait,
        webRtcComponent : state.BabylonSettings.webRtcComponent
    }), shallowEqual);

    useEffect(() => {
        async function init() {
            if (canvas.current && !load2) {
                canvas.current.style.position = 'absolute';
                canvas.current.style.width = '100%';
                canvas.current.style.height = '100%';    
                let isRecast = await NG.createNav()
                if (isRecast === true) {
                    setLoad2(true)
                } 
            }
        }

        const peer = new Peer({config: {
            'iceServers': [
                // {
                // urls: [
                //     "stun:stun.l.google.com:19302",
                //     "stun:stun1.l.google.com:19302",
                //     "stun:stun2.l.google.com:19302",
                //     "stun:stun3.l.google.com:19302",
                //     "stun:stun4.l.google.com:19302",
                //   ]
                // },
                {
                    urls: "turn:115.85.183.81:3478?transport=tcp",
                    username: "coffepictturn",
                    credential: "vlrxmroqkf1004!"
                }
                // {
                //     urls: "turn:openrelay.metered.ca:80",
                //     username: "openrelayproject",
                //     credential: "openrelayproject",
                // },
                // {
                //     urls: "turn:openrelay.metered.ca:443",
                //     username: "openrelayproject",
                //     credential: "openrelayproject",
                // }
          ]
        }});
        // const peer = new Peer()
        peer.on('open', (id) => {
            SetPeer(peer);
            SetPeerConnectId(id)
            init();
        });

        // init();
        return () => {
            canvas.current = null;
            NG.clearNavPlugin();
            setLoad2(false);
        }
    }, [])
    return (
        <>
            {!allReady && <Loading />}            
            {allReady && <StateButtons />}
            {allReady && <Inventory />}

            {clickUser && <UserToolBox />}
            {openWait && <RtcReadyCall />}
            {webRtcComponent && <WebRtc />}

            <canvas id='babylonCanvas' ref={canvas}></canvas>
            {load2 && <World canvas={canvas.current} value={value}/>}
        </>
    );
}

export default BabylonSet;
