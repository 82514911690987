import { useEffect } from "react";

// redux
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setUsers, addUser, deleteUser } from '../redux/OtherUsers'
import { setImportType } from "../redux/BabylonSettings";

// fc
import { socket, Socket_disconnection } from '../middleware/Socket'
import OtherUser from "./OtherUser";

function OtherUsers() {
    const isReady = useSelector(state => state.BabylonSettings.allReady, shallowEqual);
    const users = useSelector(state => state.OtherUsers);
    const dispatch = useDispatch();
    const SetUsers = (users) => dispatch(setUsers(users));
    const AddUser = (user) => dispatch(addUser(user));
    const DeleteUser = (id) => dispatch(deleteUser(id));
    const SetImportType = (importtype) => dispatch(setImportType(importtype));
    
    useEffect(() => {
        if (!isReady) {
            socket.once("ERROR_ENTER", (callback) => {
                if (!callback) Socket_disconnection();                
            })
            socket.once("ROOM_INFO", ({ roomName, userList }) => {
                if (userList.length > 0) {
                    SetUsers(userList);
                } else {
                    SetImportType('add_user')
                }
            })
        }else{
            socket.on('_ENTER_USER', (data) => {
                AddUser(data);
            })
            socket.on('OUT_USER', ({ id }) => {
                DeleteUser(id)                
            })
        }
    }, [isReady])

    return (
        <>
            {
                isReady && users.length > 0
                    ? users.map((userData) => (
                        <OtherUser key={userData.id} userData={userData} />
                    ))
                    : <></>
            }
        </>
    );
}


export default OtherUsers;

