import styled from 'styled-components'
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import "./Loading.css";

const LodingWrapper = styled.div`
  width: 100%;
  height : 100%;
  position: fixed;
  top : 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
`;

const LodingVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function Loding() {
  const Video = useRef() as React.MutableRefObject<HTMLVideoElement>
  const location = useLocation();
  const {allReady}= useSelector((state : any)=>({
    allReady : state.BabylonSettings.allReady,
}), shallowEqual);
  const [src, setSrc] = useState('default');
  useEffect(()=>{
    let path = location.pathname.split('/')[1]
    document.title = "로딩중 | 아이오테드 메타버스"

    return () => {
      if (path === "future") {
        setSrc('future')
        document.title = "100년 후 미래 / 아이오테드 메타버스"
      }else if (path === "present") {
        setSrc('present')
        document.title = "현재 / 아이오테드 메타버스"
      } else {
        setSrc('future')
      }
    }
  }, [])

  return (
    // <div className={!allReady ? 'loadingWrapper open' : 'loadingWrapper'}>
    //   <video className='loadingVideo' src={!allReady ? `/assets/video/${src}.mp4` : ""} ref={Video} muted playsInline autoPlay loop></video>
    // </div>
    <>
      {!allReady && <LodingWrapper>
        {src && <LodingVideo ref={Video} src={`/assets/video/intro.mp4`} muted playsInline autoPlay loop></LodingVideo>}
      </LodingWrapper>}
    </>
  );
}

export default Loding;