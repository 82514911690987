import React from 'react';
import styled from 'styled-components';

interface Prop {
    children?: React.ReactNode;
    Ref : React.RefObject<HTMLDivElement>
    onClick : (e : React.MouseEvent<HTMLDivElement>) => void
    color ?: "black"
}

function Container({onClick, Ref, color ,children} : Prop) {
  return (
    <_Container color={color ? color : ''} ref={Ref} onClick={onClick}>
      {children}
    </_Container>
  );
}

export default Container;

interface Props {
  color : string
}
const _Container = styled.div<Props>`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    background-color: ${props=> props.color === "black" ? 'rgba(0,0,0,0.4)' : ''}
`