export enum UserInfoTypes {
    SET_INIT_STATE = 'SET_INIT_STATE',
    SET_USER_INFO = "SET_USER_INFO",
    SET_Avatar_Meshes = "SET_Avatar_Meshes",
    SET_SOCKETID = "SET_SOCKETID",
    SET_CURRENT_ITEMS = "SET_CURRENT_ITEMS",
    SET_ORIGIN_ITEMS = "SET_ORIGIN_ITEMS",
    CHANGE_CAMPUS = 'CHANGE_CAMPUS',
    SET_FRIENDS_LIST = 'SET_FRIENDS_LIST',
    SET_CHANGE_FRIENDS_STAT = "SET_CHANGE_FRIENDS_STAT",
    SET_ADD_FRIEND = "SET_ADD_FRIEND",
    SET_MAIL_LIST = "SET_MAIL_LIST",
    SET_READ_MAIL = "SET_READ_MAIL",
    SET_NEW_MAIL = "SET_NEW_MAIL",
    SET_CONIN = 'SET_CONIN',
    SET_REMOVE_MAIL = "SET_REMOVE_MAIL",
    SET_STATUS_MESSAGE = "SET_STATUS_MESSAGE",
    SET_CHANGE_NICKNAME = "SET_CHANGE_NICKNAME"
}

export enum BabylonSettingTypes {
    SET_INIT_BABYLON_SET = 'SET_INIT_BABYLON_SET',
    SET_ALL_READY = "SET_ALL_READY",
    SET_IMPORT_TYPE = "SET_IMPORT_TYPE",
    SET_VISIBLE_INVENTORT = 'SET_VISIBLE_INVENTORT',
    SET_NAV_STATE = 'SET_NAV_STATE',
    SET_CLICK_MAP = "SET_CLICK_MAP",
    SET_CLICK_CAMPUS = 'SET_CLICK_CAMPUS',
    SET_CLICK_CLASSROOM = "SET_CLICK_CLASSROOM",
    SET_DISPLAY_NPC = 'SET_DISPLAY_NPC',
    SET_DISPLAY_MYPAGE = 'SET_DISPLAY_MYPAGE',
    SET_PORTAL_ACTION = 'SET_PORTAL_ACTION',
    SET_DANCE_VALUE = "SET_DANCE_VALUE",
    SET_CAMERA_ACTION_ANI = "SET_CAMERA_ACTION_ANI",
    SET_GUEST_BOOK = "SET_GUEST_BOOK",
    SET_CALENDER = "SET_CALENDER",
    SET_DISPLAY_CHAT = "SET_DISPLAY_CHAT",
    SET_DISPLAY_EMOTIONS = "SET_DISPLAY_EMOTIONS",
    SET_DISPLAY_SETTINGS = "SET_DISPLAY_SETTINGS",
    SET_PLACE_SUB_NAME = "SET_PLACE_SUB_NAME",
    SET_FISHING_MODE = "SET_FISHING_MODE",
    SET_IS_START_FISHING = "SET_IS_START_FISHING",
    SET_FISH_LISTS = "SET_FISH_LISTS",
    SET_FISHING_SUCCESS = "SET_FISHING_SUCCESS",
    SET_DISPLAY_FRIENDS = "SET_DISPLAY_FRIENDS",
    SET_DISPLAY_MAIL = "SET_DISPLAY_MAIL",
    SET_DISPLAY_FAME = "SET_DISPLAY_FAME",
    SET_DISPLAY_GAME_TUTORIAL = "SET_DISPLAY_GAME_TUTORIAL",
    SET_COUNT_GROW_TREE = "SET_COUNT_GROW_TREE",
    SET_DISPLAY_TREE = "SET_DISPLAY_TREE",
    SET_TO_STORE_SEND_MAIL = "SET_TO_STORE_SEND_MAIL",
    SET_DISYPLAY_CAFE_INFO = "SET_DISYPLAY_CAFE_INFO",
    SET_DISPLAY_GALLERY = "SET_DISPLAY_GALLERY",
    SET_TOTAL_GAME_MODE = "SET_TOTAL_GAME_MODE",
    SET_CLICK_USER = "SET_CLICK_USER",
    SET_ClICK_INFO = "SET_ClICK_INFO",
    SET_OPEN_WAIT = "SET_OPEN_WAIT",
    SET_OPEN_VIDEO_AREA = "SET_OPEN_VIDEO_AREA",
    SET_CONNECTING_MSG = "SET_CONNECTING_MSG",
    SET_VIDEO_CHAT_CANCEL_BTN = "SET_VIDEO_CHAT_CANCEL_BTN",
    SET_CAMERA_SWITCH = "SET_CAMERA_SWITCH",
    SET_AUDIO_SWITCH = "SET_AUDIO_SWITCH",
    SET_PEERCONNECT_ID = "SET_PEERCONNECT_ID",
    SET_PEER = "SET_PEER",
    SET_PEERNICKNAME = "SET_PEERNICKNAME",
    SET_REQ_CONNECT = "SET_REQ_CONNECT",
    SET_WEBRTC_COMPONENT = "SET_WEBRTC_COMPONENT"
}

export enum OtherUserTypes {
    // SET_INIT_STATE = 'SET_INIT_STATE',
    SET_USERS = "SET_USERS",
    ADD_USER = 'ADD_USER',
    DELETE_USER = 'DELETE_USER',
    UPDATE_POSITION = 'UPDATE_POSITION',
    CLEAR_USERS = "CLEAR_USERS",
}
