import '../css/index.css'
import {Link, useNavigate} from 'react-router-dom';
import logo from '../assets/img/logo.webp';
import symbol from '../assets/img/symbol.webp';
import kakao from '../assets/img/kakao_login.webp'
import naver from '../assets/img/naver_login.webp'
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from '../redux';
import axios from 'axios';
import { getCookie, removeCookie, setCookie } from '../middleware/Cookie';
import { setUserInfo, Info } from '../redux/UserInfo';
import styled from 'styled-components';
import Ioted from '../components/Ioted';

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.73);
    /* background-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.09) 135%); */

`

const Video = styled.video`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    object-fit: cover;
    top: 0;
    left: 0;
`

const Back = styled.div`
    width: 90%;
    max-width: 864px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    & > img {
        width: 100%;
    }
    @media (max-width : 600px) {
        top: 20%;
        transform: translateX(-50%);
    }
`

const ContensContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    padding-left: 15%;
    z-index: 2;
    @media (max-width : 1080px) {
        padding-left: 10%;
        /* justify-content: flex-end; */
    }
    @media (max-width : 880px) {
        padding-left: 5%;
        align-items: center;
    }
`

const Title = styled.h1`
    font-family: var(--fn-sans);
    font-weight: 700;
    letter-spacing: -2.4px;
    color : #fff;
    font-size: 40px;
    line-height: 1.45;
    text-align: left;
    margin-bottom: 30px;
    @media (max-width : 880px) {
        text-align: center;
    }
`

const Description = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 180px;
    & > p {
        font-family: var(--fn-sans);
        color : #fff;
        letter-spacing: -1.26px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        line-height: 1.5;
    }
    & > p >b {
        font-weight: 700;
    }
    & > p >b::before {
        content: '';
        margin-left: 5px;
        display: inline-block;
    }
    @media (max-width : 880px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10%;
    }
`

const InputContainer = styled.label`
    width: 90%;
    max-width: 536px;
    height: 43.8px;
    display: flex;
    flex-direction: column;
    color : #fff;
    &:last-of-type {
        margin-top: 32px;
    }
    & > span {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        margin-bottom: 3px;
    }
    & > input {
        width: 100%;
        height: 100%;
        outline: none;
        background-color: unset;
        border: none;
        border-bottom: 2px solid #808495;
        font-size: 18px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        padding-left: 5%;
        padding-bottom: 3px;
        color : #fff;
    }
    & > input:focus {
        border-bottom: 2px solid #fff;
    }
    @media (max-width : 600px) {
        &:last-of-type {
            margin-top: 8%;
        }
    }
`

const SubmitButton = styled.button`
    width: 260px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    color : #fff;
    border-radius: 25px;
    background-color: #2a1558;
    margin-top: 62.2px;
    margin-bottom: 23px;
    @media (max-width : 1024px) {
        margin-top: 5%;
    }
    @media screen and (max-width : 600px) {
        margin-top: 10%;
        width: 70%;
    }
    @media screen and (max-width : 717px) and (max-height : 512px) {
        margin-top: 5%;
    }
`


declare global {
    interface Window {
        naver : any
    }
}
interface USER {
    ioted_id : number
    campus : string
    cloth : string
    eyebrow : string | null
    face : string | null
    hair : string
    id : number
    idx : number
    last_login_date : null | string
    last_login_ip : null | string
    mobile : null | string
    name : string | null
    nick_name : string
    position_x : string
    position_y : string
    position_z : string
    reg_date : string
    rotation_x : string
    rotation_y : string
    rotation_z : string
    shoes : string
    token : string
    use_ty : null | string
}

interface CHATBOT {
    cbt_comment_count : number
    cbt_create_date : string
    cbt_intro : string
    cbt_is_private : number
    cbt_language_type : number
    cbt_like_count : number
    cbt_name : string
    cbt_profile_url : string
    cbt_talk_count : number
    cbt_type : number
    cbt_voice_type : number
    id : number
    user_id : number
}

const Index = ()=> {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = getCookie('token');
    const redux_token = useSelector((state : StoreState) => state.UserInfo.token);
    const campus = useSelector((state : StoreState) => state.UserInfo.campus);
    const SetUserInfo = (userinfo: Info) => dispatch(setUserInfo(userinfo));
    const [userData,setUserData] = useState<USER | null>(null);
    const [isLogined, setIsLogined] = useState(false);
    const [isSetChatBotId, setIsSETChatBotId] = useState(false);
    const [chatbotList, setChatbotList] = useState<CHATBOT[]>([]);
    const [selectedChatBotId, setSelectedChatBotId] = useState(0)
    useEffect(()=> {
        if (token && redux_token && campus) {
            axios.post(`${process.env.REACT_APP_API_URL}/green_select`).then(res=> {
                if (res.data.cnt >= 30) {
                    navigate(`/present`)
                } else {
                    navigate(`/future`)
                }
            })
        }
        if (token && redux_token === '') {
            axios.post(`${process.env.REACT_APP_API_URL}/green_select`).then(res=> {
                axios.post(`${process.env.REACT_APP_API_URL}/check_token`, {
                    token,
                })
                    .then((response) => {
                        // 성공일 때
                        // 성공하면 리덕스 상태 업데이트
    
                        // 실패일 때
                        // 쿠키 강제로 삭제 후 로그인 페이지로 리다이렉트
                        if (response.data.callback === 500 || response.data.callback === 501) {
                            removeCookie('token');
                            navigate('/')
                        } else if (response.data.callback === 200) {
                            const {data} = response.data
                            const role = 1
                            let campus = ""
                            if (res.data.cnt >= 30) {
                                campus = 'present'
                            } else {
                                campus = 'future'
                            }
                            SetUserInfo({
                                id: data.id,
                                nickname: data.nick_name,
                                chatbot_id : data.current_chatbot_id,
                                token: data.token,
                                ioted_id : data.ioted_id,
                                items: {
                                    eyebrow : data.eyebrow,
                                    hair: data.hair,
                                    cloth: data.cloth,
                                    shoes: data.shoes
                                },
                                position: {
                                    x: "-24.579",
                                    y: "-0.564",
                                    z: "-1.893"
                                },
                                rotation: {
                                    x: "0",
                                    y: '84',
                                    z: "0"
                                },
                                campus: campus,
                                role : role,
                                coin : data.coin,
                                status_message : data.status_message ? data.status_message : ''
                            })
                            navigate(`/${campus}`)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
                
                
            })
        }
    },[token, redux_token, campus])
    
    const [inputs, setInputs] = useState({
        id : '',
        pwd : '',
    })
    const {id, pwd} = inputs;
    
    /**
     * id, pwd 체인지 이벤트 핸들러
     * @param e React.ChangeEvent
     */
    const onChangeInputs = (e : React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name
        setInputs({
            ...inputs,
            [name] : e.target.value
        })
    }
    /**
     * 로그인 ㅂ튼
     */
    
    const onSubmitHandler = () => {
        if (id === "") return window.alert("Please enter your ID");
        if (pwd === "") return window.alert("Please enter a password");
        axios.post(`${process.env.REACT_APP_API_URL}/new_login`, {usr_login_id : id, usr_login_pw : pwd}).then((res : any)=> {
            if (res.data.text === "로그인 정보가 올바르지 않습니다." || res.data.text === "API 오류 발생")  {
                window.alert("아이디 혹은 비밀번호가 올바르지 않습니다.");
            } else {
                setIsLogined(true)
                setUserData(res.data);

            }
            
            // const userData = res.data;
            
            

        }).catch((e)=> {
            // throw console.error("관리자에게 문의해주세요.", e);
            return window.alert("Your login information is incorrect");
        })
    }
    useEffect(()=> {
        if (isLogined && !isSetChatBotId &&  userData?.ioted_id) {
            axios.get(`${process.env.REACT_APP_API_URL}/ioted_get_api`, {

                params : {
                    type : 'chatbot',
                    id : userData?.ioted_id
                }
            }).then((res)=> {
                setIsSETChatBotId(true)
                setChatbotList(res.data)
            })
        }
    },[isLogined, isSetChatBotId, userData])
    const onChangeChatbotHandler = (e : React.ChangeEvent<HTMLInputElement>) => {
        setSelectedChatBotId(Number(e.target.value))
    }
    const onSubmitLoginHandler = () => {
        const now = new Date();
            const cookieExpires = new Date(now.setHours(now.getHours() + 2));
        setCookie('token', userData!.token, {expires : cookieExpires, path: '/', sameSite: "strict"});
        SetUserInfo({
            id : userData!.id,
            nickname : userData!.nick_name ? userData!.nick_name : '',
            token : userData!.token ? userData!.token : '',
            chatbot_id : selectedChatBotId,
            ioted_id : userData!.ioted_id,
            items : {
                hair : userData!.hair ? userData!.hair : 'hair_0',
                cloth : userData!.cloth ? userData!.cloth : 'cloth_0',
                shoes : userData!.shoes ? userData!.shoes : 'shoes_0',
                eyebrow : userData!.eyebrow ? userData!.eyebrow : 'eyebrow_1'
            },
            position: {
                x: userData!.position_x,
                y: userData!.position_y,
                z: userData!.position_z,
            },
            rotation: {
                x: userData!.rotation_x,
                y: userData!.rotation_y,
                z: userData!.rotation_z,
            },
            campus : userData!.campus,
            role : 1,
            coin : 0,
            status_message : '',
        })
        axios.post(`${process.env.REACT_APP_API_URL}/chatbot_update`, {current_chatbot_id : selectedChatBotId, user_id : userData!.id})
        axios.post(`${process.env.REACT_APP_API_URL}/green_select`).then(res=> {
            if (res.data.cnt >= 30) {
                navigate('/present')
            } else {
                navigate('/future')
            }
        })
    }
    return(
        <>
        <Video src='/assets/video/intro.mp4'  loop muted autoPlay playsInline/>
        <Container>
            <ContensContainer>
                <Title>
                아이오테드<br/>
                메타버스
                </Title>                
                <Description>
                    <p>아이오테드에</p>
                    <p>오신 여러분을 환영합니다</p>
                </Description>
                <InputContainer>
                    <span>ID</span>
                    <input type="text" name="id" value={id} onChange={onChangeInputs} />
                </InputContainer>
                <InputContainer>
                    <span>Password</span>
                    <input type="password" name="pwd" value={pwd} onChange={onChangeInputs} />
                </InputContainer>
                <SubmitButton onClick={onSubmitHandler}>Login</SubmitButton>
            </ContensContainer>
        </Container>
        {isSetChatBotId && <Ioted chatbots={chatbotList} onChange={onChangeChatbotHandler} checkedValue={selectedChatBotId} onSumbit={onSubmitLoginHandler} />}
        </>
    );
}
export default Index;