import * as BABYLON from '@babylonjs/core';
import * as MAT from '@babylonjs/materials'
import { AdvancedDynamicTexture, TextBlock, Image } from '@babylonjs/gui'
import { getNavPlugin } from './NAVIGATOR';
import { socket, Socket_disconnection } from '../middleware/Socket';
import axios from "axios";

//nameSpace
//---------------------------------------------------------------------------------------------------------------------------------------------------------
let engine, scene, camera, camera2 = null;
let directionalLight, hemisphericLight, shadowGenerator, waterMaterial, skyBoxDay = null;
let targetCube, hairArr, clothArr, shoesArr, pointer, pointerAni, pin = null;
let mainPlayer, AvatarBodyContainer, playerSkeleton, tempPlayerJoint, playerHeight, playerFace, StageName = null;
let playerAni = null;
let playerAnis = [];
let leftParticleBox, rightParticleBox, navMesh, crowd = null;
let redux_id = null;
let portals = [];
let portalAnis = [];
let portalHitBoxes = [];
let isHitPortals = [false];
let particleSystems = [];
let agents = [];
let faceEmotion = [];
let isClickAble = true;
let checkDistance = 0;
let checkPointVector3 = null;
let isMoveAble, cameraAniValue = false;
let footPrintDelay = 380;
let miniMapArr = [];
let npc = [];
let moveX, moveZ, radiusValue, betaValue, targetValueX, targetValueY, rotationValue, targetValue = 0;
let isAllReady = false;

let clickUser = false;

let myNickNameText = null;
let testWalkPoint = 0;
let bubbleStateOn = false;
let bubbleState, bubbleStateInterval, bubbleUpperState, bubbleLowerState = null;
let bubbleStateUrl = [
    "./assets/model/dressUpBubble.png", //test cloth
    "./assets/model/speechBubble.png" //test talk
]
let eyebrowMat = null;
let eyebrowHexColors = [
    "#000000",
    "#6A392A",
    "#FCEF00",
    "#FC9200",
    "#FF9F9F",
    "#5885E8",
    "#D198E8"
];

//fc
let minimapRotation = null;
//---------------------------------------------------------------------------------------------------------------------------------------------------------



//graphic option
//---------------------------------------------------------------------------------------------------------------------------------------------------------
let shadowOptimizeValue = null;
//---------------------------------------------------------------------------------------------------------------------------------------------------------



//parameter etc
//---------------------------------------------------------------------------------------------------------------------------------------------------------
const agentParams = {
    radius: 0.1,
    height: 2,
    maxAcceleration: 130.0,
    maxSpeed: 6.0,
    collisionQueryRange: 0.1,
    pathOptimizationRange: 0.0,
    separationWeight: 10.0
};
//---------------------------------------------------------------------------------------------------------------------------------------------------------




//function
//---------------------------------------------------------------------------------------------------------------------------------------------------------
let resize = () => {
    scene.getEngine().resize(true);
};
//---------------------------------------------------------------------------------------------------------------------------------------------------------




//babylon.js normal setting
//---------------------------------------------------------------------------------------------------------------------------------------------------------

export const createEngine = (canvas, options) => {
    if (!engine) engine = new BABYLON.Engine(canvas, true, { ...options });
};
export const createScene = (options, offlineValue) => {
    scene = new BABYLON.Scene(engine, { ...options })
    scene.blockfreeActiveMeshesAndRenderingGroups = true;
    scene.collisionsEnabled = true;
    if (offlineValue) {
        engine.enableOfflineSupport = true;
        engine.disableManifestCheck = false;
        BABYLON.Database.IDBStorageEnabled = true;
    } else {
        engine.enableOfflineSupport = false;
        engine.disableManifestCheck = true;
        BABYLON.Database.IDBStorageEnabled = false;
    }
    scene.clearCachedVertexData();
    scene.cleanCachedTextureBuffer();
    scene.fogEnabled = true;
    scene.fogMode = 2
    scene.fogDensity = 0.0005;
    scene.fogColor = BABYLON.Color3.FromHexString('#CFF1FF')
    scene.clearColor = new BABYLON.Color4(0, 0, 0, 255);
    scene.blockMaterialDirtyMechanism = true;
    scene.autoClear = true;
    scene.autoClearDepthAndStencil = true;

    window.addEventListener("resize", (resize));
};
export const normalBabylonSetup = (multiStageNameValue, isShadow, skyLikCheckValue) => {
    StageName = multiStageNameValue;
    shadowOptimizeValue = isShadow;

    camera = new BABYLON.ArcRotateCamera("MainCamera", 1.6, 1.4, 8, new BABYLON.Vector3(0, -0.75, 0), scene);
    camera.angularSensibilityX = 2500;
    camera.angularSensibilityY = 5000;
    camera.panningSensibility = 0;
    camera.pinchDeltaPercentage = 0.01;
    camera.wheelDeltaPercentage = 0.01;
    camera.lowerBetaLimit = 0.7;
    camera.upperBetaLimit = 1.4;
    camera.lowerRadiusLimit = 3;
    camera.upperRadiusLimit = 20;
    camera.attachControl(true);

    camera2 = new BABYLON.FreeCamera("camera2", new BABYLON.Vector3(4.45, 1.08, -4.71), scene);
    camera2.rotation = new BABYLON.Vector3(BABYLON.Tools.ToRadians(2.12), BABYLON.Tools.ToRadians(-53.81), 0)
    scene.activeCamera = camera;


    directionalLight = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(4.636, 0.162, -5.103), scene);
    directionalLight.direction = new BABYLON.Vector3(0, -10, -5)
    directionalLight.intensity = 3;
    directionalLight.diffuse = new BABYLON.Color3.FromHexString('#FFF2D4');
    directionalLight.specular = new BABYLON.Color3(1, 1, 1);
    directionalLight.shadowMinZ = 10;
    directionalLight.shadowMaxZ = 130;

    directionalLight.position.x = 4.68;
    directionalLight.position.z = -5.1;

    hemisphericLight = new BABYLON.HemisphericLight("HemisphericLight", new BABYLON.Vector3(0, 10, 0), scene);
    hemisphericLight.diffuse = BABYLON.Color3.FromHexString('#FFE2E2');
    hemisphericLight.groundColor = BABYLON.Color3.FromHexString("#464646");
    hemisphericLight.intensity = 0.5;

    if (shadowOptimizeValue) {
        shadowGenerator = new BABYLON.CascadedShadowGenerator(1024, directionalLight, true);
        shadowGenerator.debug = false;
        shadowGenerator.stabilizeCascades = false;
        shadowGenerator.cascadeBlendPercentage = 0;
        shadowGenerator.depthClamp = false;
        shadowGenerator.autoCalcDepthBounds = false;
        shadowGenerator.shadowMaxZ = 80;
        shadowGenerator.bias = 0.008;
        shadowGenerator.normalBias = 0;
        shadowGenerator.darkness = 0;
        shadowGenerator.transparencyShadow = false;

        shadowGenerator.numCascades = 2;
        shadowGenerator.lambda = 0;
        shadowGenerator.usePercentageCloserFiltering = true;
        shadowGenerator.filteringQuality = BABYLON.ShadowGenerator.QUALITY_MEDIUM;
        shadowGenerator.stabilizeCascades = true;
    
    }
        scene.imageProcessingConfiguration.contrast = 1.2;
        scene.imageProcessingConfiguration.exposure = 1.1;
        scene.imageProcessingConfiguration.vignetteEnabled = true;
        scene.imageProcessingConfiguration.vignetteWeight = 0.6;
        scene.imageProcessingConfiguration.vignetteCameraFov = 0.7;
        scene.imageProcessingConfiguration.vignetteStretch = 0.5;


    let skyBoxLink = "./assets/Tskybox/skybox/Sunset/02_Sunset";
    if(skyLikCheckValue){     
        let gl = new BABYLON.GlowLayer("glow", scene, {
            mainTextureSamples: 2
        })
        gl.blurKernelSize = 16;
        gl.intensity = 0.6;

        skyBoxDay = BABYLON.Mesh.CreateBox("skyBoxDay", 900.0, scene);    
        skyBoxDay.position = new BABYLON.Vector3(0, 5, 0)
        skyBoxDay.rotation.y = BABYLON.Tools.ToRadians(94.6)
        skyBoxDay.scaling.z = 0.6;
        skyBoxDay.applyFog = false;
    
        let frontTextureExt = "_pz.png";
        let backTextureExt = "_nz.png";
        let leftTextureExt = "_px.png";
        let rightTextureExt = "_nx.png";
        let upTextureExt = "_py.png";
        let downTextureExt = "_ny.png";
    
        let extensions = [leftTextureExt, upTextureExt, frontTextureExt, rightTextureExt, downTextureExt, backTextureExt];
    
        let skyBoxMaterial = new BABYLON.StandardMaterial("skyBoxDayMat", scene);
        skyBoxMaterial.backFaceCulling = false;
        skyBoxMaterial.reflectionTexture = new BABYLON.CubeTexture(skyBoxLink, scene, extensions, false);
        skyBoxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
        skyBoxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        skyBoxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        skyBoxMaterial.emissiveColor = new BABYLON.Color3.FromHexString("#0A0A0A");
        skyBoxMaterial.disableLighting = true;
        skyBoxDay.material = skyBoxMaterial;   
    }else{
        skyBoxLink = "./assets/Tskybox/skybox/Day/01/01_Day";
        skyBoxDay = BABYLON.Mesh.CreateBox("skyBoxDay", 900.0, scene);
        skyBoxDay.position = new BABYLON.Vector3(0, -50, 0)
        skyBoxDay.rotation.y = BABYLON.Tools.ToRadians(278);    
    
        let skyBoxMaterial = new BABYLON.StandardMaterial("skyBoxDay", scene);
        skyBoxMaterial.backFaceCulling = false;
        skyBoxMaterial.reflectionTexture = new BABYLON.CubeTexture(skyBoxLink, scene);
        skyBoxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
        skyBoxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
        skyBoxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        skyBoxMaterial.disableLighting = true;
        skyBoxDay.material = skyBoxMaterial;
    }

    targetCube = BABYLON.MeshBuilder.CreateBox("targetCubeInter", { size: 1, height: 1 }, scene)
    targetCube.isVisible = false;
    targetCube.position.y = 1;

    hairArr = new BABYLON.TransformNode("MTMhair", scene);
    clothArr = new BABYLON.TransformNode("MTMcloth", scene);
    shoesArr = new BABYLON.TransformNode("MTMshoes", scene);

    leftParticleBox = new BABYLON.MeshBuilder.CreateBox("leftParticleBox", { size: 1, height: 1 }, scene);
    leftParticleBox.isVisible = false;
    leftParticleBox.position.x -= 0.1;
    leftParticleBox.position.y += 0.08;

    rightParticleBox = new BABYLON.MeshBuilder.CreateBox("RightParticleBox", { size: 1, height: 1 }, scene);
    rightParticleBox.isVisible = false;
    rightParticleBox.position.x += 0.1;
    rightParticleBox.position.y += 0.08;

    engine.runRenderLoop(() => {
        scene.render();
    });
};

//---------------------------------------------------------------------------------------------------------------------------------------------------------



//world player setup
//---------------------------------------------------------------------------------------------------------------------------------------------------------
export const playerSetup = (posX, posY, posZ, rotY, eyebrowColor, container) => {
    AvatarBodyContainer = container;
    container.meshes[0].name = "PlayerBody";
    container.meshes[0].position = new BABYLON.Vector3(posX, posY, posZ);
    container.meshes[0].rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(rotY), 0);
    container.meshes[0].scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
    container.meshes[1].alwaysSelectAsActiveMesh = true;
    container.meshes[2].alwaysSelectAsActiveMesh = true;
    container.meshes[3].alwaysSelectAsActiveMesh = true;
    playerFace = container.meshes[3];
    playerHeight = container.transformNodes[29];
    playerHeight.position.y = -0.07;
    container.animationGroups[0].stop();

    if (shadowOptimizeValue) {
        shadowGenerator.getShadowMap().renderList.push(container.meshes[2]);
    }

    playerSkeleton = container.skeletons[0];
    playerSkeleton.name = "playerSkeleton"
    container.materials[0].name = "M_Eyebrow";
    eyebrowMat = container.materials[0];
    container.materials[1].name = "M_Body";

    container.materials.forEach((e) => {
        bodyMatSetup(e, eyebrowColor);
    });

    mainPlayer = container.meshes[0];
    targetCube.parent = mainPlayer;
    camera.lockedTarget = targetCube;
    leftParticleBox.parent = mainPlayer;
    rightParticleBox.parent = mainPlayer;


};
export const setPlayerAnimation = (animations) => {
    playerAnis[0] = animations[10];
    playerAnis[1] = animations[14];
    playerAnis[2] = animations[11];
    playerAnis[3] = animations[0]; //dance 0
    playerAnis[4] = animations[1];
    playerAnis[5] = animations[2];
    playerAnis[6] = animations[3];
    playerAnis[7] = animations[4];
    playerAnis[8] = animations[5];
    playerAnis[9] = animations[6];
    playerAnis[10] = animations[7];
    playerAnis[11] = animations[8];
    playerAnis[12] = animations[9]; //dance 10
    playerAnis[13] = animations[12]; //sit
};
export const nickNameSetup = (nickname) => {
    let nickNameTextPlane = BABYLON.Mesh.CreatePlane("nickNameTextPlane", 2, scene);
    nickNameTextPlane.rotation = new BABYLON.Vector3(0, 0, 0);
    nickNameTextPlane.parent = mainPlayer;
    nickNameTextPlane.position.y = 1.7;
    nickNameTextPlane.isPickable = false;
    nickNameTextPlane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

    let advancedTexture = AdvancedDynamicTexture.CreateForMesh(nickNameTextPlane);

    myNickNameText = new TextBlock("myNickNameText", nickname);
    myNickNameText.width = 1;
    myNickNameText.height = 4;
    myNickNameText.color = "white";
    myNickNameText.fontSize = "75px";
    myNickNameText.shadowBlur = 0;
    myNickNameText.outlineWidth = 10;
    myNickNameText.outlineColor = "black"

    advancedTexture.addControl(myNickNameText);


    bubbleState = new Image("but", bubbleStateUrl[1]);
    bubbleState.width = "220px";
    bubbleState.height = "120px";
    bubbleState.top = "-175px";
    bubbleState.isVisible = false;
    bubbleState.cellId = 1;
    bubbleState.cellWidth = 220;
    bubbleState.cellHeight = 120;
    bubbleState.sourceWidth = 220;
    bubbleState.sourceHeight = 120;

    bubbleState.alpha = 0.065;
    advancedTexture.addControl(bubbleState);
};

export const myNickNameChange = (name) => {
    if (myNickNameText) {
        myNickNameText.text = name;
        socket.emit('CHANGE_MY_NAME', {
            name: name
        })
    }
}
export const bubbleStateStart = (value) => {
    if (bubbleStateInterval !== null) {
        clearInterval(bubbleStateInterval);
        bubbleStateInterval = null;
    }
    switch (value) {
        case "chat":
            if (bubbleState.source !== bubbleStateUrl[1]) bubbleState.source = bubbleStateUrl[1];
            bubbleStateInterval = setInterval(() => {
                if (bubbleStateOn) bubbleState.cellId < 28 ? bubbleState.cellId++ : bubbleState.cellId = 1;
            }, 33);
            break;
        case "closet":
            if (bubbleState.source !== bubbleStateUrl[0]) bubbleState.source = bubbleStateUrl[0];
            bubbleStateInterval = setInterval(() => {
                if (bubbleStateOn) bubbleState.cellId < 88 ? bubbleState.cellId++ : bubbleState.cellId = 1;
            }, 33);
            break;
    }

    if (bubbleLowerState !== null) {
        clearInterval(bubbleLowerState);
        bubbleLowerState = null;
    }
    bubbleState.isVisible = true;
    bubbleUpperState = setInterval(() => {
        if (bubbleState.alpha < 1) {
            bubbleState.alpha += 0.05;
        }
        else {
            clearInterval(bubbleUpperState);
            bubbleUpperState = null;
        }
    }, 50);

    bubbleState.cellId = 1;
    bubbleStateOn = true;
    socket.emit('BUBBLE_STATE_START', {
        state: value
    });
};
export const bubbleStateEnd = () => {
    if (isAllReady) {
        if (bubbleUpperState !== null) {
            clearInterval(bubbleUpperState);
            bubbleUpperState = null;
        }
        bubbleLowerState = setInterval(() => {
            if (bubbleState.alpha > 0.06) {
                bubbleState.alpha -= 0.05;
            }
            else {
                bubbleState.isVisible = false;
                bubbleState.alpha = 0;
                clearInterval(bubbleLowerState);
                bubbleStateOn = false;
                if (bubbleStateInterval !== null) {
                    clearInterval(bubbleStateInterval);
                    bubbleStateInterval = null;
                }
            }
        }, 50);
        socket.emit('BUBBLE_STATE_END');
    };
};
export const playerEmotionSetup = () => {
    faceEmotion = [
        {
            faceName: "기본",
            texture: new BABYLON.Texture("/assets/img/emotion/기본.png", scene, undefined, false)
        },
        {
            faceName: "!!",
            texture: new BABYLON.Texture("/assets/img/emotion/!!.png", scene, undefined, false)
        },
        {
            faceName: "^^",
            texture: new BABYLON.Texture("/assets/img/emotion/^^.png", scene, undefined, false)
        },
        {
            faceName: "놀람",
            texture: new BABYLON.Texture("/assets/img/emotion/놀람.png", scene, undefined, false)
        },
        {
            faceName: "ㅎㅎ",
            texture: new BABYLON.Texture("/assets/img/emotion/ㅎㅎ.png", scene, undefined, false)
        },
        {
            faceName: "화남2",
            texture: new BABYLON.Texture("/assets/img/emotion/화남2.png", scene, undefined, false)
        },
        {
            faceName: "ㅜㅜ",
            texture: new BABYLON.Texture("/assets/img/emotion/ㅜㅜ.png", scene, undefined, false)
        },
        {
            faceName: "ㅠㅠ",
            texture: new BABYLON.Texture("/assets/img/emotion/ㅠㅠ.png", scene, undefined, false)
        },
        {
            faceName: "ㅡㅡ",
            texture: new BABYLON.Texture("/assets/img/emotion/ㅡㅡ.png", scene, undefined, false)
        }
    ];
    playerFace.material.albedoTexture = faceEmotion[0].texture;

    socket.on("_CHAT_EMOTION", ({ id, word }) => {
        if (id === socket.id) { // ->redux_id
            if (playerFace.material.albedoTexture === faceEmotion[0].texture) {
                let transName = word;
                if (word.includes("??")) transName = "놀람";
                else if (word.includes("!?")) transName = "화남2";
                faceEmotion.forEach((e) => {
                    if (transName.indexOf(e.faceName) != -1) {
                        playerFace.material.albedoTexture = e.texture;
                        return;
                    }
                });
                setTimeout(() => {
                    playerFace.material.albedoTexture = faceEmotion[0].texture;
                }, 3000);
            };
        };
    });

};


//---------------------------------------------------------------------------------------------------------------------------------------------------------


// material setup
//---------------------------------------------------------------------------------------------------------------------------------------------------------
export const bodyMatSetup = (material) => {

    if (material.name !== "M_Eyebrow" && material.name !== "M_Eyeblow") {
        material.albedoColor = BABYLON.Color3.FromHexString("#ffca9d");
    }
    material.useAlphaFromAlbedoTexture = false;
    material.roughness = 1;
    material.sheen.isEnabled = true;
    material.transparencyMode = BABYLON.PBRMaterial.MATERIAL_OPAQUE;
    material.sheen.color = BABYLON.Color3.FromHexString("#909090");
    material.sheen.useRoughness = true;
    material.sheen.intensity = 0.5;
    material.sheen.roughness = 0.5;
    material.specularIntensity = 0;
};
export const mapMatSetup = (material) => {
    material.useAlphaFromAlbedoTexture = false;
    material.roughness = 0.8;
    material.transparencyMode = BABYLON.PBRMaterial.MATERIAL_OPAQUE;
    material.sheen.isEnabled = true;
    material.sheen.color = BABYLON.Color3.FromHexString("#828282");
    material.sheen.useRoughness = true;
    material.sheen.intensity = 0.5;
    material.sheen.roughness = 0.5;
};
export const grassMatSet = (material) => {
    material.metallic = 0;
    material.roughness = 1;
};
export const glassMatSet = (material) => {
    material.alpha = 0.22;
    material.transparencyMode = BABYLON.PBRMaterial.MATERIAL_ALPHABLEND;
    material.metallic = 0.26;
    material.roughness = 0;
};
export const brickMatSet = (material) => {
    material.metallic = 0;
    material.roughness = 0.75;
    material.bumpTexture.level = 0.2;
    material.albedoColor = BABYLON.Color4.FromHexString('#DBDBDB');
};
export const BackFaceCullingOn = (materials) => {
    materials.forEach((e) => {
        e.backFaceCulling = true;
    })
};
export const BackFaceCullingOne = (material) => {
    material.backFaceCulling = true;
};
export const minimapMat = (materials) => {
    materials.forEach((e) => {
        e.transparencyMode = BABYLON.PBRMaterial.MATERIAL_OPAQUE;
        e.metallic = 0;
        e.roughness = 0;
        e.unlit = true;
        e.backFaceCulling = false;
    })
};


//---------------------------------------------------------------------------------------------------------------------------------------------------------



// nav move essential setup
//---------------------------------------------------------------------------------------------------------------------------------------------------------
export const pinSetup = (container) => {
    pin = container.meshes[0];
    pin.name = "pin";
    pin.rotation = new BABYLON.Vector3(0, Math.PI, 0);
    pin.scaling = new BABYLON.Vector3(5, 5, 5);
    pin.setEnabled(false);

    let pins = BABYLON.Mesh.MergeMeshes([
        pin.getChildMeshes()[0],
        pin.getChildMeshes()[1]
    ], true, true, null, false, true);

    pins.parent = pin;
    pins.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

    container.materials[0].roughness = 0.8;
    container.materials[0].transparencyMode = BABYLON.PBRMaterial.MATERIAL_OPAQUE;
    container.materials[0].sheen.isEnabled = true;
    container.materials[0].sheen.color = BABYLON.Color3.FromHexString("#828282").toLinearSpace();
    container.materials[0].sheen.useRoughness = true;
    container.materials[0].sheen.intensity = 0.5;
    container.materials[0].sheen.roughness = 0.5;

    container.materials[1].roughness = 0.8;
    container.materials[1].transparencyMode = BABYLON.PBRMaterial.MATERIAL_OPAQUE;
    container.materials[1].sheen.isEnabled = true;
    container.materials[1].sheen.color = BABYLON.Color3.FromHexString("#828282").toLinearSpace();
    container.materials[1].sheen.useRoughness = true;
    container.materials[1].sheen.intensity = 0.5;
    container.materials[1].sheen.roughness = 0.5;
};
export const pointerSetup = (pointerM, pointerA) => {
    pointer = pointerM;
    pointer.name = "pointer";
    pointer.position = new BABYLON.Vector3(200, 200, 200);
    pointer.scaling = new BABYLON.Vector3(0.3, 0.3, 0.3);
    pointerAni = pointerA;
    pointerAni.name = "PointerAni"
    pointerAni.stop();
    pointerAni.loopAnimation = false;
    pointerAni.onAnimationGroupEndObservable.add(() => {
        pointer.setEnabled(false)
    })
};
export const setNavMesh = (tempNavMesh, value, height) => {
    navMesh = tempNavMesh;
    navMesh.isVisible = value;
    playerHeight.position.y = height;
};
export const setParticles = () => {
    for (let i = 1; i <= 4; i++) {
        const particle = new BABYLON.ParticleSystem(`particle${i}`, 4, scene);
        if (i <= 2) {
            particle.particleTexture = new BABYLON.Texture("/assets/model/footPrint.png", scene);
            particle.minEmitBox = new BABYLON.Vector3(-1, 0, 0);
            particle.maxEmitBox = new BABYLON.Vector3(1, 0, 0);
            particle.color1 = new BABYLON.Color4(0, 0, 0, 0.5);
            particle.color2 = new BABYLON.Color4(0, 0, 0, 0.5);
            particle.colorDead = new BABYLON.Color4(0, 0, 0, 0);
            particle.minSize = 0.1;
            particle.maxSize = 0.1;
            particle.minLifeTime = 0.15;
            particle.maxLifeTime = 0.15;
            particle.blendMode = BABYLON.ParticleSystem.BLENDMODE_STANDARD;
            particle.direction1 = BABYLON.Vector3.Zero();
            particle.direction2 = BABYLON.Vector3.Zero();
            particle.isBillboardBased = false;
            particle.particleEmitterType = new BABYLON.PointParticleEmitter();

            particle.minEmitPower = 0;
            particle.maxEmitPower = 0;
        } else {
            particle.particleTexture = new BABYLON.Texture("/assets/model/Dust_Alpha.jpg", scene);
            particle.color1 = BABYLON.Color3.FromHexString("#ffe0b3");
            particle.color2 = BABYLON.Color3.FromHexString("#fff5e6");
            particle.colorDead = new BABYLON.Color4(0, 0, 0.2, 0);
            particle.minSize = 0.15;
            particle.maxSize = 0.2;
            particle.minLifeTime = 0.15;
            particle.maxLifeTime = 0.15;
            particle.blendMode = BABYLON.ParticleSystem.BLENDMODE_ONEONE;
            particle.isBillboardBased = false;
            particle.particleEmitterType = new BABYLON.BoxParticleEmitter();
            particle.minEmitBox = new BABYLON.Vector3(0, 0, 0);
            particle.maxEmitBox = new BABYLON.Vector3(0, 0, 0);
            particle.direction1 = new BABYLON.Vector3(0, 1, -0.5)
            particle.direction2 = new BABYLON.Vector3(0, 1, -0.5)
            particle.minEmitPower = 1;
            particle.maxEmitPower = 2;
        }

        if (i % 2 !== 0) {
            particle.emitter = leftParticleBox;
        } else {
            particle.emitter = rightParticleBox;
        }

        particle.gravity = new BABYLON.Vector3.Zero();
        particle.minAngularSpeed = 0;
        particle.maxAngularSpeed = 0;
        particle.updateSpeed = 0.002;
        particle.emitRate = 13;
        particle.isLocal = false;
        particleSystems.push(particle);
        particle.stop();
    }
}
export const particlesState = (value) => {
    try {
        if (value) {
            particleSystems[0].start();
            particleSystems[2].start();
            setTimeout(() => {
                particleSystems[1].start();
                particleSystems[3].start();
            }, footPrintDelay);
        } else {
            playerAni.stop();
            setMoveAble(false);
            particleSystems[0].stop();
            particleSystems[2].stop();
            setTimeout(() => {
                particleSystems[1].stop();
                particleSystems[3].stop();
            }, footPrintDelay);
        }
    } catch {
    }
};
export const playerNavMoveSetup = (nameArr, reduxId, SetClickUser) => {
    redux_id = reduxId;
    crowd = getNavPlugin().createCrowd(10, 0.1, scene);
    // agents
    checkDistance = 0;
    checkPointVector3 = mainPlayer.position;
    let targetCubeT = BABYLON.MeshBuilder.CreateBox("targetCubeT", { size: 0.1, height: 0.1 }, scene);
    targetCubeT.isVisible = false;
    let firstPos = mainPlayer.position
    let transform = new BABYLON.TransformNode("passCount");
    let agentIndex = crowd.addAgent(firstPos, agentParams, transform);
    agents.push({ idx: agentIndex, trf: transform, mesh: mainPlayer, target: targetCubeT });
    playerAni = playerAnis[0];
    playerAni.start(true, 1.0);

    function pointeValueFunction(name) {
        let value = false;
        nameArr.forEach((e) => {
            if (e === name) {
                value = true;
                return;
            }
        })
        return value;
    }

    scene.onPointerObservable.add((pointerInfo) => {
        switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERPICK:
                // console.log("pos:::::::" + pointerInfo.pickInfo.pickedMesh.name);
                //  console.log(`{ x: ${pointerInfo.pickInfo.pickedPoint.x.toFixed(3)}, y: ${pointerInfo.pickInfo.pickedPoint.y.toFixed(3)}, z: ${pointerInfo.pickInfo.pickedPoint.z.toFixed(3)}, rotY: 180 },`);
                // console.log(`new Vector3 (${pointerInfo.pickInfo.pickedPoint.x.toFixed(3)}, ${pointerInfo.pickInfo.pickedPoint.y.toFixed(3)}, ${pointerInfo.pickInfo.pickedPoint.z.toFixed(3)}),`);
                if (clickUser) {
                    SetClickUser(false);
                    clickUser = false;
                }

                if (isClickAble) {
                    let testTransVector3 = getNavPlugin().getClosestPoint(pointerInfo.pickInfo.pickedPoint);
                    if (testTransVector3._y !== 0) {
                        if (!pointeValueFunction(pointerInfo.pickInfo.pickedMesh.name)) {
                            return;
                        }
                        testWalkPoint++;
                        setMoveAble(true);
                        checkPointVector3 = pointerInfo.pickInfo.pickedPoint;
                        socket.emit('MOVE_POZ', {
                            x: testTransVector3.x,
                            y: testTransVector3.y,
                            z: testTransVector3.z
                        })

                        checkDistance = BABYLON.Vector3.Distance(mainPlayer.position, checkPointVector3);
                        pointer.position = checkPointVector3;
                        pointer.position.y += 0.1;
                        if (pointerAni.isPlaying) {
                            pointerAni.stop();
                            pointerAni.start(false, 1.0, pointerAni.from, pointerAni.to, false);
                            pointer.setEnabled(true);
                        } else {
                            pointerAni.start(false, 1.0, pointerAni.from, pointerAni.to, false);
                            pointer.setEnabled(true);
                        }

                        if (checkDistance > 3) {
                            if (playerAni.isPlaying) {
                                if (playerAni !== playerAnis[2]) {
                                    playerAni.stop();
                                    playerAni = playerAnis[2];
                                    playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                                    agentParams.maxSpeed = 6;
                                    crowd.updateAgentParameters(agents[0], agentParams);
                                }
                            } else {
                                playerAni = playerAnis[2];
                                playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                                agentParams.maxSpeed = 6;
                                crowd.updateAgentParameters(agents[0], agentParams);
                            }

                        } else if (checkDistance < 3) {
                            if (playerAni.isPlaying) {
                                if (playerAni !== playerAnis[1]) {
                                    playerAni.stop();
                                    playerAni = playerAnis[1];
                                    playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                                    agentParams.maxSpeed = 3;
                                    crowd.updateAgentParameters(agents[0], agentParams);
                                }
                            } else {
                                playerAni = playerAnis[1];
                                playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                                agentParams.maxSpeed = 3;
                                crowd.updateAgentParameters(agents[0], agentParams);
                            }
                        }
                        crowd.agentGoto(agents[0], testTransVector3);
                        particlesState(true);
                    }
                }
                break;
        }
    });

    scene.onAfterRenderObservable.add(() => {
        if (isMoveAble) {
            mainPlayer.position = crowd.getAgentPosition(agents[0].idx);
            checkDistance = BABYLON.Vector3.Distance(mainPlayer.position, checkPointVector3);
            let vel = crowd.getAgentVelocity(agents[0].idx);
            if (vel.length() > 0.1) {
                vel.normalize();
                mainPlayer.rotation.y = Math.atan2(vel.x, vel.z);
                if (checkDistance > 3) {
                    if (playerAni !== playerAnis[2]) {
                        playerAni.stop();
                        playerAni = playerAnis[2];
                        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                        agentParams.maxSpeed = 6;
                        crowd.updateAgentParameters(agents[0], agentParams);
                    }
                } else if (checkDistance > 1) {
                    if (playerAni !== playerAnis[1]) {
                        playerAni.stop();
                        playerAni = playerAnis[1];
                        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                        agentParams.maxSpeed = 3;
                        crowd.updateAgentParameters(agents[0], agentParams);
                    }
                }
            } else {
                if (playerAni !== playerAnis[0]) {
                    axios.post(`${process.env.REACT_APP_API_URL}/heat_insert`, {
                        id: redux_id,
                        point: testWalkPoint
                    }).then(() => {
                        testWalkPoint = 0;
                    });          
                    particlesState(false);
                    playerAni = playerAnis[0];
                    playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                    crowd.agentTeleport(agents[0], mainPlayer.position);
                }
            }
        }
    });
};


//---------------------------------------------------------------------------------------------------------------------------------------------------------

export const clickUserTrue = () => {
    clickUser = true;
};

//player state
//---------------------------------------------------------------------------------------------------------------------------------------------------------
export const setClickAble = (value) => {
    isClickAble = value;
};
export const setMoveAble = (value) => {
    isMoveAble = value;
};
export const setAllReady = () => {
    let options = new BABYLON.SceneOptimizerOptions(90, 500);
    options.addOptimization(new BABYLON.TextureOptimization(1, 256));
    options.addOptimization(new BABYLON.ShadowsOptimization(2));
    options.addOptimization(new BABYLON.PostProcessesOptimization(3));
    options.addOptimization(new BABYLON.LensFlaresOptimization(4));
    options.addOptimization(new BABYLON.ParticlesOptimization(5));
    options.addOptimization(new BABYLON.RenderTargetsOptimization(6));
    options.addOptimization(new BABYLON.HardwareScalingOptimization(7, 1.5));
    let optimizer = new BABYLON.SceneOptimizer(scene, options, undefined, true);
    optimizer.start();    
    isAllReady = true;
};






//---------------------------------------------------------------------------------------------------------------------------------------------------------



// select functions
//---------------------------------------------------------------------------------------------------------------------------------------------------------
export const waterAndShadowSetup = (map, water, reflectionWaterMeshes, drawShadowMeshes, receiveShadowMeshes) => {
    map.name = "map";

    if (water) {
        water.name = "water";
        water.waterColor = new BABYLON.Color4(1, 1, 1, 1);
        water.renderOverlay = true;
        water.overlayColor = new BABYLON.Color3.FromHexString("#00B5FF");
        water.material = waterMaterial;
        reflectionWaterMeshes.push(skyBoxDay);
        reflectionWaterMeshes.forEach((e) => {
            waterMaterial.addToRenderList(e);
        });
    }

    if (shadowOptimizeValue) {
        drawShadowMeshes.forEach((e) => {
            shadowGenerator.getShadowMap().renderList.push(e);
        });

        receiveShadowMeshes.forEach((e) => {
            e.receiveShadows = true;
        });
    }
};

export const portalSetup = (container, portalsPos) => {
    container.meshes[0].rotation = new BABYLON.Vector3(0, 0, 0);
    container.meshes[0].scaling = new BABYLON.Vector3(120, 140, 120);
    container.meshes[2].isVisible = false;

    portals.push(container.meshes[0]);
    portalAnis.push(container.animationGroups[0]);
    portalHitBoxes.push(container.meshes[2])

    for (let i = 0; i < portalsPos.length - 1; i++) {
        let portalContainer = container.instantiateModelsToScene();
        let portal;
        for (portal of portalContainer.rootNodes);
        portals.push(portal);
        portalAnis.push(portalContainer.animationGroups[0]);
        portalHitBoxes.push(portal.getChildMeshes()[0])
        isHitPortals.push(false);
    };
    portals.forEach((e, i) => {
        e.position = portalsPos[i];
        e.name = "portal0" + String(i);
        portalAnis[i].stop();
        portalAnis[i].name = "portalAni" + String(i);
        portalHitBoxes[i].name = "portalHitBox" + String(i);
    })
};
export const minimapSetup = (glbName, textureName, pos, rot, alpha, SetClickMap, arrRot) => {
    BABYLON.SceneLoader.LoadAssetContainer('./assets/model/', glbName, scene, (container) => {
        container.meshes[0].name = "minimapUI";
        container.meshes[0].rotation = new BABYLON.Vector3(0, Math.PI, 0);
        minimapMat(container.materials);
        let texArr = []
        for (let i = 1; i <= pos.length; i++) {
            let UITex = new BABYLON.Texture("/assets/model/" + textureName + i + ".jpg", scene, undefined, false);
            texArr.push(UITex);
        }

        container.meshes.forEach((e, i) => {
            if (i > 0) {
                e.isVisible = false;
                e.rotation = new BABYLON.Vector3(arrRot[0], arrRot[1], arrRot[2]);
                miniMapArr.push(e);
                let UITex1 = e.material.albedoTexture;
                e.actionManager = new BABYLON.ActionManager(scene);
                e.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, () => {
                    e.material.albedoTexture = texArr[i - 1];
                }));
                e.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, () => {
                    e.material.albedoTexture = UITex1;
                }));
                e.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], (evt) => {
                    camera.lowerRadiusLimit = 3;
                    camera.alpha = alpha[i - 1];
                    setClickAble(true);
                    isClickAble = true;

                    socket.emit('TELEPORT_LOCAL', {
                        x: pos[i - 1]._x,
                        y: pos[i - 1]._y,
                        z: pos[i - 1]._z,
                        rotY: rot[i - 1]
                    })
                    crowd.agentTeleport(agents[0], pos[i - 1]);
                    camera.useAutoRotationBehavior = false;

                    mainPlayer.position = crowd.getAgentPosition(agents[0].idx);
                    mainPlayer.rotation.y = BABYLON.Tools.ToRadians(rot[i - 1]);
                    if (playerAni !== playerAnis[0]) {
                        particlesState(false);
                        playerAni = playerAnis[0];
                        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
                    }
                    miniMapArr.forEach((e) => {
                        e.isVisible = false;

                    })
                    let ttt2 = scene.onBeforeRenderObservable.add(() => {
                        if (camera.radius >= 14) {
                            camera.radius -= 3;
                            if (camera.beta < 1.1)
                                camera.beta += 0.01;
                        }
                        else {
                            camera.lowerRadiusLimit = 3;
                            camera.upperRadiusLimit = 20;
                            camera.beta = 1.1;
                            scene.onBeforeRenderObservable.remove(ttt2);
                        }
                    });
                    SetClickMap(false);
                }));
            }
        })

        container.addAllToScene()
    });
};
export const minimapOn = (maxRadius, speed) => {
    scene.fogDensity = 0;
    camera.beta = 0.85;
    camera.upperRadiusLimit = maxRadius + 10;
    setClickAble(false);
    crowd.agentTeleport(agents[0], mainPlayer.position);
    socket.emit('TELEPORT_LOCAL', {
        x: mainPlayer.position._x,
        y: mainPlayer.position._y,
        z: mainPlayer.position._z,
        rotY: BABYLON.Tools.ToDegrees(mainPlayer.rotation.y)
    })
    if (playerAni !== playerAnis[0]) {
        particlesState(false);
        playerAni = playerAnis[0];
        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
    }
    minimapRotation = scene.onBeforeRenderObservable.add(() => {
        miniMapArr.forEach((e) => {
            e.rotation.z = -camera.alpha;
        });

    });

    let upperCamera = scene.onBeforeRenderObservable.add(() => {
        if (camera.radius <= maxRadius) {
            camera.radius += speed;
        } else {
            miniMapArr.forEach((e) => {
                e.isVisible = true;
            })
            pin.setEnabled(true);
            pin.position = new BABYLON.Vector3(mainPlayer.position.x, 15, mainPlayer.position.z);
            camera.lowerRadiusLimit = maxRadius;
            camera.useAutoRotationBehavior = true;
            camera.autoRotationBehavior.idleRotationSpeed = 0.15;
            scene.onBeforeRenderObservable.remove(upperCamera);
        }
    })
};
export const minimapOff = (minRadius, speed) => {
    if (minimapRotation !== null) {
        scene.onBeforeRenderObservable.remove(minimapRotation);
        minimapRotation = null;
        pin.setEnabled(false);
        if (camera.lowerRadiusLimit !== 3) {
            camera.lowerRadiusLimit = 3;
            camera.useAutoRotationBehavior = false;
            miniMapArr.forEach((e) => {
                e.isVisible = false;
            })

            let downerCamera = scene.onBeforeRenderObservable.add(() => {
                if (camera.radius >= minRadius) {
                    camera.radius -= speed;
                    if (camera.beta < 1.35)
                        camera.beta += 0.01;
                }
                else {
                    setClickAble(true);
                    scene.fogDensity = 0.0005;
                    camera.lowerRadiusLimit = 3;
                    camera.upperRadiusLimit = 20;
                    camera.beta = 1.35;
                    scene.onBeforeRenderObservable.remove(downerCamera);
                }
            });
        }
    }
};
export const portalEventSetup = (SetCameraActionAniAction, SetPortalAction) => {
    let tempFrame = 0;
    scene.onAfterRenderObservable.add(() => {
        portalHitBoxes.forEach((e, i) => {
            if (leftParticleBox.intersectsMesh(e)) {
                if (!isHitPortals[i]) {
                    isHitPortals[i] = true;
                    try {
                        tempFrame = portalAnis[i].animatables[0].masterFrame;
                    } catch {
                        tempFrame = 0;
                    }

                    portalAnis[i].stop();
                    portalAnis[i].start(false, 0.3, tempFrame, 30);
                    portalAnis[i].onAnimationGroupEndObservable.addOnce(() => {
                        setCameraAniValue(true, SetCameraActionAniAction);
                        SetPortalAction(i);
                    })
                }
            } else {
                if (isHitPortals[i]) {
                    isHitPortals[i] = false;
                    portalAnis[i].onAnimationGroupEndObservable.clear();
                    try {
                        tempFrame = portalAnis[i].animatables[0].masterFrame;
                    } catch {
                        tempFrame = 30;
                    }
                    portalAnis[i].stop();
                    portalAnis[i].start(false, 0.3, tempFrame, 0);
                }
            }
        })
    });
};
export const setCameraAniValue = (value, SetCameraActionAniAction) => {
    cameraAniValue = value;
    SetCameraActionAniAction(true);
};
export const singleNpcSetup = (name, pos, rot, distance, eventNum, checkEventValue, setEventValue, event, SetEvent, isFinal, setIsJustFinishRender) => {
    BABYLON.SceneLoader.LoadAssetContainer('./assets/model/', name + '.glb', scene, (container) => {
        BackFaceCullingOn(container.materials);
        container.meshes[0].name = name;
        container.meshes[0].position = pos;
        container.meshes[0].rotation = rot;
        container.meshes[0].scaling = new BABYLON.Vector3(1.5, 1.5, -1.5);

        npc.push({
            idx: eventNum,
            mesh: container.meshes[0]
        });

        container.animationGroups[0].play(true, 1.0);
        container.materials.forEach((e) => {
            bodyMatSetup(e);
        })
        if (shadowOptimizeValue) shadowGenerator.getShadowMap().renderList.push(container.meshes[1]);

        let justAniValue = false;
        scene.onBeforeRenderObservable.add(() => {
            if (BABYLON.Vector3.Distance(mainPlayer.position, container.meshes[0].position) > distance) {
                if (!justAniValue) {
                    justAniValue = true;
                    container.animationGroups[1].stop();
                    container.animationGroups[0].play(true, 1.0);
                }
            }
        })


        container.meshes[1].actionManager = new BABYLON.ActionManager(scene);
        container.meshes[1].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
            if (BABYLON.Vector3.Distance(mainPlayer.position, container.meshes[0].position) < distance) {
                justAniValue = false;
                container.meshes[0].lookAt(mainPlayer.position, 0, -Math.PI, Math.PI);
                container.meshes[0].rotation = new BABYLON.Vector3(0, container.meshes[0].rotation.y + Math.PI, 0);
                mainPlayer.lookAt(container.meshes[0].position, 0, 0, 0);
                mainPlayer.rotation = new BABYLON.Vector3(0, mainPlayer.rotation.y, 0);
                if (event !== checkEventValue) {
                    SetEvent(setEventValue);
                    container.animationGroups[0].stop();
                    container.animationGroups[1].stop();
                    container.animationGroups[1].play(true, 1.0);
                }
            }
        }));

        if (isFinal) setIsJustFinishRender(true);
        container.addAllToScene();
    });
};
export const multiNpcSetup = (name, multiNpc, distance, isFinal, setIsJustFinishRender) => {
    BABYLON.SceneLoader.LoadAssetContainer('./assets/model/', name + '.glb', scene, (container) => {
        BackFaceCullingOn(container.materials);
        container.meshes[0].name = multiNpc[0].name;
        container.meshes[0].position = multiNpc[0].pos;
        container.meshes[0].rotation = multiNpc[0].rot;
        container.meshes[0].scaling = new BABYLON.Vector3(1.5, 1.5, -1.5);
        container.materials[0].transparencyMode = BABYLON.PBRMaterial.MATERIAL_OPAQUE;

        let npcNamePlane = BABYLON.Mesh.CreatePlane(`${multiNpc[0].name} npcNamePlane`, 2, scene);
        npcNamePlane.rotation = new BABYLON.Vector3(0, 0, 0);
        npcNamePlane.scaling = new BABYLON.Vector3(1, -1, -1);
        npcNamePlane.parent = container.meshes[0];
        npcNamePlane.position.y = -1.8;
        npcNamePlane.isPickable = false;
        npcNamePlane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

        let npcADTex = AdvancedDynamicTexture.CreateForMesh(npcNamePlane);

        let npcNameText = new TextBlock(`${multiNpc[0].name} npcNameText`, multiNpc[0].name);
        npcNameText.width = 1;
        npcNameText.height = 4;
        npcNameText.color = multiNpc[0].textColor;
        npcNameText.fontSize = "75px";
        npcNameText.shadowBlur = 0;
        npcNameText.outlineWidth = 15;
        npcNameText.outlineColor = multiNpc[0].outlineColor;
        npcADTex.addControl(npcNameText);

        npc.push({
            idx: multiNpc[0].eventNum,
            mesh: container.meshes[0]
        });
        container.animationGroups[0].play(true, 1.0);
        container.materials.forEach((e) => {
            bodyMatSetup(e);
        })
        if (shadowOptimizeValue) shadowGenerator.getShadowMap().renderList.push(container.meshes[1]);
        let justAniValue = false;

        scene.onBeforeRenderObservable.add(() => {
            if (BABYLON.Vector3.Distance(mainPlayer.position, container.meshes[0].position) > distance) {
                if (!justAniValue) {
                    container.animationGroups[1].stop();
                    container.animationGroups[0].play(true, 1.0);
                }
            }
        })

        container.meshes[1].actionManager = new BABYLON.ActionManager(scene);
        container.meshes[1].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
            if (BABYLON.Vector3.Distance(mainPlayer.position, container.meshes[0].position) < distance) {
                justAniValue = false;
                container.meshes[0].lookAt(mainPlayer.position, 0, -Math.PI, Math.PI);
                container.meshes[0].rotation = new BABYLON.Vector3(0, container.meshes[0].rotation.y + Math.PI, 0);

                mainPlayer.lookAt(container.meshes[0].position, 0, 0, 0);
                mainPlayer.rotation = new BABYLON.Vector3(0, mainPlayer.rotation.y, 0);
                if (multiNpc[0].event !== multiNpc[0].checkEventValue) {
                    multiNpc[0].SetEvent(multiNpc[0].setEventValue);
                    container.animationGroups[0].stop();
                    container.animationGroups[1].stop();
                    container.animationGroups[1].play(true, 1.0);
                }
            }
        }));

        for (let i = 1; i < multiNpc.length; i++) {
            let tempContainer = container.instantiateModelsToScene();
            let tempNpc;
            for (tempNpc of tempContainer.rootNodes);
            tempNpc.name = multiNpc[i].name;
            tempNpc.position = multiNpc[i].pos;
            tempNpc.rotation = multiNpc[i].rot;

            let tempNpcNamePlane = tempNpc.getChildMeshes()[3];
            tempNpcNamePlane.name = `${multiNpc[0].name} npcNamePlane`;
            tempNpcNamePlane.isPickable = false;

            let tempNpcADTex = AdvancedDynamicTexture.CreateForMesh(tempNpcNamePlane);
            let tempNpcNameText = new TextBlock(`${multiNpc[i].name} npcNameText`, multiNpc[i].name);
            tempNpcNameText.width = 1;
            tempNpcNameText.height = 4;
            tempNpcNameText.color = multiNpc[i].textColor;
            tempNpcNameText.fontSize = "75px";
            tempNpcNameText.shadowBlur = 0;
            tempNpcNameText.outlineWidth = 10;
            tempNpcNameText.outlineColor = multiNpc[i].outlineColor;
            tempNpcADTex.addControl(tempNpcNameText);

            npc.push({
                idx: multiNpc[i].eventNum,
                mesh: tempNpc
            });
            tempContainer.animationGroups[0].play(true, 1.0);
            if (shadowOptimizeValue) shadowGenerator.getShadowMap().renderList.push(tempNpc.getChildMeshes()[0]);

            let justAniValue2 = false;
            scene.onBeforeRenderObservable.add(() => {
                if (BABYLON.Vector3.Distance(mainPlayer.position, tempNpc.position) > distance) {
                    if (!justAniValue2) {
                        justAniValue2 = true;
                        tempContainer.animationGroups[1].stop();
                        tempContainer.animationGroups[0].play(true, 1.0);
                    }
                }
            })

            tempNpc.getChildMeshes()[0].actionManager = new BABYLON.ActionManager(scene);
            tempNpc.getChildMeshes()[0].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
                if (BABYLON.Vector3.Distance(mainPlayer.position, tempNpc.position) < distance) {
                    justAniValue2 = false;
                    tempNpc.lookAt(mainPlayer.position, 0, -Math.PI, Math.PI);
                    tempNpc.rotation = new BABYLON.Vector3(0, tempNpc.rotation.y + Math.PI, 0);
                    mainPlayer.lookAt(tempNpc.position, 0, 0, 0);
                    mainPlayer.rotation = new BABYLON.Vector3(0, mainPlayer.rotation.y, 0);
                    if (multiNpc[i].event !== multiNpc[i].checkEventValue) {
                        multiNpc[i].SetEvent(multiNpc[i].setEventValue);
                        tempContainer.animationGroups[0].stop();
                        tempContainer.animationGroups[1].stop();
                        tempContainer.animationGroups[1].play(true, 1.0);
                    }
                }
            }));

        }
        container.addAllToScene();
        if (isFinal) setIsJustFinishRender(true);
    });

};
export const playerDance = (danceNum, SetDanceValue) => {
    let danceAni = null;
    let aniCount = false;
    switch (danceNum) {
        case 1: danceAni = playerAnis[3];
            aniCount = 0;
            break;
        case 2: danceAni = playerAnis[4];
            aniCount = 1;
            break;
        case 3: danceAni = playerAnis[5];
            aniCount = 2;
            break;
        case 4: danceAni = playerAnis[6];
            aniCount = 3;
            break;
        case 5: danceAni = playerAnis[7];
            aniCount = 4;
            break;
        case 6: danceAni = playerAnis[8];
            aniCount = 5;
            break;
        case 7: danceAni = playerAnis[9];
            aniCount = 6;
            break;
        case 8: danceAni = playerAnis[10];
            aniCount = 7;
            break;
        case 9: danceAni = playerAnis[11];
            aniCount = 8;
            break;
        case 10: danceAni = playerAnis[12];
            aniCount = 9;
            break;
        default:
            break;
    }
    if (aniCount !== false) {
        if (playerAni !== danceAni) {
            particlesState(false);
            playerAni = danceAni;
            playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
            crowd.agentTeleport(agents[0], mainPlayer.position);
            socket.emit('DANCE_ANI', {
                aniCount: aniCount,
                rotationY: mainPlayer.rotation.y
            })
        }
        SetDanceValue(false);
    }
};
export const npcTalkCameraWorkIn = (disPlayNpc) => {
    crowd.agentTeleport(agents[0], mainPlayer.position);
    if (playerAni !== playerAnis[0]) {
        particlesState(false);
        playerAni = playerAnis[0];
        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
    }
    const targetCubePos = targetCube.getAbsolutePosition();
    let targetNpcV = npc.filter(obj => obj.idx === disPlayNpc ? true : false)
    let targetNpc = targetNpcV[0].mesh.getAbsolutePosition();

    if (targetCubePos.x > targetNpc.x) {
        if (targetCubePos.z > targetNpc.z) {
            moveX = targetCubePos.z - targetNpc.z - 3;
            moveZ = targetCubePos.x - targetNpc.x + 1;
        } else {
            moveX = (targetCubePos.x - targetNpc.x) - 1;
            moveZ = -(targetCubePos.z - targetNpc.z) + 3;
        }
    } else {
        if (targetCubePos.z < targetNpc.z) {
            moveX = -(targetCubePos.z - targetNpc.z);
            moveZ = -(targetCubePos.x - targetNpc.x);
        } else {
            moveX = (targetCubePos.z - targetNpc.z);
            moveZ = -(targetCubePos.x - targetNpc.x);
        }
    };
    moveX = moveX / Math.PI;
    moveZ = moveZ / Math.PI;

    const npcTalkInAni = new BABYLON.AnimationGroup('npcTalkInAni', scene);
    npcTalkInAni.normalize(0, 15);

    const radiusAni = new BABYLON.Animation("radiusAni", "radius", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    const targetAni = new BABYLON.Animation('targetAni', "position", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    const betaAni = new BABYLON.Animation("betaAni", "beta", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    let radiusKeys = [];
    let targetKeys = [];
    let betaKeys = [];
    targetKeys.push({
        frame: 0,
        value: targetCube.position.clone()
    });
    targetKeys.push({
        frame: 15,
        value: new BABYLON.Vector3(targetCube.position.x + moveX, targetCube.position.y, targetCube.position.z + moveZ)
    });
    radiusKeys.push({
        frame: 0,
        value: camera.radius
    });
    radiusKeys.push({
        frame: 15,
        value: 8
    });
    betaKeys.push({
        frame: 0,
        value: camera.beta
    });
    betaKeys.push({
        frame: 15,
        value: 1.2
    });

    radiusAni.setKeys(radiusKeys);
    targetAni.setKeys(targetKeys);
    betaAni.setKeys(betaKeys);
    npcTalkInAni.addTargetedAnimation(radiusAni, camera);
    npcTalkInAni.addTargetedAnimation(targetAni, targetCube);
    npcTalkInAni.addTargetedAnimation(betaAni, camera);
    npcTalkInAni.play(false);
    npcTalkInAni.onAnimationGroupEndObservable.add(() => {
        npcTalkInAni.dispose();
    });
};
export const npcTalkCameraWorkOut = () => {
    const npcTalkOutAni = new BABYLON.AnimationGroup('npcTalkOutAni', scene);
    npcTalkOutAni.normalize(0, 15);

    const radiusAni = new BABYLON.Animation("radiusAni", "radius", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    const targetAni = new BABYLON.Animation('targetAni', "position", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    const betaAni = new BABYLON.Animation("betaAni", "beta", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    let radiusKeys = [];
    let targetKeys = [];
    let betaKeys = [];
    radiusKeys.push({
        frame: 0,
        value: camera.radius
    });
    radiusKeys.push({
        frame: 15,
        value: 16
    });
    targetKeys.push({
        frame: 0,
        value: targetCube.position.clone()
    })
    targetKeys.push({
        frame: 15,
        value: new BABYLON.Vector3(targetCube.position.x - moveX, 1, targetCube.position.z - moveZ)
    });

    betaKeys.push({
        frame: 0,
        value: camera.beta
    });
    betaKeys.push({
        frame: 15,
        value: 0.8
    });

    radiusAni.setKeys(radiusKeys);
    targetAni.setKeys(targetKeys);
    betaAni.setKeys(betaKeys);
    npcTalkOutAni.addTargetedAnimation(radiusAni, camera);
    npcTalkOutAni.addTargetedAnimation(targetAni, targetCube);
    npcTalkOutAni.addTargetedAnimation(betaAni, camera);
    npcTalkOutAni.play(false);
    npcTalkOutAni.onAnimationGroupEndObservable.add(() => {
        npcTalkOutAni.dispose();
    });
};
export const cameraWorks = (SetCameraActionAniAction, isInCamera) => {
    if (isAllReady) {
        let sortingValue = 'pcOpen';
        if (isInCamera) {
            if (window.innerWidth <= 768) sortingValue = 'mobileOpen';
            if (cameraAniValue) sortingValue = 'portalOpen';
            if (playerAni !== playerAnis[0]) {
                particlesState(false);
                playerAni = playerAnis[0];
                playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
            };
        } else {
            sortingValue = 'pcClose';
            if (window.innerWidth <= 768) {
                sortingValue = 'mobileClose';
            }
        }
        camera.alpha = (camera.alpha % Math.PI);
        let alphaValue = 1.57;
        switch (sortingValue) {
            case 'pcOpen':
                betaValue = 1.125;
                radiusValue = 5.0;
                targetValueX = -0.5;
                targetValueY = 0;
                rotationValue = 0;
                crowd.agentTeleport(agents[0], mainPlayer.position);
                socket.emit('TELEPORT_LOCAL', {
                    x: mainPlayer.position._x,
                    y: mainPlayer.position._y,
                    z: mainPlayer.position._z,
                    rotY: BABYLON.Tools.ToDegrees(mainPlayer.rotation.y)
                })
                setMoveAble(false);
                setClickAble(false);
                camera.detachControl(true);
                break;
            case 'mobileOpen':
                betaValue = 0.85;
                radiusValue = 10;
                targetValueX = 0;
                targetValueY = -1;
                rotationValue = 0;
                setClickAble(false);
                camera.detachControl(true);
                break;
            case 'portalOpen':
                alphaValue = 0;
                betaValue = 1.2;
                radiusValue = 8;
                targetValueX = 0;
                targetValueY = 0;
                if (mainPlayer.rotation.y < 0) {
                    rotationValue = -1.655;
                } else {
                    rotationValue = 4.62498;
                }

                SetCameraActionAniAction(false);
                crowd.agentTeleport(agents[0], mainPlayer.position);
                break;
            case 'pcClose':
                betaValue = 0.9;
                radiusValue = 6;
                targetValueY = 0;
                if (targetCube.position.x !== 0) {
                    targetValueX = -(targetCube.position.x);
                } else {
                    targetValueX = 0;
                }
                break;
            case 'mobileClose':
                betaValue = 0.9
                radiusValue = 14;
                targetValueX = 0;
                if (targetCube.position.y !== 0) {
                    targetValueY = 1 - (targetCube.position.y);
                } else {
                    targetValueY = 1;
                }
                break;
            default: break;
        }
        targetValue = new BABYLON.Vector3(targetCube.position.x + targetValueX, targetCube.position.y + targetValueY, targetCube.position.z);

        const cameraAnimationNormal = new BABYLON.AnimationGroup('cameraAnimationNormal', scene);
        cameraAnimationNormal.normalize(0, 15);
        const radiusAni = new BABYLON.Animation("radiusAni", "radius", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        const targetAni = new BABYLON.Animation('targetAni', "position", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        const betaAni = new BABYLON.Animation("betaAni", "beta", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        const rotationAni = new BABYLON.Animation("rotationAni", "rotation", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        const alphaAni = new BABYLON.Animation("alphaAni", "alpha", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

        let radiusKeys = [];
        let targetKeys = [];
        let betaKeys = [];
        let rotationKeys = [];
        let alphaKeys = [];

        targetKeys.push({
            frame: 0,
            value: targetCube.position.clone()
        });
        targetKeys.push({
            frame: 15,
            value: targetValue
        });
        radiusKeys.push({
            frame: 0,
            value: camera.radius
        });
        radiusKeys.push({
            frame: 15,
            value: radiusValue
        });
        betaKeys.push({
            frame: 0,
            value: camera.beta
        });
        betaKeys.push({
            frame: 15,
            value: betaValue
        });
        rotationKeys.push({
            frame: 0,
            value: mainPlayer.rotation.clone()
        });
        rotationKeys.push({
            frame: 15,
            value: new BABYLON.Vector3(0, rotationValue, 0)
        });
        alphaKeys.push({
            frame: 0,
            value: camera.alpha
        });
        alphaKeys.push({
            frame: 15,
            value: alphaValue
        });

        radiusAni.setKeys(radiusKeys);
        targetAni.setKeys(targetKeys);
        betaAni.setKeys(betaKeys);
        rotationAni.setKeys(rotationKeys);
        alphaAni.setKeys(alphaKeys);

        cameraAnimationNormal.addTargetedAnimation(radiusAni, camera);
        cameraAnimationNormal.addTargetedAnimation(targetAni, targetCube);
        cameraAnimationNormal.addTargetedAnimation(betaAni, camera);
        cameraAnimationNormal.addTargetedAnimation(rotationAni, mainPlayer);
        cameraAnimationNormal.addTargetedAnimation(alphaAni, camera);
        cameraAnimationNormal.play(false);
        cameraAnimationNormal.onAnimationGroupEndObservable.addOnce(() => {
            if (cameraAniValue || isInCamera) {
                camera.radius = radiusValue;
                cameraAniValue = false;
            } else {
                camera.attachControl(true);
                setClickAble(true);
            }
            cameraAnimationNormal.dispose();
        });
    }
};
export const vrEventSetup = (container, posArr, rotArr, address) => {

    container.meshes[0].name = "VR_UI00";
    container.meshes[0].position = posArr[0];
    container.meshes[0].rotation = rotArr[0];
    container.meshes[0].scaling = new BABYLON.Vector3(1, 1, 1);
    container.materials[0].unlit = true;


    let innerVrTex = new BABYLON.Texture("/assets/model/VRTex03.jpg", scene);

    let VrUndoMat = container.materials[0];
    let VrTransMat = container.materials[0].clone("vrMatClone");
    VrTransMat.albedoTexture = innerVrTex;
    container.meshes[1].actionManager = new BABYLON.ActionManager(scene);
    scene.onAfterRenderObservable.add(() => {
        if (BABYLON.Vector3.Distance(mainPlayer.position, container.meshes[0].position) < 7) {
            container.meshes[0].lookAt(mainPlayer.position, Math.PI, -Math.PI, Math.PI);
            container.meshes[0].rotation.x = 0
            container.meshes[0].rotation.z = 0
        }
    });

    container.meshes[1].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], (evt) => {
            window.open(address[0]);    
    }));
    container.meshes[1].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, () => {
        container.meshes[1].material = VrTransMat;
    }));
    container.meshes[1].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, () => {
        container.meshes[1].material = VrUndoMat;
    }));

    posArr.forEach((e, i) => {
        if (i > 0) {
            let entries = container.instantiateModelsToScene();
            let tempVr;
            for (tempVr of entries.rootNodes);

            if (i < 10) {
                tempVr.name = "VR_UI00" + i;
            } else {
                tempVr.name = "VR_UI0" + i;
            }
            tempVr.position = e;
            tempVr.rotation = rotArr[i];
            scene.onAfterRenderObservable.add(() => {
                if (BABYLON.Vector3.Distance(mainPlayer.position, tempVr.position) < 7) {
                    tempVr.lookAt(mainPlayer.position, Math.PI, -Math.PI, Math.PI);
                    tempVr.rotation.x = 0
                    tempVr.rotation.z = 0
                }
            });
            tempVr.getChildMeshes()[0].actionManager = new BABYLON.ActionManager(scene);
            tempVr.getChildMeshes()[0].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
                window.open(address[i]);
            }));
            tempVr.getChildMeshes()[0].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, () => {
                tempVr.getChildMeshes()[0].material = VrTransMat;
            }));
            tempVr.getChildMeshes()[0].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, () => {
                tempVr.getChildMeshes()[0].material = VrUndoMat;
            }));
        }
    })
};
export const anyClickEventSetup = (anyName, container, pos, text) => {

    container.meshes[0].name = anyName;
    container.meshes[0].position = pos;
    container.meshes[0].rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
    container.meshes[0].scaling = new BABYLON.Vector3(1, 1, -1);

    container.meshes[1].actionManager = new BABYLON.ActionManager(scene);
    container.meshes[1].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
        window.alert(text);
    }));
    container.addAllToScene();
};
export const anyClickEventSetup2 = (mesh, event, eventValue) => {
    mesh.isPickable = true;
    mesh.actionManager = new BABYLON.ActionManager(scene);
    mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
        event(eventValue);
    }));
};
export const noticeBoardSetup = (name, container, posArr, rotArr, event, eventValue, role) => {
    container.meshes[0].name = name + 0;
    container.meshes[0].position = posArr[0];
    container.meshes[0].rotation = rotArr[0];
    container.meshes[0].scaling = new BABYLON.Vector3(-1, 1, 1);
    container.materials[0].backFaceCulling = true;
    container.meshes[1].actionManager = new BABYLON.ActionManager(scene);
    container.meshes[1].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
        if (role === 1) {
            if (BABYLON.Vector3.Distance(mainPlayer.position, container.meshes[0].position) < 7) {
                event(eventValue[0]);
            }
        }else{
            window.alert("로그인 후 이용 가능한 서비스입니다.")
        }
    }));

    npc.push({
        idx: eventValue[0],
        mesh: container.meshes[0]
    });

    posArr.forEach((e, i) => {
        if (i > 0) {
            let entries = container.instantiateModelsToScene();
            let tempAny;
            for (tempAny of entries.rootNodes);
            tempAny.name = name + i;
            tempAny.position = e;
            tempAny.rotation = rotArr[i];

            tempAny.getChildMeshes()[0].actionManager = new BABYLON.ActionManager(scene);
            tempAny.getChildMeshes()[0].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager['OnPickTrigger'], () => {
                if (role === 1) {
                    if (BABYLON.Vector3.Distance(mainPlayer.position, tempAny.position) < 7) {
                        event(eventValue[i])
                    }
                }else{
                    window.alert("로그인 후 이용 가능한 서비스입니다.")
                }
            }));

            npc.push({
                idx: eventValue[i],
                mesh: tempAny
            });

        }
    });
};

//---------------------------------------------------------------------------------------------------------------------------------------------------------



//avatar and item setup
//---------------------------------------------------------------------------------------------------------------------------------------------------------

export const getItemArr = () => {
    return {
        hairTransform: hairArr,
        clothTransform: clothArr,
        shoesTransform: shoesArr
    }
};
//avatar sorting : it is normal arr, sorting value of redux value of DB
export const setAvatarItems = (container, jointNum, clothNum, hairNum) => {
    container.meshes[0].name = "custom";
    container.meshes[0].position = new BABYLON.Vector3(Math.PI - Math.PI / 4, 0, -Math.PI + Math.PI / 4);
    container.meshes[0].rotation = new BABYLON.Vector3(0, 0, 0);
    container.meshes[0].scaling = new BABYLON.Vector3(1, 1, 1);

    tempPlayerJoint = container.transformNodes[jointNum];
    tempPlayerJoint.getChildMeshes().forEach((mesh, i) => {

        if (i < clothNum) {
            mesh.parent = clothArr;
        } else if (i < hairNum) {
            mesh.parent = hairArr;
        } else {
            mesh.parent = shoesArr;
        }
        mesh.isVisible = false;
    });
    container.meshes[0].dispose();
    container.skeletons[0].dispose();
};
//avatar set : it is avatar mat normalize setup
export const avatarMatSet = (material) => {
    material.useAlphaFromAlbedoTexture = false;
    material.sheen.isEnabled = true;
    material.roughness = 1;
    material.sheen.color = BABYLON.Color3.FromHexString("#B7B7B7");
    material.sheen.useRoughness = true;
    material.sheen.intensity = 0.5;
    material.sheen.roughness = 0.5;
    material.specularIntensity = 0.1
};
//custom set : it is changed items of users design, so post api in redux value to DB
export const changeCustom = (currentItems, thisItem) => {
    let sValue = false;
    if (Number(currentItems["cloth"].split('_')[1]) === 17) sValue = true;
    let tempItems = {
        hair: null,
        cloth: null,
        shoes: null,
    };

    for (const key in currentItems) {
        let value = Number(currentItems[key].split('_')[1]);
        switch (key) {
            case 'hair':
                if (!sValue) {
                    if (thisItem.hair) {
                        if (shadowOptimizeValue) shadowGenerator.removeShadowCaster(thisItem.hair, true);
                        thisItem.hair.dispose();
                    }
                    tempItems.hair = hairArr.getChildMeshes()[value].clone(key + "Clone", null, false)
                    tempItems.hair.alwaysSelectAsActiveMesh = true;
                    tempItems.hair.skeleton = playerSkeleton;
                    tempItems.hair.isVisible = true;
                    tempItems.hair.parent = mainPlayer.getChildTransformNodes()[0];
                    if (shadowOptimizeValue) shadowGenerator.getShadowMap().renderList.push(tempItems.hair);
                } else {
                    if (thisItem.hair)
                        thisItem.hair.isVisible = false;
                }
                break;
            case 'shoes':
                if (!sValue) {
                    if (thisItem.shoes) {
                        if (shadowOptimizeValue) shadowGenerator.removeShadowCaster(thisItem.shoes, true);
                        thisItem.shoes.dispose();
                    }
                    tempItems.shoes = shoesArr.getChildMeshes()[value].clone(key + "Clone", null, false)
                    tempItems.shoes.alwaysSelectAsActiveMesh = true;
                    tempItems.shoes.skeleton = playerSkeleton;
                    tempItems.shoes.isVisible = true;
                    tempItems.shoes.parent = mainPlayer.getChildTransformNodes()[0];
                    if (shadowOptimizeValue) shadowGenerator.getShadowMap().renderList.push(tempItems.shoes);
                } else {
                    if (thisItem.shoes)
                        thisItem.shoes.isVisible = false;
                }
                break;
            case 'cloth':
                if (thisItem.cloth) {
                    if (shadowOptimizeValue) shadowGenerator.removeShadowCaster(thisItem.cloth, true);
                    thisItem.cloth.dispose();
                }
                if (value >= 20) value = 0;
                tempItems.cloth = clothArr.getChildMeshes()[value].clone(key + "Clone", null, false)
                tempItems.cloth.alwaysSelectAsActiveMesh = true;
                tempItems.cloth.skeleton = playerSkeleton;
                tempItems.cloth.isVisible = true;
                tempItems.cloth.parent = mainPlayer.getChildTransformNodes()[0];
                if (shadowOptimizeValue) shadowGenerator.getShadowMap().renderList.push(tempItems.cloth);
                if (sValue) {
                    mainPlayer.getChildMeshes()[0].isVisible = false;
                    mainPlayer.getChildMeshes()[1].isVisible = false;
                    mainPlayer.getChildMeshes()[2].isVisible = false;
                    if (tempItems.shoes) {
                        tempItems.shoes.isVisible = false;
                        tempItems.hair.isVisible = false;
                    }
                } else {
                    mainPlayer.getChildMeshes()[0].isVisible = true;
                    mainPlayer.getChildMeshes()[1].isVisible = true;
                    mainPlayer.getChildMeshes()[2].isVisible = true;
                    if (tempItems.shoes) {
                        tempItems.shoes.isVisible = true;
                        tempItems.hair.isVisible = true;
                    }
                }
                break;
            case 'eyebrow':
                eyebrowMat.albedoColor = BABYLON.Color3.FromHexString(eyebrowHexColors[value]).toLinearSpace();
                break;
            default:
                break;
        }
    }

    return {
        sValue: sValue,
        hair: tempItems.hair,
        shoes: tempItems.shoes,
        cloth: tempItems.cloth
    }
};


export const multiMovFC = (playerAni, userAnis, particles, trueValue, sortingValue, crowd, agentParams, agents) => {
    if (playerAni !== userAnis) {
        playerAni.stop();
        playerAni = userAnis;
        playerAni.start(true, 1.0, playerAni.from, playerAni.to, false);
        if (!crowd) {
            particles[0].stop();
            particles[2].stop();
            setTimeout(() => {
                particles[1].stop();
                particles[3].stop();
            }, footPrintDelay);
            trueValue = false;
        } else {
            agentParams.maxSpeed = sortingValue;
            crowd.updateAgentParameters(agents, agentParams);
        }
    }
};
//-------------------------------------------------------------------------------------------------------------------------------------------------------



//multi setup
//-------------------------------------------------------------------------------------------------------------------------------------------------------
export const createSetUser = (deleteArr, id, nickname, position, rotation, items, userAnis, agentParams, isClickUser, SetClickUser, SetClickInfo, isDesktop) => {
    let entries = AvatarBodyContainer.instantiateModelsToScene();
    let tempHairValue, tempShoesValue, tempClothValue, tempEyeBrowValue = 0;
    let user;
    for (user of entries.rootNodes);
    user.name = id;
    user.position = new BABYLON.Vector3(position.x, position.y, position.z);
    user.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(Number(rotation.y)), 0);
    let userSkeleton = entries.skeletons[0];
    userSkeleton.name = id + "skeleton";
    userAnis.push(entries.animationGroups[10]); //idle
    userAnis.push(entries.animationGroups[14]); //walk
    userAnis.push(entries.animationGroups[11]); //run 
    userAnis.push(entries.animationGroups[0]);
    userAnis.push(entries.animationGroups[1]);
    userAnis.push(entries.animationGroups[2]);
    userAnis.push(entries.animationGroups[3]);
    userAnis.push(entries.animationGroups[4]);
    userAnis.push(entries.animationGroups[5]);
    userAnis.push(entries.animationGroups[6]);
    userAnis.push(entries.animationGroups[7]);
    userAnis.push(entries.animationGroups[8]);
    userAnis.push(entries.animationGroups[9]);
    userAnis.push(entries.animationGroups[12]);
    userAnis.forEach((e, i) => {
        e.name = `Ani${i + 1}_${id}`;
    });

    deleteArr.push(user);
    entries.animationGroups.forEach((e) => {
        deleteArr.push(e);
    });
    entries.skeletons.forEach((e) => {
        deleteArr.push(e);
    });

    let nickNameTextPlane = user.getChildren()[4];
    deleteArr.push(nickNameTextPlane);

    nickNameTextPlane.isPickable = false;

    let advancedTexture = AdvancedDynamicTexture.CreateForMesh(nickNameTextPlane);
    deleteArr.push(advancedTexture);
    deleteArr.push(nickNameTextPlane.material);

    let nickNameText = new TextBlock(id + "nickNameText", nickname);
    nickNameText.width = 1;
    nickNameText.height = 4;
    nickNameText.color = "white";
    nickNameText.fontSize = "75px";
    nickNameText.shadowBlur = 0;
    nickNameText.outlineWidth = 10;
    nickNameText.outlineColor = "black"
    advancedTexture.addControl(nickNameText);

    let userBubbleState = new Image("but", bubbleStateUrl[1]);
    userBubbleState.width = "220px";
    userBubbleState.height = "120px";
    userBubbleState.top = "-175px";
    userBubbleState.isVisible = false;
    userBubbleState.cellId = 1;
    userBubbleState.cellWidth = 220;
    userBubbleState.cellHeight = 120;
    userBubbleState.sourceWidth = 220;
    userBubbleState.sourceHeight = 120;

    userBubbleState.alpha = 0.065;
    advancedTexture.addControl(userBubbleState);


    let userEyeBrowMat = eyebrowMat.clone(id + "userEyeBrowMat");
    deleteArr.push(userEyeBrowMat);
    user.getChildTransformNodes()[0].getChildMeshes()[0].material = userEyeBrowMat;
    if (user.getChildTransformNodes()[0].getChildMeshes().length === 6) {
        user.getChildTransformNodes()[0].getChildMeshes()[5].dispose();
        user.getChildTransformNodes()[0].getChildMeshes()[4].dispose();
    }
    if (user.getChildTransformNodes()[0].getChildMeshes()[3]) user.getChildTransformNodes()[0].getChildMeshes()[3].dispose();
    user.getChildren()[3].dispose();
    user.getChildren()[2].dispose();
    user.getChildren()[1].dispose();

    if (items.hair && !items.hair.split('_')[1]) tempHairValue = items.hair;
    else if (items.hair.split('_')[1]) tempHairValue = items.hair.split('_')[1];

    if (items.shoes && !items.shoes.split('_')[1]) tempShoesValue = items.shoes;
    else if (items.shoes.split('_')[1]) tempShoesValue = items.shoes.split('_')[1];

    if (items.cloth && !items.cloth.split('_')[1]) tempClothValue = items.cloth;
    else if (items.cloth.split('_')[1]) tempClothValue = items.cloth.split('_')[1];

    if (items.eyebrow && !items.eyebrow.split('_')[1]) tempEyeBrowValue = items.eyebrow;
    else if (items.eyebrow.split('_')[1]) tempEyeBrowValue = items.eyebrow.split('_')[1];

    let playerHair = hairArr.getChildMeshes()[tempHairValue].clone(id + "hairClone", null, false)
    playerHair.alwaysSelectAsActiveMesh = true;
    playerHair.skeleton = userSkeleton;
    playerHair.parent = user.getChildTransformNodes()[0];


    let playerCloth = clothArr.getChildMeshes()[tempClothValue].clone(id + "clothClone", null, false)
    playerCloth.alwaysSelectAsActiveMesh = true;
    playerCloth.skeleton = userSkeleton;
    playerCloth.isVisible = true;
    playerCloth.parent = user.getChildTransformNodes()[0];


    let playerShoes = shoesArr.getChildMeshes()[tempShoesValue].clone(id + "shoesClone", null, false)
    playerShoes.alwaysSelectAsActiveMesh = true;
    playerShoes.skeleton = userSkeleton;
    playerShoes.parent = user.getChildTransformNodes()[0];

    deleteArr.push(playerHair);
    deleteArr.push(playerCloth);
    deleteArr.push(playerShoes);
    if (shadowOptimizeValue) {
        shadowGenerator.getShadowMap().renderList.push(user.getChildMeshes()[1]);
        shadowGenerator.getShadowMap().renderList.push(playerHair);
        shadowGenerator.getShadowMap().renderList.push(playerCloth);
        shadowGenerator.getShadowMap().renderList.push(playerShoes);
    }

    if (Number(tempClothValue) === 17) {
        playerHair.isVisible = false;
        playerShoes.isVisible = false;
        user.getChildTransformNodes()[0].getChildMeshes()[0].isVisible = false;
        user.getChildTransformNodes()[0].getChildMeshes()[1].isVisible = false;
        user.getChildTransformNodes()[0].getChildMeshes()[2].isVisible = false;
    } else {
        playerHair.isVisible = true;
        playerShoes.isVisible = true;
        user.getChildTransformNodes()[0].getChildMeshes()[0].isVisible = true;
        user.getChildTransformNodes()[0].getChildMeshes()[1].isVisible = true;
        user.getChildTransformNodes()[0].getChildMeshes()[2].isVisible = true;
    }


    userEyeBrowMat.albedoColor = BABYLON.Color3.FromHexString(eyebrowHexColors[tempEyeBrowValue]);

    let leftParticleBox = new BABYLON.MeshBuilder.CreateBox(id + "leftParticleBox", { size: 1, height: 1 }, scene);
    deleteArr.push(leftParticleBox);

    let rightParticleBox = new BABYLON.MeshBuilder.CreateBox(id + "RightParticleBox", { size: 1, height: 1 }, scene);
    deleteArr.push(rightParticleBox);

    leftParticleBox.isVisible = false;
    rightParticleBox.isVisible = false;
    leftParticleBox.parent = user;
    leftParticleBox.position.x -= 0.1;
    leftParticleBox.position.y += 0.08;
    rightParticleBox.parent = user;
    rightParticleBox.position.x += 0.1;
    rightParticleBox.position.y += 0.08;

    const particleSystem = multiUserParticleSetup(leftParticleBox, rightParticleBox, id);
    deleteArr.concat(particleSystem);

    let crowd = getNavPlugin().createCrowd(10, 0.1, scene);
    let targetCube = new BABYLON.MeshBuilder.CreateBox(id + "targetCube", { size: 0.1, height: 0.1 }, scene);
    targetCube.isVisible = false;
    deleteArr.push(targetCube);

    let firstPos = getNavPlugin().getRandomPointAround(user.position, 0);
    let transform = new BABYLON.TransformNode(id + "passCount", scene);
    deleteArr.push(transform);

    let agentIndex = crowd.addAgent(firstPos, agentParams, transform);

    let userHitBox = BABYLON.MeshBuilder.CreateBox(id + "hitBox", { size: 1, height: 1 }, scene);
    userHitBox.position.y += 0.8
    userHitBox.scaling = new BABYLON.Vector3(0.8, 1.5, 0.8)
    userHitBox.visibility = 0.001;
    userHitBox.parent = user;
    userHitBox.actionManager = new BABYLON.ActionManager(scene);
    let clickTrigger = isDesktop ? 'OnRightPickTrigger' : 'OnPickTrigger';
    userHitBox.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager[clickTrigger], (evt) => {
        if (BABYLON.Vector3.Distance(user.position, mainPlayer.position) < 10) {
            if (!isClickUser) {
                SetClickUser(true);
                clickUser = true;
                SetClickInfo({ socketid: id, clickusernickname: nickname, x: evt.pointerX, y: evt.pointerY })
            }
        }
    }));

    let agents = [];
    agents.push({ idx: agentIndex, trf: transform, mesh: user, target: targetCube });
    return {
        user: user,
        particleSystem: particleSystem,
        shadowGenerator: getShadowGenerator(),
        playerHair: playerHair,
        playerCloth: playerCloth,
        playerShoes: playerShoes,
        userEyeBrowMat: userEyeBrowMat,
        userSkeleton: userSkeleton,
        crowd: crowd,
        agents: agents,
        userBubbleState: userBubbleState,
        bubbleStateUrl: bubbleStateUrl,
        hairArr: hairArr,
        clothArr: clothArr,
        shoesArr: shoesArr,
        faceEmotion: faceEmotion,
        nickNameText: nickNameText
    }
};
export const multiUserParticleSetup = (leftParticleBox, rightParticleBox, id) => {
    let updateSpeedValue = 0.002;
    let particleSystems = [];
    for (let i = 1; i <= 4; i++) {
        const particle = new BABYLON.ParticleSystem(`${id}_particle${i}`, 4, scene);
        if (i <= 2) {
            particle.particleTexture = new BABYLON.Texture("/assets/model/footPrint.png", scene);
            particle.minEmitBox = new BABYLON.Vector3(-1, 0, 0);
            particle.maxEmitBox = new BABYLON.Vector3(1, 0, 0);
            particle.color1 = new BABYLON.Color4(0, 0, 0, 0.5);
            particle.color2 = new BABYLON.Color4(0, 0, 0, 0.5);
            particle.colorDead = new BABYLON.Color4(0, 0, 0, 0);
            particle.minSize = 0.1;
            particle.maxSize = 0.1;
            particle.minLifeTime = 0.15;
            particle.maxLifeTime = 0.15;
            particle.blendMode = BABYLON.ParticleSystem.BLENDMODE_STANDARD;
            particle.direction1 = BABYLON.Vector3.Zero();
            particle.direction2 = BABYLON.Vector3.Zero();
            particle.isBillboardBased = false;
            particle.particleEmitterType = new BABYLON.PointParticleEmitter();

            particle.minEmitPower = 0;
            particle.maxEmitPower = 0;
        } else {
            particle.particleTexture = new BABYLON.Texture("/assets/model/Dust_Alpha.jpg", scene);
            particle.color1 = BABYLON.Color3.FromHexString("#ffe0b3");
            particle.color2 = BABYLON.Color3.FromHexString("#fff5e6");
            particle.colorDead = new BABYLON.Color4(0, 0, 0.2, 0);
            particle.minSize = 0.15;
            particle.maxSize = 0.2;
            particle.minLifeTime = 0.15;
            particle.maxLifeTime = 0.15;
            particle.blendMode = BABYLON.ParticleSystem.BLENDMODE_ONEONE;
            particle.isBillboardBased = false;
            particle.particleEmitterType = new BABYLON.BoxParticleEmitter();
            particle.minEmitBox = new BABYLON.Vector3(0, 0, 0);
            particle.maxEmitBox = new BABYLON.Vector3(0, 0, 0);
            particle.direction1 = new BABYLON.Vector3(0, 1, -0.5)
            particle.direction2 = new BABYLON.Vector3(0, 1, -0.5)
            particle.minEmitPower = 1;
            particle.maxEmitPower = 2;
        }
        if (i % 2 !== 0) {
            particle.emitter = leftParticleBox;
        } else {
            particle.emitter = rightParticleBox;
        }
        particle.gravity = new BABYLON.Vector3.Zero();
        particle.minAngularSpeed = 0;
        particle.maxAngularSpeed = 0;
        particle.updateSpeed = updateSpeedValue;
        particle.emitRate = 13;
        particle.isLocal = false;
        particleSystems.push(particle);
        particle.stop();
    }
    return particleSystems;
};
export const multiParticlesState = (value, multiParticles) => {
    try {
        if (value) {
            multiParticles[0].start();
            multiParticles[2].start();
            setTimeout(() => {
                multiParticles[1].start();
                multiParticles[3].start();
            }, footPrintDelay);
        } else {
            multiParticles[0].stop();
            multiParticles[2].stop();
            setTimeout(() => {
                multiParticles[1].stop();
                multiParticles[3].stop();
            }, footPrintDelay);
        }
    } catch {
    }
};


//-------------------------------------------------------------------------------------------------------------------------------------------------------



//importing objects
//-------------------------------------------------------------------------------------------------------------------------------------------------------

export const getEngine = () => {
    return engine;
};
export const getScene = () => {
    return scene;
};
export const getCamera = () => {
    return camera;
};
export const getNavMesh = () => {
    return navMesh;
};
export const getParticleSystems = () => {
    return particleSystems;
};
export const getMiniMapArr = () => {
    return miniMapArr;
};

export const getMobile = () => {
    let isMobile = 'pc';
    let UserAgent = navigator.userAgent;
    if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) !== null || UserAgent.match(/LG|SAMSUNG|Samsung/) !== null) {
        isMobile = 'mobile'
    }
    return isMobile;
};
export const getMainPlayer = () => {
    return mainPlayer;
};
export const getShadowGenerator = () => {
    return shadowGenerator;
};


//-------------------------------------------------------------------------------------------------------------------------------------------------------



//delete objects
//-------------------------------------------------------------------------------------------------------------------------------------------------------

export const clearEngine = () => {
    Socket_disconnection();
    engine.stopRenderLoop();
    window.removeEventListener("resize", (resize));
    engine.dispose(true, true);
    if (scene) {
        scene.dispose(true, true);
    }
    engine = null;
    scene = null;
    playerAnis = [];
    portals = [];
    portalAnis = [];
    portalHitBoxes = [];
    isHitPortals = [false];
    particleSystems = [];
    agents = [];
    isClickAble = true;
    isMoveAble = false;
    miniMapArr = [];
    npc = [];
    faceEmotion = [];
    minimapRotation = null;
    isAllReady = false;
    leftParticleBox = false;
    if (bubbleStateInterval !== null) {
        clearInterval(bubbleStateInterval);
        bubbleStateInterval = null;
    }

    bubbleStateInterval = null;
    bubbleState = null;
    clickUser = false;
    testWalkPoint = 0;
    myNickNameText = null;
};
export const clearScene = () => {
    scene.dispose(true, true);
};


//-------------------------------------------------------------------------------------------------------------------------------------------------------
