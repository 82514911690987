import React, { useEffect } from 'react';
import styled, {keyframes} from 'styled-components'

const active = keyframes`
    0% {
        opacity: 0;
        transform: translate(-50%, 0);
    }
    30% {
        opacity: 1;
        transform: translate(-50%, calc(-100% - 20px));
    }
    100% {
        opacity: 0;
    }
`
interface Props {
    active : boolean
    text: string
    alertHandler : (active : boolean, text : string) => void
}

const Continaer = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    border : 1px solid rgba(0,0,0,0.16);
    position: absolute;
    /* z-index: 1; */
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 5px;
    padding: 11px 32px;
    animation: ${active} 7s;
;
`

const Msg = styled.p`
    font-family: 'Noto Sans KR', sans-serif ;
    font-weight: 400;
    letter-spacing: -0.98px;
`

function Alert({active, text, alertHandler} : Props) {
    useEffect(()=> {
        setTimeout(()=> {
            alertHandler(false, '');
        }, 7000)
    }, [])
  return (
    <>
        {active &&
            <Continaer>
                <Msg>
                    {text}
                </Msg>
            </Continaer>
        }
    </>
  );
}

export default Alert;