import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Index from './pages/Index';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter, Route, Routes } from'react-router-dom';
import {Provider} from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './redux';
import SignUp from './pages/SignUp';
import Future from './babylon/Future'
import Present from './babylon/Present'
import {CookiesProvider} from 'react-cookie'
import ErrorPage from './pages/ErrorPage';

// import {composeWithDevTools} from 'redux-devtools-extension';
// const store = createStore(rootReducer, composeWithDevTools()); //로컬 개발용
const store = createStore(rootReducer); //빌드용

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}
:root {
  /* color */
  --bs-color-blue : #1a317c;
  --bs-color-red : #e6081f;
  --bs-color-brown : #360006;
  --li-color-brown : #733614;
  --hover-red : #cf0c20;

  /* font */
  --fn-sans : 'Noto Sans KR', sans-serif;
  --fn-inter : 'Inter', sans-serif;
  --fn-Omni-20 : "210OmniGothic020-Regular";
  --fn-Omni-25 : "210OmniGothic025-Regular";
  --fn-Omni-30 : "210OmniGothic030-Regular";
  --fn-Omni-35 : "210OmniGothic035-Regular";
  --fn-Omni-40 : "210OmniGothic040-Regular";
  --fn-Omni-45 : "210OmniGothic045-Regular";
  --fn-Omni-50 : "210OmniGothic050-Regular";
  /* font-size */
  --fs-main-title : 30px;
  --fs-sub-title : 20px;
  --fs-large : 18px;
  --fs-default : 16px;
  /* size */
  --max-height : calc(var(--vh, 1vh) * 100);

  /* padding */
  --pl-moa : 10.6%;
  @media (max-width: 1100px) {
    --pl-moa : 50px;
  }
  @media (max-width: 600px) {
    --pl-moa : 0px;
  }
}
`


ReactDOM.render(
  <Provider store={store}>
      <CookiesProvider>
        <React.StrictMode>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Index/>} />
              <Route path="*" element={<ErrorPage />} />
              <Route path="/future" element={<Future />} />
              <Route path="/present" element={<Present />} />              
              <Route path="/signup" element={<SignUp />} />
            </Routes>
          </BrowserRouter>
        </React.StrictMode>
      </CookiesProvider>
  </Provider>
  ,
  document.getElementById('root') 
);
reportWebVitals();
